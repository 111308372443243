import { Box, Chip, Drawer, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { SingleSelectFilter } from "./SingleSelectFilter";
import { MultipleSelectFilter } from "./MultipleSelectFilter";
import {
  companyTypeLabels,
  matchScoreLabels,
  yearsOfExperienceLabels,
} from "./filterList-constant";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import { useDispatch, useSelector } from "react-redux";
import BusinessIcon from "@mui/icons-material/Business";
import {
  candidateFiltersSlice,
  selectCandidateFilters,
  setCompanyType,
  setMatchScore,
  setYearsOfExperience,
} from "../../../slices/candidateFilters";

/**
 * FilterCandidates component
 *
 * Allows filtering of candidate list by match score, years of experience and company type.
 * Uses MultipleSelectFilter and SingleSelectFilter components to display filter options.
 * Maintains filter state and total number of active filters.
 * Renders in a drawer overlay triggered by the main candidate list.
 */
export const FilterCandidates = ({ open, handleToggle }) => {
  const dispatch = useDispatch();
  const { yearsOfExperience, matchScore, companyType, totalActiveFilters } =
    useSelector(selectCandidateFilters);

  return (
    <>
      <Drawer open={open} anchor="right" onClose={handleToggle}>
        <Box
          sx={{
            width: "340px",
            borderBottom: "1px solid #eaecf0",
            padding: "24px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography fontWeight={600} variant="h5" textTransform={"none"}>
            Filters
          </Typography>
          <Chip
            color="primary"
            variant="outlined"
            label={`${totalActiveFilters}`}
          />
        </Box>
        <Box
          sx={{
            width: "340px",
            borderBottom: "1px solid #eaecf0",
            padding: "24px",
          }}
        >
          <MultipleSelectFilter
            heading={"Match Score"}
            filterValues={matchScoreLabels}
            setFilterValue={(v) => dispatch(setMatchScore(v))}
            currentValues={matchScore}
            setTotalCount={() => {}}
            IconComponent={StarBorderIcon}
          />
        </Box>
        <Box
          sx={{
            width: "340px",
            borderBottom: "1px solid #eaecf0",
            padding: "24px",
          }}
        >
          <MultipleSelectFilter
            heading={"Years of experience"}
            filterValues={yearsOfExperienceLabels}
            setFilterValue={(v) => dispatch(setYearsOfExperience(v))}
            currentValues={yearsOfExperience}
            setTotalCount={() => {}}
            IconComponent={HourglassEmptyIcon}
          />
        </Box>
        <Box
          sx={{
            width: "340px",
            borderBottom: "1px solid #eaecf0",
            padding: "24px",
          }}
        >
          <SingleSelectFilter
            heading={"Company Type"}
            filterValues={companyTypeLabels}
            setFilterValue={(v) => dispatch(setCompanyType(v))}
            setTotalCount={() => {}}
            IconComponent={BusinessIcon}
            currentValue={companyType}
          />
        </Box>
      </Drawer>
    </>
  );
};
