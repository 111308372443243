import { useEffect, useState } from "react";
import { WorkspaceService } from "../../services/workspaces";
import { connect } from "react-redux";
import { switchCurrentWorkspace } from "../../actions/auth";
import { useNavigate } from "react-router";
import {
  Box,
  Button,
  Container,
  Divider,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { ContainerVertical } from "../../styled/SharedComponents";
import WavingHandIcon from "@mui/icons-material/WavingHand";
import Header from "../Header/Header";
function Workspaces({ switchCurrentWorkspace, currentWorkspaceId }) {
  const [workspacesToJoin, setWorkspacesToJoin] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (currentWorkspaceId) {
      switchCurrentWorkspace(null);
    }
    loadWorkspaces();
  }, []);

  const loadWorkspaces = () => {
    setIsLoading(true);
    WorkspaceService.getWorkspaces()
      .then((workspaces) => {
        setWorkspacesToJoin(workspaces);
        setError(null);
      })
      .catch(setError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const [workspaceName, setWorkspaceName] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState(null);

  const handleCreateWorkspace = (e) => {
    e.preventDefault();
    setIsCreating(true);
    WorkspaceService.createWorkspace({ name: workspaceName })
      .then((workspace) => {
        setCreateError(null);
        loadWorkspaces();
      })
      .catch(setCreateError)
      .finally(() => {
        setIsCreating(false);
      });
  };
  const theme = useTheme();
  return (
    <>
      <Header />
      <Container
        sx={{
          minHeight: "100vh",
          maxWidth: "1500px !important",
          paddingTop: "8vh",
          background: "#eeebf2",
        }}
      >
        <ContainerVertical sx={{ width: "100%" }}>
          <Box
            sx={{
              width: "80%",
            }}
          >
            <Typography
              variant="h2"
              color="black"
              textAlign={"left"}
              fontWeight={600}
            >
              <WavingHandIcon /> Welcome back
            </Typography>
          </Box>
          {/* <Box
            sx={{
              width: "40%",
            }}
          >
            <Typography
              variant="h2"
              color={theme.palette.common.black}
              textAlign={"center"}
              fontWeight={600}
            >
             Workspaces
            </Typography>
          </Box> */}
          <Box width={"80%"} gap={2}>
            {isLoading ? (
              <Typography variant="h5">Loading...</Typography>
            ) : error ? (
              <Typography variant="h5" color={theme.palette.error.main}>
                Error: {error.message}
              </Typography>
            ) : (
              <>
                <div
                  style={{
                    background: "rgb(194, 176, 232)",
                    border: "2px solid black",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5" width={"100%"} textAlign={"left"}>
                      Workspaces
                    </Typography>
                    <Button
                      sx={{
                        width: "20% !important",
                        padding: "16px",
                        margin: "10px",
                        textDecoration: "none",
                      }}
                      variant="contained"
                      type="button"
                      onClick={loadWorkspaces}
                    >
                      <Typography width={"100%"} variant="h5">
                        Refresh workspace
                      </Typography>
                    </Button>
                  </Box>
                </div>
                <div
                  style={{
                    border: "2px solid black",
                    padding: "10px",
                    borderBottomLeftRadius: "10px",
                    borderBottomRightRadius: "10px",
                  }}
                >
                  {workspacesToJoin.map((workspace) => (
                    <Workspace
                      key={workspace.id}
                      name={workspace.name}
                      id={workspace.id}
                      onClick={() => {
                        switchCurrentWorkspace(workspace.id);
                        setTimeout(() => navigate("/dashboard"), 2000);
                      }}
                    />
                  ))}
                </div>
              </>
            )}
          </Box>
          <br />
          <Box
            sx={{
              width: "80%",
            }}
          >
            <form>
              <div
                style={{
                  display: "flex",
                  padding: "10px",
                  border: "2px solid black",
                  width: "100%",
                  justifyContent: "space-between",
                  borderRadius: "10px",
                }}
              >
                <TextField
                  sx={{
                    width: "40%",
                  }}
                  required
                  size="large"
                  id="outlined-required"
                  placeholder="Name your workspace"
                  value={workspaceName}
                  onChange={(e) => setWorkspaceName(e.target.value)}
                />
                <Button
                  sx={{
                    width: "20% !important",
                    padding: "16px",
                    textDecoration: "none",
                    marginRight: "10px",
                    marginLeft: "10px",
                    border: "1px solid",
                    borderColor: theme.palette.primary.light,
                    boxShadow: "none",
                    color: "white",
                  }}
                  variant="contained"
                  onClick={handleCreateWorkspace}
                  disabled={!workspaceName}
                >
                  <Typography width={"100%"} variant="h5">
                    {isCreating ? "Creating..." : "Create Workspace"}
                  </Typography>
                </Button>
              </div>
            </form>
          </Box>
        </ContainerVertical>
      </Container>
    </>
  );
}

export default connect(
  (state) => ({
    currentWorkspaceId: state.Auth.workspaceId,
  }),
  {
    switchCurrentWorkspace,
  }
)(Workspaces);

function Workspace({ name, id, onClick }) {
  const theme = useTheme();
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography variant="h5" width={"100%"} textAlign={"left"}>
          {name}
        </Typography>
        <Button
          sx={{
            width: "20% !important",
            padding: "16px",
            textDecoration: "none",
            marginRight: "10px",
            border: "1px solid",
            borderColor: theme.palette.primary.light,
            boxShadow: "none",
            color: "white",
          }}
          variant="contained"
          onClick={onClick}
        >
          <Typography width={"100%"} variant="h5">
            LAUNCH
          </Typography>
        </Button>
      </Box>
      <Divider />
    </>
  );
}
