/** @format */

import persistReducer from "redux-persist/es/persistReducer";
import { SET_CURRENT_WORKSPACE } from "../actions/constants";
import storage from "redux-persist/lib/storage";

const INITIAL_STATE = {
  workspaceId: null,
};

function _authReducer(state = INITIAL_STATE, action) {
  const { payload, type } = action;
  switch (type) {
    case SET_CURRENT_WORKSPACE:
      return {
        ...state,
        workspaceId: payload.workspaceId,
      };
    default:
      return {
        ...state,
      };
  }
}

const authReducer = persistReducer(
  {
    key: "auth",
    storage,
  },
  _authReducer
);
export default authReducer;
