/** @format */
import {
	GET_CHAT,
	LOADING_CHAT,
	SEND_CHAT,
	REMOVE_CHAT,
	USER_FILES,
	TRAN_SCRIPT,
	IS_LOADING,
	FILES_LOADING
} from "../actions/constants";

const INITIAL_STATE = {
	chat: null,
	loading: false,
	filesLoading: false
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_CHAT:
			return { ...state, chat: payload, loading: false };
		case SEND_CHAT:
			return { ...state, chat: [...state.chat, payload], loading: false };
		case LOADING_CHAT:
			return { ...state, chat: [], loading: true };
		case REMOVE_CHAT:
			return { ...state, chat: null, loading: false };
		case USER_FILES:
			return { ...state, files: payload, filesLoading:false};
		case TRAN_SCRIPT:
			return {...state, script: payload, isloading:false}
		case IS_LOADING:
			return {...state, isloading:true}
		case FILES_LOADING:
			return { ...state, filesLoading: true }
		default:
			return { ...state };
	}
}
