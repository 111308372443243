import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { auth } from "../firebaseConfig";

export const candidatesApi = createApi({
  reducerPath: "candidatesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers, { getState }) => {
      const workspaceId = getState().Auth.workspaceId;
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      if (workspaceId) {
        headers.set("x-workspace-id", workspaceId);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getCandidates: builder.query({
      query: () => "/candidates",
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => {
        return baseQueryReturnValue.error.message;
      },
      transformResponse: (baseQueryReturnValue, meta, arg) => {
        return baseQueryReturnValue || [];
      },
      providesTags: (result, error, id) => {
        return [
          "Candidate",
          ...result.map(({ _id: id }) => ({ type: "Candidate", id })),
        ];
      },
    }),
    getCandidateById: builder.query({
      query: (id) => {
        console.log("Getting candidate", id);
        return `/candidates/${id}`;
      },
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => {
        console.log(baseQueryReturnValue);
        console.log(meta);
        console.log(arg);
        return baseQueryReturnValue.error;
      },
      providesTags: (result, error, id) => [{ type: "Candidate", id }],
    }),
    changeCandidateStageById: builder.mutation({
      query: ({ id, stage }) => {
        console.log("Chaning stage", id, stage);
        const visible = stage === "screening" ? true : false;
        return {
          url: `/candidates/${id}`,
          method: "PATCH",
          body: {
            action: stage,
            visible,
          },
        };
      },
      transformResponse: (response) => {
        return response.updatedCandidate;
      },
      // invalidatesTags: (result, error, { id }) => [{ type: "Candidate", id }],
      onQueryStarted: async ({ id, stage }, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedCandidate } = await queryFulfilled;
          console.log("Updated candidate", updatedCandidate);
          const patchResult = dispatch(
            candidatesApi.util.updateQueryData(
              "getCandidates",
              undefined,
              (draft) => {
                const index = draft.findIndex((c) => c._id === id);
                draft[index] = updatedCandidate;
              }
            )
          );
        } catch (e) {}
      },
      invalidatesTags: (result, error, { id }) => [{ type: "Candidate", id }],
    }),
  }),
  tagTypes: ["Candidate", "Candidate"],
});

export const {
  useGetCandidatesQuery,
  useGetCandidateByIdQuery,
  useChangeCandidateStageByIdMutation,
} = candidatesApi;

// const initialState = {
//   loading: false,
//   candidates: [],
// };
//
// const candidatesSlice = createSlice({
//   name: "candidates",
//   initialState,
//   reducers: {},
// });
