/** @format */

import {
	EMPTY_CITIES,
	EMPTY_STATES,
	GET_CITIES,
	GET_COUNTRIES,
	GET_STATES
} from "../actions/constants";

const initialState = {
	countries: null,
	states: null,
	cities: null
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_COUNTRIES:
			return {
				...state,
				countries: payload
			};
		case EMPTY_STATES:
			return {
				...state,
				states: []
			};
		case GET_STATES:
			return {
				...state,
				states: payload
			};
		case EMPTY_CITIES:
			return {
				...state,
				cities: []
			};
		case GET_CITIES:
			return {
				...state,
				cities: payload
			};
		default:
			return { ...state };
	}
}
