/** @format */

import Axios from "axios";
import {
	EMPTY_STATES,
	GET_COUNTRIES,
	GET_STATES,
	EMPTY_CITIES,
	GET_CITIES
} from "./constants";

export const getCountries = () => async dispatch => {
	const countries = ["India"]

	dispatch({
		type: GET_COUNTRIES,
		payload: countries
	});
};

export const getStates = countryName => async dispatch => {
	dispatch({
		type: EMPTY_STATES
	});

	const states = {
		"India": [
			"Madhya Pradesh",
			"Maharashtra",
			"Goa",
		]
	};

	dispatch({
		type: GET_STATES,
		payload: states[countryName],
	});
};

export const getCities = stateName => async dispatch => {
	dispatch({
		type: EMPTY_CITIES
	});

	const cities = {
		"Madhya Pradesh": [
			"Jabalpur",
			"Bhopal",
			"Indore",
		],
		"Maharashtra": [
			"Pune",
			"Mumbai",
			"Nagpur",
		],
		"Goa": [
			"Maragao",
		]
	};

	dispatch({
		type: GET_CITIES,
		payload: cities[stateName] || [],
	});
};

export const emptyStates = () => dispatch => {
	dispatch({
		type: EMPTY_STATES
	});
};

export const emptyCities = () => dispatch => {
	dispatch({
		type: EMPTY_CITIES
	});
};
