/** @format */

import { Grid, Grow } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Vacancies from "./Vacancies/Vacancies";
import Candidates from "./Candidates/Candidates";
import Styles from "./Dashboard.module.scss";
import { connect } from "react-redux";
import { Transition } from "react-transition-group";
import {
  getDepartments,
  getPostingTitles,
  getJobList,
} from "../../actions/positions";
import {
  getCandidatesByVacancy,
  resetCandidates,
  getLengthOfUnassignedCandidates,
} from "../../actions/outreach";
import { addMessage, getMessage, removeChat, candidateFiles } from "../../actions/chat";
import Chat from "./Chat/Chat";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { PageContainer } from "../../styled/SharedComponents";

const Dashboard = (props) => {
  const {
    getDepartments,
    getPostingTitles,
    getJobList,
    departmentList,
    postingTitles,
    jobList,
    chat,
    files,
    getCandidatesByVacancy,
    candidateList,
    resetCandidates,
    addMessage,
    getMessage,
    chatLoading,
    setLoading,
    removeChat,
    getLengthOfUnassignedCandidates,
    candidateFiles
  } = props;
  const [selectedCandidates, setSelectedCandidates] = useState([]);
  const [selectedVacancy, setSelectedVacancy] = useState("");
  const [singleCandidate, setSingleCandidate] = useState({});
  const [hidden, setHidden] = useState(false);
  const [currentJob, setCurrentJob] = useState({});
  useEffect(() => {
    resetCandidates();
    getDepartments();
    getPostingTitles();
    getJobList();
    getLengthOfUnassignedCandidates();
  }, []);

  useEffect(() => {
    if (selectedVacancy !== "") {
      getCandidatesByVacancy(selectedVacancy._id);
    }
  }, [selectedVacancy]);

  const addCandidate = (id) => {};

  const [user, loading, error] = useAuthState(auth);
  return user ? (
    <PageContainer>
      <Grid className={`container height-100vh ${Styles.container}`} container>
        {!hidden ? (
          <Grid
            item
            xl={`${hidden ? 0 : 2}`}
            lg={`${hidden ? 0 : 2}`}
            md={`${hidden ? 0 : 2}`}
            xs={`${hidden ? 0 : 2}`}
            className={Styles.bar}
          >
            <Vacancies
              departmentList={departmentList}
              postingTitles={postingTitles}
              jobList={jobList}
              selectedVacancy={selectedVacancy}
              setSelectedVacancy={setSelectedVacancy}
              setSingleCandidate={setSingleCandidate}
              removeChat={removeChat}
              hidden={hidden}
              currentJob={currentJob}
              setCurrentJob={setCurrentJob}
              setSelectedCandidates={setSelectedCandidates}
            />
          </Grid>
        ) : (
          ""
        )}
        <Grid item xl={3} lg={3} md={3} xs={3} className={Styles.bar}>
          <Candidates
            selectedCandidates={selectedCandidates}
            setSelectedCandidates={setSelectedCandidates}
            candidateList={candidateList}
            setSingleCandidate={setSingleCandidate}
            getMessage={getMessage}
            setHidden={setHidden}
            hidden={hidden}
            currentJob={currentJob}
            setCurrentJob={setCurrentJob}
            selectedVacancy={selectedVacancy}
            jobList={jobList}
            getJobList={getJobList}
          />
        </Grid>
        <Grid
          item
          xl={`${hidden ? 9 : 7}`}
          lg={`${hidden ? 9 : 7}`}
          md={`${hidden ? 9 : 7}`}
          xs={`${hidden ? 9 : 7}`}
        >
          <Chat
            hidden={hidden}
            singleCandidate={singleCandidate}
            setSingleCandidate={setSingleCandidate}
            chat={chat}
            addMessage={addMessage}
            getMessage={getMessage}
            chatLoading={chatLoading}
            candidateFiles={candidateFiles}
            files={files}
          />
        </Grid>
      </Grid>
    </PageContainer>
  ) : (
    <Navigate to="/" />
  );
};
const mapStateToProps = (state) => ({
  departmentList: state.Positions.departmentList,
  postingTitles: state.Positions.postingTitles,
  jobList: state.Positions.jobList,
  candidateList: state.Outreach.candidateList,
  chat: state.Chat.chat,
  chatLoading: state.Chat.loading,
  files: state.Chat.files
});

export default connect(mapStateToProps, {
  getDepartments,
  getPostingTitles,
  getJobList,
  getCandidatesByVacancy,
  resetCandidates,
  addMessage,
  getMessage,
  removeChat,
  getLengthOfUnassignedCandidates,
  candidateFiles
})(Dashboard);
