/** @format */
import { useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { Tab, Tabs, Grid, AppBar } from "@material-ui/core";
import React from "react";
import Styles from "./Positions.module.scss";
import Panel from "../GlobalComponents/Panel/Panel";
import CreateJob from "./CreateJob/CreateJob";
import ModifyJob from "./ModifyJob/ModifyJob";
import ScreeningQuestion from "./ScreeningQuestion/ScreeningQuestion";
import { Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { PageContainer } from "../../styled/SharedComponents";
const Positions = (props) => {
  const [value, setValue] = useState(0);
  const [user, loading, error] = useAuthState(auth);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handelChangeIndex = (index) => {
    setValue(index);
  };

  return user ? (
    <PageContainer>
      <div className="container">
        <AppBar
          style={{ backgroundColor: "#fff", height: "80px" }}
          position="static"
        >
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            variant="fullWidth"
          >
            <Tab className={Styles.tab} label="Create Job" />
            <Tab className={Styles.tab} label="Modify Job" />
          </Tabs>
        </AppBar>
        <SwipeableViews index={value} onChangeIndex={handelChangeIndex}>
          <Panel value={value} index={0}>
            <CreateJob setValue={setValue}></CreateJob>
          </Panel>
          <Panel value={value} index={1}>
            <ModifyJob></ModifyJob>
          </Panel>
        </SwipeableViews>
      </div>
    </PageContainer>
  ) : (
    <Navigate to="/" />
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Positions);
