/** @format */

import React, { useState, useEffect } from "react";
import {
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
  Dialog,
  Paper,
  Checkbox,
  FormHelperText,
  Typography,
  Box,
  InputAdornment,
} from "@material-ui/core";
import Styles from "./CreateJob.module.scss";
import { showAlert } from "../../../actions/alert";

import { AddCircle, FormatIndentDecreaseTwoTone, PictureAsPdf } from "@material-ui/icons";
import AddDepartment from "./AddDepartment/AddDepartment";
import { connect, useDispatch } from "react-redux";
import {
  getCountries,
  getStates,
  getCities,
  emptyCities,
  emptyStates,
} from "../../../actions/location";
import {
  createJob,
  getDepartments,
  getPostingTitles,
  uploadFile
} from "../../../actions/positions";
import {
  getDepartmentWiseQuestion,
  createQuestion,
  updateQuestion,
  getGeneralQuestions,
  deleteQuestion,
  getScreeningQuestions
} from "../../../actions/screeningQuestions";
import { Edit, Delete } from "@material-ui/icons";
import ScreeningQuestions from "../../GlobalComponents/ScreeningQuestions/ScreeningQuestions";
import Dropzone from "react-dropzone";
import { JobsService } from "../../../services/jobs.services";
import { Stack } from "@mui/material";
import ImageIcon from '@mui/icons-material/Image';
import AddQuestion from "../ScreeningQuestion/AddQuestion/AddQuestion";
const CreateJob = (props) => {
  const {
    getCountries,
    getStates,
    getCities,
    createJob,
    emptyStates,
    emptyCities,
    getDepartments,
    countries,
    states,
    cities,
    departments,
    getPostingTitles,
    postingTitles,
    screeningQuestions,
    uploadFile,
    createJobLoading,
    getDepartmentWiseQuestion,
    departmentQuestions,
    getGeneralQuestions,
    generalQuestions,
    createQuestion,
    deleteQuestion,
    updateQuestion
  } = props;

  const [screeningQuestionsLocal, setScreeningQuestionsLocal] = useState([]);
  const [changeQuestion, setChangeQuestions] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    getCountries();
    getDepartments();
    getPostingTitles();
    // if (screeningQuestions !== null && changeQuestion) {
    //   setScreeningQuestionsLocal(screeningQuestions);
    //   setChangeQuestions(false);
    //   console.log("here");
    // }
  }, [screeningQuestions]);
  useEffect( ()=>{
   getScreeningQuestions().then((questions)=>{

      setScreeningQuestionsLocal(questions)
    });
  },[]);
 
  const [formData, setFormData] = useState({
    department: "",
    minSalaryRange: "",
    maxSalaryRange: "",
    minWorkExp: "",
    maxWorkExp: "",
    city: "",
    postingTitle: "",
    assignedRecruiter:"",
    companyName: "",
    hiringManager: "",
    recruiterEmail: "",
    hiringManagerEmail: "",
    jobDescription: null,
    jobProfileImage:null
  });
  const [showError, setShowError] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [categoryData, setCategoryData] = useState("");
  const [files, setFiles] = useState([]);
  const [ profilePic, setProfilePic] = useState([]);
  const [departmentId, setDepartmentId] = useState('');
  const [addQuestion, setAddQuestion] = useState(false);
  const [check, setCheck] = useState({});
  useEffect(()=>{
    if (screeningQuestions !== null) {
      // setScreeningQuestionsLocal(screeningQuestions);
      setChangeQuestions(false);
    }
  },[formData.department])
  useEffect( ()=>{
    getScreeningQuestions().then((questions)=>{
      const updatedQuestion = questions.map((ele)=>{
        if(check[ele._id]!=undefined){
          return {...ele, checked:check[ele._id]}
        }
        return ele
      });
      console.log('updatedQuestion:',updatedQuestion)
      setScreeningQuestionsLocal(questions)
    });
  
  },[addQuestion]);
  const handleDrop = async (acceptedFiles) => {
    setFiles([...acceptedFiles.map((file) => file)]);
    setFormData({ ...formData, jobDescription: acceptedFiles[0] });
  };
  const handleDropTemplate = async (acceptedFiles) => {
    setProfilePic([...acceptedFiles.map((file) => file)]);
    setFormData({ ...formData, jobProfileImage: acceptedFiles[0] });
  };
  const onReject = (files) => {
    const fileString = files.map((file) => file.file.name).join();
    console.log(fileString);
    showAlert(`${fileString} are not valid`, "error");
  };
  const handleDataFromAPI = (key, value) => {
    console.log("here");
    setFormData({ ...formData, [key]: value });
  };

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });

    if (event.target.name === "country") {
      getStates(event.target.value);
    }

    if (event.target.name === "state") {
      getCities(event.target.value);
    }
    if (event.target.name === "department") {
      setChangeQuestions(true);
      setDepartmentId(event.target.value);
    }
  };
  const handleModalClose = () => {
    setModalOpen(false);
  };
  const openModal = (category) => {
    setCategoryData(category);
    setModalOpen(true);
  };

  const handleScreeningQuestions = (e) => {
    console.log(typeof e.target.value);
    const mutatedScreeningQuestion = screeningQuestionsLocal.map((el) => {
      if (el._id === e.target.value) {
        setCheck({
          ...check,
          [el._id]:!el.checked
        })
        return { ...el, checked: !el.checked };
      }
      return el;
    });
    setScreeningQuestionsLocal(mutatedScreeningQuestion);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let flag;
    const formDataCopy = Object.keys(formData);
    formDataCopy.forEach((key) => {
      if (formData[key] === "" || formData[key] === null) {
        return (flag = true);
      }
    });
    if (flag) {
      return setShowError(true);
    }
    if (!flag) {
      setShowError(false);
      /**
       * @type {File}
       */
      const jdFile = formData.jobDescription;
      const jdFileType = jdFile?.type;
      formData.jobDescription = await JobsService.uploadJobDescription(
        jdFile,
        jdFileType
      );

      const fileData = new FormData();
      fileData.append("intro",formData.jobProfileImage);
      const jobProfileImageUrl = await uploadFile(fileData);
      formData.jobProfileImage= jobProfileImageUrl;
      createJob(formData, screeningQuestionsLocal);
      emptyCities();
      emptyStates();
      setFiles([]);
      setProfilePic([]);
      setFormData({
        ...formData,
        department: "",
        maxSalaryRange: "",
        minWorkExp: "",
        maxWorkExp: "",
        minSalaryRange: "",
        city: "",
        postingTitle: "",
        assignedRecruiter: "",
        companyName:"",
        hiringManager: "",
        recruiterEmail: "",
        hiringManagerEmail: "",
      });
    }
  };
  const [addQuestionModalOpen, setAddQuestionModalOpen] = useState(false);
	const [editQuestionData, setEditQuestionData] = useState({});
 	const [editQuestion, setEditQuestion] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [deleteQuestionData, setDeleteQuestionData] = useState({});
	const [category, setCategory] = useState('');
	const [departmentQuestionLocal, setDepartmentQuestionLocal] = useState();
	const onConfirmReject = () => {
		setConfirmModal(false);
		setDeleteQuestionData({});
	  };
	
	  const onConfirmAccept = () => {
		deleteQuestion(deleteQuestionData._id, deleteQuestionData.department);
    const filteredList = screeningQuestionsLocal.filter((e)=>e._id!==deleteQuestionData._id)
		setConfirmModal(false);
		setDeleteQuestionData({});
    setScreeningQuestionsLocal(filteredList)
    // setTimeout(()=>{setAddQuestion(!addQuestion)},2000)
	  };
	const openAddQuestionModal = (name)=>{
		setCategory(name)
		setAddQuestionModalOpen(true);
	  }
	const onEditQuestionClick = (question) => {
		setEditQuestionData(question);
		setEditQuestion(true);
		setAddQuestionModalOpen(true);
	  };
	
	  const onConfirmRequest = (question) => {
		setConfirmModal(true);
		setDeleteQuestionData(question);
	  };
	  const closeAddQuestionModal = ()=>{
		setAddQuestionModalOpen(false)
	  }
  return   !createJobLoading?
  countries !== null && countries.length !== 0 ?
  (
   <div className={Styles.root}>
     <Grid className={Styles.container} container>
       <Grid item xl={6} lg={6} md={6} xs={6}>
         <div className={Styles.item}>
           <div className={Styles.formFiledContainer}>
             <label>Posting Title<span style={{color:"red"}}>*</span></label>
             <FormControl variant="standard" className={Styles.formControl}>
               <Select
                 value={formData.postingTitle}
                 name={"postingTitle"}
                 onChange={handleChange}
                 displayEmpty
                 className={Styles.select}
               >
                 {postingTitles.map((title, index) => {
                   return (
                     <MenuItem
                       key={index}
                       className={Styles.menuitem}
                       value={title._id}
                     >
                       {title.postingTitle}
                     </MenuItem>
                   );
                 })}
               </Select>
               {showError && formData.postingTitle === "" ? (
                 <FormHelperText required={true} className={Styles.error}>
                   Please Select Posting Title
                 </FormHelperText>
               ) : (
                 ""
               )}
             </FormControl>
             <div className={Styles.addButton} onClick={()=>openModal('posting-title')}>
               <AddCircle
                 aria-label={"posting-title"}
                 className={`${Styles.icon} ${Styles.iconLight}`}
               ></AddCircle>
             </div>
           </div>
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Location<span style={{ color: "red" }}>*</span></label>
             <div className={Styles.fullWidth}>
               <TextField
                 onChange={handleChange}
                 name={"city"}
                 value={formData.city}
                 className={Styles.textfiled}
                 variant={"standard"}
               ></TextField>
             {showError && formData.city === "" ? (
               <FormHelperText required={true} className={Styles.error}>
                 Please Enter Location
               </FormHelperText>
             ) : (
               ""
               )}
               </div>

           </div>
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Company Name<span style={{color:"red"}}>*</span></label>
             <div className={Styles.fullWidth}>
               <TextField
                 onChange={handleChange}
                 name={"companyName"}
                 value={formData.companyName}
                 className={Styles.textfiled}
                 variant={"standard"}
               ></TextField>
               {showError && formData.companyName === "" ? (
                 <FormHelperText required={true} className={Styles.error}>
                   Please Enter Company Name
                 </FormHelperText>
               ) : (
                 ""
               )}
             </div>
           </div>
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Department<span style={{color:"red"}}>*</span></label>
             <FormControl variant="standard" className={Styles.formControl}>
               <Select
                 value={formData.department}
                 name={"department"}
                 onChange={handleChange}
                 displayEmpty
                 className={Styles.select}
               >
                 {departments.map((department, index) => {
                   return (
                     <MenuItem
                       key={index}
                       className={Styles.menuitem}
                       value={department._id}
                     >
                       {department.departmentName}
                     </MenuItem>
                   );
                 })}
               </Select>
               {showError && formData.department === "" ? (
                 <FormHelperText required={true} className={Styles.error}>
                   Please Select Department
                 </FormHelperText>
               ) : (
                 ""
               )}
             </FormControl>
             <div className={Styles.addButton} onClick={()=>openModal("department")}>
               <AddCircle
                 aria-label={"department"}
                 className={`${Styles.icon} ${Styles.iconLight}`}
               ></AddCircle>
             </div>
           </div>
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Assigned Recruiter<span style={{color:"red"}}>*</span></label>
             <div className={Styles.fullWidth}>
               <TextField
                 onChange={handleChange}
                 name={"assignedRecruiter"}
                 value={formData.assignedRecruiter}
                 className={Styles.textfiled}
                 variant={"standard"}
               ></TextField>
               {showError && formData.assignedRecruiter === "" ? (
                 <FormHelperText required={true} className={Styles.error}>
                   Please Enter Assigned Recruiter
                 </FormHelperText>
               ) : (
                 ""
               )}
             </div>
           </div>
           
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Assigned Recruiter Email<span style={{color:"red"}}>*</span></label>
             <div className={Styles.fullWidth}>
               <TextField
                 onChange={handleChange}
                 name={"recruiterEmail"}
                 value={formData.recruiterEmail}
                 className={Styles.textfiled}
                 variant={"standard"}
               ></TextField>
               {showError && formData.recruiterEmail === "" ? (
                 <FormHelperText required={true} className={Styles.error}>
                   Please Enter Recruiter Email
                 </FormHelperText>
               ) : (
                 ""
               )}
             </div>
           </div>
           <Dropzone
             accept=".jpeg,.jpg,.png"
             onDrop={handleDropTemplate}
             onReject={onReject}
           >
             {({ getRootProps, getInputProps }) => (
               <div {...getRootProps({ className: `${Styles.dropTemplate}` })}>
                 <input {...getInputProps()} />
                 <h4>Drop Job Profile Image</h4>
                 <p>Drag & drop files, or click to select </p>
                 <p>Allowed file types are .jpeg, .png & .jpg</p>
               </div>
             )}
           </Dropzone>
           <Box
             style={{
               marginTop: "12px",
               marginBottom:"12px",
               display: "flex",
               flexDirection: "column",
               rowGap: "20px",
               alignItems: "center",
               justifyContent: "center",
             }}
           >
             {profilePic.length !== 0 ? (
               profilePic?.map((file, index) => {
                 return (
                   <>
                     <Box
                       style={{
                         width: "100%!important",
                         display: "flex",
                         justifyContent: "flex-start",
                         alignItems: "center",
                       }}
                       key={index}
                     >
                       <Stack spacing={2} direction={"row"}>
                         <ImageIcon />
                         <Typography
                           style={{
                             maxWidth: "70%",
                             overflow: "hidden",
                             textOverflow: "ellipsis",
                             whiteSpace: "nowrap",
                           }}
                         >
                           {file.name}
                         </Typography>
                         <Typography fontWeight={600}>
                           {Math.round(file.size / 1024)} KB
                         </Typography>
                       </Stack>
                     </Box>
                   </>
                 );
               })
             ) : (
               <h4>Please Upload Job Profile Image</h4>
             )}
           </Box>
         </div>
       </Grid>
       <Grid item xl={6} lg={6} md={6} xs={6}>
         <div className={Styles.item}>
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Min-Max Work Exp<span style={{ color: "red" }}>*</span></label>
             <div className={Styles.rangeControl}>
               <FormControl variant="standard" className={Styles.formControl}>
               <TextField
                   onChange={handleChange}
                   name={"minWorkExp"}
                   value={formData.minWorkExp}
                   className={Styles.textfield}
                   variant={"standard"}
                   InputProps={{
                     inputProps: {
                       type: 'number',
                       min: 0,
                      },
                      endAdornment: <InputAdornment position="end">Years</InputAdornment>
                   }}
               ></TextField> 
                 {showError && formData.minWorkExp === "" ? (
                   <FormHelperText required={true} className={Styles.error}>
                     Please Enter Min Work Experience
                   </FormHelperText>
                 ) : (
                   ""
                 )}
               </FormControl>
               <FormControl variant="standard" className={Styles.formControl}>
               <TextField
                   onChange={handleChange}
                   name={"maxWorkExp"}
                   value={formData.maxWorkExp}
                   className={Styles.textfield}
                   variant={"standard"}
                   InputProps={{
                     inputProps: {
                       type: 'number',
                       min: 0,
                      },
                      endAdornment: <InputAdornment position="end">Years</InputAdornment>
                   }}
               ></TextField> 
                 {showError && formData.maxWorkExp === "" ? (
                   <FormHelperText required={true} className={Styles.error}>
                     Please Enter max Work Experience
                   </FormHelperText>
                 ) : (
                   ""
                 )}
               </FormControl>
             </div>
           </div>
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Salary Range<span style={{ color: "red" }}>*</span></label>
             <div className={Styles.rangeControl}>
               <FormControl variant="standard" className={Styles.formControl}>
               <TextField
                   onChange={handleChange}
                   value={formData.minSalaryRange}
                   name={"minSalaryRange"}
                   className={Styles.textfield}
                   variant={"standard"}
                   InputProps={{
                     inputProps: {
                       type: 'number',
                       min: 0,
                      },
                      endAdornment: <InputAdornment position="end">LPA</InputAdornment>
                   }}
               ></TextField>
                 {showError && formData.minSalaryRange === "" ? (
                   <FormHelperText required={true} className={Styles.error}>
                     Please Enter Min Salary Range
                   </FormHelperText>
                 ) : (
                   ""
                 )}
               </FormControl>
               <FormControl variant="standard" className={Styles.formControl}>
               <TextField
                   onChange={handleChange}
                   value={formData.maxSalaryRange}
                   name={"maxSalaryRange"}
                   className={Styles.textfield}
                   variant={"standard"}
                   InputProps={{
                     inputProps: {
                       type: 'number',
                       min: 0,
                      },
                      endAdornment: <InputAdornment position="end">LPA</InputAdornment>
                   }}
               ></TextField> 
               
                 {showError && formData.maxSalaryRange === "" ? (
                   <FormHelperText required={true} className={Styles.error}>
                     Please Enter Max Salary Range
                   </FormHelperText>
                 ) : (
                   ""
                 )}
               </FormControl>
             </div>
           </div>
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Hiring Manager<span style={{color:"red"}}>*</span></label>
             <div className={Styles.fullWidth}>
               <TextField
                 onChange={handleChange}
                 name={"hiringManager"}
                 value={formData.hiringManager}
                 className={Styles.textfiled}
                 variant={"standard"}
               ></TextField>
               {showError && formData.hiringManager === "" ? (
                 <FormHelperText required={true} className={Styles.error}>
                   Please Enter Hiring Manger
                 </FormHelperText>
               ) : (
                 ""
               )}
             </div>
           </div>
           <div className={Styles.formFiledContainer}>
             <label htmlFor="">Hiring Manager Email<span style={{color:"red"}}>*</span></label>
             <div className={Styles.fullWidth}>
               <TextField
                 onChange={handleChange}
                 name={"hiringManagerEmail"}
                 value={formData.hiringManagerEmail}
                 className={Styles.textfiled}
                 variant={"standard"}
               ></TextField>
               {showError && formData.hiringManagerEmail === "" ? (
                 <FormHelperText required={true} className={Styles.error}>
                   Please Enter Hiring Manger
                 </FormHelperText>
               ) : (
                 ""
               )}
             </div>
           </div>
           <Dropzone
             accept=".pdf,.docx,.doc"
             onDrop={handleDrop}
             onReject={onReject}
           >
             {({ getRootProps, getInputProps }) => (
               <div {...getRootProps({ className: `${Styles.dropzone}` })}>
                 <input {...getInputProps()} />
                 <h4>Drop JD</h4>
                 <p>Drag & drop files, or click to select </p>
                 <p>Allowed file types are .pdf & .docx</p>
               </div>
             )}
           </Dropzone>
           <Box
             style={{
               marginTop: "24px",
               display: "flex",
               flexDirection: "column",
               rowGap: "20px",
               alignItems: "center",
               justifyContent: "center",
             }}
           >
             {files.length !== 0 ? (
               files?.map((file, index) => {
                 return (
                   <>
                     <Box
                       style={{
                         width: "100%!important",
                         display: "flex",
                         justifyContent: "flex-start",
                         alignItems: "center",
                       }}
                       key={index}
                     >
                       <Stack spacing={2} direction={"row"}>
                         <PictureAsPdf />
                         <Typography
                           style={{
                             maxWidth: "70%",
                             overflow: "hidden",
                             textOverflow: "ellipsis",
                             whiteSpace: "nowrap",
                           }}
                         >
                           {file.name}
                         </Typography>
                         <Typography fontWeight={600}>
                           {Math.round(file.size / 1024)} KB
                         </Typography>
                       </Stack>
                     </Box>
                   </>
                 );
               })
             ) : (
               <h4 style={{marginTop:"10px"}}>Please Upload Job JD</h4>
             )}
           </Box>
         </div>
       </Grid>
     </Grid>
     <div className={Styles.screeningContainer}>
       {/* <div className={Styles.screeningHeader}>
         <h2>Screening Questions</h2>
         <Button variant="contained">Edit</Button>
         <Button variant="contained">Delete</Button>
       </div> */}
       <div>
			{/* { screeningQuestionsLocal!==null&& screeningQuestionsLocal?.length === 0 ? (
				<h1 className={Styles.heading}>
					There Are No Questions in this Department
				</h1>
			) :  */}
      {screeningQuestionsLocal!==null ? (
        <>
					<div className={Styles.questionContainer}>
						<Paper elevation={3}>
							<h3>Screening Questions</h3>
        {screeningQuestionsLocal?.length!=0?
        <div className={Styles.question}>
        {screeningQuestionsLocal !== null &&
          screeningQuestionsLocal.map((question, index) => {
            if (question.department === "General") {
              return (
              <p key={index}>
                <Checkbox
                  value={question._id}
                  checked={check[question._id]!=undefined?check[question._id]:question.checked}
                  onChange={e => handleScreeningQuestions(e)}
                  color='primary'
                  className={Styles.checkBox}
                />
                {question.question}
            <span>
              <Edit	
                className={Styles.editIcon}
                size="large"
                onClick={() => onEditQuestionClick(question)}
              />
              <Delete
                className={Styles.deleteIcon}
                size="large"
                onClick={(e) => onConfirmRequest(question)}
              />
            </span>{" "}
          </p>
              );
            }
          })}
      </div>
        :<>
        <h1 className={Styles.heading}>
					There Are No Questions
				</h1>
        </>}
							
							<Button
								onClick={(e) => { openAddQuestionModal("General") }}
								size={"small"}
								color={"primary"}
								variant={"contained"}
								aria-label={"General"}
							>
								Add Question
							</Button>
						</Paper>
					</div>
				</>
			) : (
				""
			)}
       <Button
             style={{ marginTop: "2rem" }}
             variant="contained"
             color="primary"
             onClick={handleSubmit}
           >
             Create Job
           </Button>
              <Dialog open={addQuestionModalOpen} onClose={closeAddQuestionModal}>
                <AddQuestion
                  category={category}
                  createQuestion={createQuestion}
                  setModalOpen={setAddQuestionModalOpen}
                  editQuestionData={editQuestionData}
                  editQuestion={editQuestion}
                  updateQuestion={updateQuestion}
                  addQuestion={addQuestion}
                  setAddQuestion={setAddQuestion}
                  screeningQuestionsLocal={screeningQuestionsLocal}
                />
              </Dialog>
              <Dialog open={confirmModal} onClose={onConfirmReject}>
                <div className={Styles.deleteModal}>
                  <p>Are you sure you want to delete this Screening Question ?</p>
                  <div>
                    <Button variant="contained" onClick={onConfirmAccept}>
                      Yes
                    </Button>
                    <Button variant="outlined" onClick={onConfirmReject}>
                      Cancel
                    </Button>
                  </div>
                </div>
              </Dialog>
		</div>
     </div>

     <Dialog open={modalOpen} onClose={handleModalClose}>
       <AddDepartment
         handleDataFromAPI={handleDataFromAPI}
         category={categoryData}
         handleModalClose={handleModalClose}
       />
     </Dialog>
   </div>
 ) : (
   <div className={Styles.loadContainer}>
     <div className={Styles.loading}></div>
   </div>
 )
  :(
    <div className={Styles.loadContainer}>
      <div className={Styles.loading}></div>
    </div>
  )
};

const mapStateToProps = (state) => ({
  countries: state.Location.countries,
  states: state.Location.states,
  cities: state.Location.cities,
  departments: state.Positions.departmentList,
  postingTitles: state.Positions.postingTitles,
  screeningQuestions: state.Positions.screeningQuestion,
  createJobLoading: state.Positions.jobLoading,
  departmentQuestions: state.ScreeningQuestion.departmentQuestions,
  generalQuestions: state.ScreeningQuestion.generalQuestions,
});

export default connect(mapStateToProps, {
  getCountries,
  getStates,
  getCities,
  createJob,
  emptyCities,
  emptyStates,
  getDepartments,
  getPostingTitles,
  uploadFile,
  getGeneralQuestions,
  getDepartmentWiseQuestion,
  createQuestion,
  updateQuestion,
  deleteQuestion,
})(CreateJob);
