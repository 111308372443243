import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Container from "@mui/material/Container";
import { SignOut } from "../../firebaseConfig";
import { Button, Dialog } from "@material-ui/core";
import Styles from "../Navbar/Navbar.module.scss";

function Header() {
  const [modalOpen, setModalOpen] = React.useState(false);

  const showConfirmDialog = () => {
    setModalOpen(true);
  };
  const onCancel = () => {
    setModalOpen(false);
  };

  const onAccept = () => {
    const log = SignOut();
    window.location.reload()
  };
  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img
            src="android-chrome-192x192.png"
            alt=""
            style={{ height: "64px", margin: "10px" }}
          />

          <Box sx={{ flexGrow: 0, width: "100%" }}>
            <div style={{float: "right"}}>
              <Button
                sx={{
                  background:"white",
                  width: "20% !important",
                  padding: "16px",
                  margin: "10px",
                  textDecoration: "none",
                }}
                variant="contained"
                type="button"
                onClick={showConfirmDialog}
              >
                {" "}
                Logout
              </Button>
            </div>
          </Box>
        </Toolbar>
        <Dialog open={modalOpen}>
          <div className={Styles.deleteModal}>
            <p>Are you sure you want to logout ?</p>
            <div>
              <Button variant="contained" onClick={onAccept}>
                Yes
              </Button>
              <Button variant="outlined" onClick={onCancel}>
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>
      </Container>
    </AppBar>
  );
}
export default Header;
