import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { PageContainer } from "../../styled/SharedComponents";
import {
  getAnalyticsData,
  getDepartments,
  getPostingTitles,
} from "../../actions/positions";
import { FormControl, Select, MenuItem, CircularProgress } from "@material-ui/core";
import Styles from "../Dashboard/Vacancies/Vacancies.module.scss";

import { connect } from "react-redux";
import PieChart from "./pieChart";
import EChartComponent from "./barGraph";
import { Divider } from "@mui/material";
import JobSelect from "../GlobalComponents/JobSelect/JobSelect";

const data = [
  {
    label: "Total number of candidates",
    key: "candidates",
  },
  {
    label: "Screening completed",
    key: "completed",
  },
  {
    label: "Total responded",
    key: "responded",
  },
  {
    label: "Total ongoing ",
    key: "ongoing",
  },
];

const piechart = [
  {
    label: " Total inbound",
    key: "inbound",
  },
  {
    label: "Total outbound",
    key: "outbound",
  },
];

const barChart = [
  {
    label: "Screen",
    key: "screen",
  },
  {
    label: "Source",
    key: "source",
  },
  {
    label: " Total Scheduled ",
    key: "schedule",
  },
  {
    label: "Total interview",
    key: "interview",
  },
  {
    label: " Total offer stage",
    key: "offer",
  },
];

const bottomTiles = [
  {
    label: "Responses within first 2 hours",
    key: "hours_2",
  },
  {
    label: "Responses within first 6 hours",
    key: "hours_6",
  },
  {
    label: " Responses within 24 hours",
    key: "hours_24",
  },
  {
    label: " CV Match score greater than 60%",
    key: "matchScore",
  },
  {
    label: "Screening score greater then 60%",
    key: "screeningScore",
  },
  {
    label: "Total no reply",
    key: "noReply",
  },
];

const CardComponent = ({item, getAnalytics}) => {
  return (
    <>
      <CardContent
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "35px !important",
            color: "white",
            textAlign: "center",
            justifyContent: "center",
            marginTop: "50px",
          }}
          variant="body2"
        >
          {getAnalytics?.[item.key] ?? "--"}
        </Typography>
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "15px !important",
            color: "white",
            textAlign: "center",
            marginTop: "32px",
          }}
          gutterBottom
        >
          {item.label ?? "--"}
        </Typography>
      </CardContent>
    </>
  );
};

const Analytics = (props) => {
  const {
    getDepartments,
    getPostingTitles,
    departmentList,
    postingTitles,
    getAnalyticsData,
    getAnalytics,
    analyticsLoading
  } = props;
  const [formData, setFormData] = React.useState({
    position: "",
    department: "",
  });
  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const filterObj = {
      ...formData,
      [e.target.name]: e.target.value,
    };

    let mainFilter = {};
    for (var key in filterObj) {
      if (filterObj[key] !== "") {
        mainFilter[key] = filterObj[key];
      }
    }
  };
  React.useEffect(() => {
    getDepartments();
    getPostingTitles();
  }, []);
  React.useEffect(() => {
    getAnalyticsData(formData);
  }, [formData]);
  return (
    <PageContainer>
      {analyticsLoading ?(
        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
          <CircularProgress />
        </div>
      ):
      <>
      <div className={Styles.filterBox}>
      <FormControl variant="standard" className={Styles.formControl}>
        <div style={{ width: "100%" }}>
          <JobSelect
            value={formData}
            onChange={setFormData}
            fullWidth
            jobStyle={true}
            postingName={true}
          />
        </div>
      </FormControl>
    </div>
    <div style={{ display: "flex" }}>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          height: "100%",
          width: "100%",
        }}
      >
        {data.map((item) => (
          <Card
            sx={{ height: "200px", width: "200px  ", background: "#9e77ed !important" }}
          >
            <CardComponent item={item} getAnalytics={getAnalytics}/>
          </Card>
        ))}
        {bottomTiles.map((item) => (
          <Card
            sx={{ height: "200px", width: "200px  ", background: "#9e77ed !important" }}
          >
            {/* {card(item, getAnalytics)} */}
            <CardComponent item={item} getAnalytics={getAnalytics}/>
          </Card>
        ))}
      </Box>
    </div>
    <br></br>
    <Divider />
    <div style={{ display: "flex", marginTop: "15px" }}>
      <EChartComponent data={getAnalytics?.barChart} />
      <PieChart data={getAnalytics?.pieChart} />
    </div>
    </>
      }
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  departmentList: state.Positions.departmentList,
  postingTitles: state.Positions.postingTitles,
  jobList: state.Positions.jobList,
  getAnalytics: state.Positions.getAnalytics,
  candidateList: state.Outreach.candidateList,
  chat: state.Chat.chat,
  chatLoading: state.Chat.loading,
  files: state.Chat.files,
  analyticsLoading:state.Positions.analyticsLoading
});

export default connect(mapStateToProps, {
  getDepartments,
  getPostingTitles,
  getAnalyticsData,
})(Analytics);
