import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./reducers";
import Location from "./reducers/location";
import Positions from "./reducers/positions";
import Alert from "./reducers/alert";
import Outreach from "./reducers/outreach";
import Chat from "./reducers/chat";
import ScreeningQuestion from "./reducers/screeningQuestion";
import Auth from "./reducers/auth";
import Upload from "./reducers/upload";
import persistStore from "redux-persist/es/persistStore";
import { candidatesApi } from "./slices/candidates";
import { appConfigApi } from "./slices/appConfig";
import { candidateFiltersSlice } from "./slices/candidateFilters";

export const store = configureStore({
  reducer: {
    Location,
    Positions,
    Outreach,
    Chat,
    ScreeningQuestion,
    Alert,
    Auth,
    Upload,
    [candidatesApi.reducerPath]: candidatesApi.reducer,
    [appConfigApi.reducerPath]: appConfigApi.reducer,
    [candidateFiltersSlice.name]: candidateFiltersSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      candidatesApi.middleware,
      appConfigApi.middleware,
    ]),
});

// export const store = createStore(
//   rootReducer,
//   initialState,
//   composeWithDevTools(applyMiddleware(...middleWare))
// );

export const persistor = persistStore(store);
