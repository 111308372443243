import { Box, Divider, Stack, Typography } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import { Phone, LocationOn } from "@mui/icons-material";

export default ({ parsedData }) => {
  console.log(parsedData);
  return (
    <Box
      width={"100%"}
      sx={{
        borderRadius: "16px",
        border: "1px solid #eaecf0",
        padding: "24px",
        display: "flex",
        flexDirection: "column",
        rowGap: "24px",
      }}
    >
      <Typography
        variant="h5"
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"space-around"}
        fontWeight={600}
        width={"100%"}
      >
        About me
      </Typography>
      {/* About typography */}
      <Typography fontSize={"16px"} fontWeight={400} color={"#475467"}>
        {parsedData?.Summary}
      </Typography>
      <Divider />

      <Typography
        sx={{
          color: "#344054",
          fontWeight: 600,
          lineHeight: "24px",
        }}
        width={"100%"}
      >
        Other details
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          rowGap: "24px",
          columnGap: "24px",
        }}
      >
        {parsedData?.Email[0] && (
          <Stack direction={"row"} gap={"12px"}>
            <EmailIcon />
            {parsedData.Email[0].EmailAddress}
          </Stack>
        )}
        {parsedData?.PhoneNumber[0] && (
          <Stack direction={"row"} gap={"12px"}>
            <Phone />
            {parsedData?.PhoneNumber[0]?.Number}
          </Stack>
        )}
        {/* not sure if location is the child */}
        {/* {parsedData?.CurrentLocation[0] && (
          <Stack direction={"row"} gap={"12px"}>
            <LocationOn />
            {parsedData?.CurrentLocation[0]?.Location}
          </Stack>
        )} */}
      </Box>
    </Box>
  );
};
