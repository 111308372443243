/** @format */

import {
	TableContainer,
	Paper,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Button,
	Fab,
	TextField,
	Select,
	MenuItem,
	FormControl,
	FormHelperText,
	InputLabel,
	RadioGroup,
	FormLabel,
	FormControlLabel,
	Radio
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import React, { useState, useEffect } from "react";
import Styles from "./Contact.module.scss";
import "./Contact.module.scss";
import "./util.scss";
import { connect } from "react-redux";
import {
	addCandidate,
	removeCandidate,
	createCandidate,
	addMultipleCandidates,
	removeAllCandidates
} from "../../../../actions/outreach";
import {
	getDepartments,
	getJobList,
	getPostingTitles
} from "../../../../actions/positions";
import { AuthAxios } from "../../../../utils/api";


const Contact = props => {
	const {
		candidatesList,
		addCandidate,
		removeCandidate,
		getDepartments,
		getPostingTitles,
		departmentList,
		postingTitles,
		createCandidate,
		addMultipleCandidates,
		removeAllCandidates,
		jobList,
		getJobList
	} = props;

	useEffect(() => {
		getDepartments();
		getPostingTitles();
		getJobList();
	}, []);

	const [globalData, setGlobalData] = useState({
		position: "",
		department: "",
		departmentId:""
	});
	const [formData, setFormData] = useState({
		name: "",
		phone: "",
		email: ""
	});
	const [showError, setShowError] = useState(false);
	const [globalError, setGlobalError] = useState(false);

	const [template, setTemplate] = useState({
		gupshup:"",
		twilio:"",
		mail:"",
	});
	const [templates, setTemplates] = useState([]);

	const [selectedOptions, setSelectedOptions] = useState({
		WhatsApp: true,
		Mail: false,
		Twilio: false,
	  });
	
	  const handleOptionChange = (event) => {
		const optionName = event.target.name;
		setSelectedOptions((prevSelected) => ({
			...prevSelected,
			[optionName]: !prevSelected[optionName],
		}));
	  };

	useEffect( () => {
		const data =  AuthAxios.get(`${process.env.REACT_APP_API_URL}/templates`).then((data)=>{
			console.log(data.data);
			setTemplates(data.data);
			setTemplate({gupshup:data.data[0]._id});
		});
		
	}, []);

	const onChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const gupshupSelectedTemplate = () => {
		try{

			const optTemplate = templates.find(t => t._id === template.gupshup);
		const formattedTemplate = replaceTemplateVars(optTemplate.content, candidatesList[0] )
		optTemplate.content = formattedTemplate;
		return optTemplate
		}catch(err){
		}
		
	};

	const mailSelectedTemplate = () => {
		try{

			const optTemplate = templates.find(t => t._id === template.mail);
		const formattedTemplate = replaceTemplateVars(optTemplate.content, candidatesList[0] )
		optTemplate.content = formattedTemplate;
		return optTemplate
		}catch(error){
		}
		
	};

	const twilioSelectedTemplate = () => {
		try{

			const optTemplate = templates.find(t => t._id === template.twilio);
		const formattedTemplate = replaceTemplateVars(optTemplate.content, candidatesList[0] )
		optTemplate.content = formattedTemplate;
		return optTemplate
		}catch(error){
		}
		
	};

	const  replaceTemplateVars =(str, variables)=> {
		return str.replace(/\$\{(\w+)\}/g, (_, varName) => variables[varName] || "");
	  }

	const handleTemplateChange = (event) =>{
		const optionName = event.target.name;
		const value = event.target.value
		setTemplate((prevSelected) => ({
			...prevSelected,
			[optionName]: value,
		}));
	}

	const onGlobalChange = e => {
		const selectedJob = jobList.filter(el => el._id === e.target.value);
		setGlobalData({
			...globalData,
			position: e.target.value,
			department: selectedJob[0].departmentName,
			departmentId:selectedJob[0].department
		});
		// setGlobalData({ ...globalData, department: selectedJob.department });
	};

	const addData = () => {
		let flag;
		const formDataCopy = Object.keys(formData);
		formDataCopy.forEach(key => {
			if (formData[key] === "") {
				return (flag = true);
			}
		});
		if (flag) {
			return setShowError(true);
		}
		if (!flag) {
			setShowError(false);
			addCandidate({...formData, phone: `+91${formData['phone']}`});
			setFormData({
				...formData,
				email: "",
				name: "",
				phone: ""
			});
		}
	};

	const postCandidate = () => {
		let flag;
		const formDataCopy = Object.keys(formData);

		console.log(formDataCopy);

		formDataCopy.forEach(key => {
			if (formData[key] === "") {
				return (flag = true);
			}
		});
		if (flag) {
			return setShowError(true);
		}
		if (!flag) {
			setShowError(false);
			createCandidate(formData, globalData);
			setFormData({
				...formData,
				email: "",
				name: "",
				phone: ""
			});
		}
	};

	const deleteCandidate = index => {
		removeCandidate(index);
	};

	const reachOut = () => {
		const requestData = { template }
		requestData.candidates = candidatesList.map(candidate => ({
			...candidate,
			jobId: globalData.position,
			departmentId: globalData.departmentId,
			chatType: "outbound",
			channel:selectedOptions,
			createdAt: Math.floor(Date.now()),
		}));
		let flag;
		if (globalData.position === "" || globalData.department === "") {
			flag = true;
		}

		if (flag) {
			setGlobalError(true);
		}
		const isSelectedChannel = Object.keys(selectedOptions).some(key=>selectedOptions[key]!=false);
		if(!isSelectedChannel){
			setGlobalError(true);
		}
		if (!flag) {
			addMultipleCandidates(requestData);
			setGlobalData({ ...globalData, position: "", department: "",departmentId:'' });
			setGlobalError(false);
			removeAllCandidates();
		}
	};
	return (
		<div className={Styles.container}>
			<div className={Styles.globalContainer}>
				<div className={Styles.SelectionField}>
					<label htmlFor=''>Applying Position</label>
					<FormControl variant='standard' className={Styles.formControl}>
						<Select
							value={globalData.position}
							onChange={e => onGlobalChange(e)}
							name={"position"}
							displayEmpty
							className={Styles.select}
							inputProps={{ "aria-label": "Without label" }}
						>
							<MenuItem className={Styles.menuitem} value='' disabled>
								Select Position Here
							</MenuItem>
							{jobList !== null &&
								jobList.length !== 0 &&
								jobList.map(jobList => {
									return (
										<MenuItem className={Styles.menuitem} value={jobList._id}>
											{jobList.postingTitle.postingTitle}
										</MenuItem>
									);
								})}
						</Select>
						{globalError && globalData.position === "" ? (
							<FormHelperText required={true} className={Styles.error}>
								Please Select Position
							</FormHelperText>
						) : (
							""
						)}
					</FormControl>
				</div>
				<div className={Styles.SelectionField}>
					<label htmlFor=''>Department</label>
					<FormControl variant='standard' className={Styles.formControl}>
						<TextField
							value={globalData.department || ""}
							className={Styles.textfiled}
							variant={"standard"}
							disabled
						></TextField>
						{globalError && globalData.department === "" ? (
							<FormHelperText required={true} className={Styles.error}>
								Please Select Department
							</FormHelperText>
						) : (
							""
						)}
					</FormControl>
				</div>
			</div>
			<div className={Styles.header}>
				<h2>Add Candidate one by one</h2>
				<span>Add Candidates to be contacted</span>
			</div>
			<div className={Styles.form}>
				<div className={Styles.formContainer}>
					<div>
						<label htmlFor=''>Name</label>

						<TextField
							name={"name"}
							value={formData.name}
							onChange={e => onChange(e)}
							variant={"standard"}
							placeholder={"Enter Candidate Name"}
						></TextField>
						{showError && formData.name === "" ? (
							<FormHelperText required={true} className={Styles.error}>
								Please Enter Name
							</FormHelperText>
						) : (
							""
						)}
					</div>
					<div>
						<label htmlFor=''>Phone</label>
						<div style={{display: "flex", justifyContent: "center"}}>
							<div style={{fontSize: 16, width: 50, paddingTop: 6}}>
								+91 -
							</div>
							<TextField
								name={"phone"}
								value={formData.phone}
								onChange={e => onChange(e)}
								variant={"standard"}
								placeholder={"Enter Candidate Phone"}
							></TextField>
							{showError && formData.phone === "" ? (
								<FormHelperText required={true} className={Styles.error}>
									Please Enter Phone
								</FormHelperText>
							) : (
								""
							)}
						</div>
					</div>
					<div>
						<label htmlFor=''>Email</label>

						<TextField
							name={"email"}
							value={formData.email}
							onChange={e => onChange(e)}
							variant={"standard"}
							placeholder={"Enter Candidate Email"}
						></TextField>
						{showError && formData.email === "" ? (
							<FormHelperText required={true} className={Styles.error}>
								Please Enter Email
							</FormHelperText>
						) : (
							""
						)}
					</div>
				</div>
				<Fab onClick={() => addData()} className={Styles.fab}>
					<Add className={Styles.fabIcon} />
				</Fab>
			</div>
			<div className={Styles.list}>
				<h2>Candidate List</h2>
				{candidatesList.length !== 0 ? (
					<TableContainer className={Styles.tableContainer} component={Paper}>
						<Table size='small' aria-label='a dense table'>
							<TableBody>
								{candidatesList.map((candidate, index) => {
									return (
										<TableRow key={index}>
											<TableCell>{candidate.name}</TableCell>
											<TableCell>{candidate.email}</TableCell>
											<TableCell>{candidate.phone}</TableCell>
											<TableCell>
												<Delete
													onClick={() => deleteCandidate(index)}
													className={Styles.icon}
												/>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table>
					</TableContainer>
				) : (
					<h4 className={Styles.warning}>
						Please add a candidate or upload a file.
					</h4>
				)}
				{candidatesList.length !== 0 ? (
					<>
						<div style={{padding: 8, display: "flex", marginTop: 16, fontSize: 16}}>
							<FormControl style={{width: 320}}>
								<br></br>
								<FormLabel> connect</FormLabel>

					
									<FormControlLabel name="WhatsApp"  value={selectedOptions.WhatsApp} control={<Radio />} checked={selectedOptions.WhatsApp}  onClick={handleOptionChange} label="Whatsapp" />
									<FormControl>
									<InputLabel id="demo-simple-select-label">WhatsApp Template</InputLabel>
										<Select
											labelId="lable1"
											id="demo-simple-select"
											value={template.gupshup}
											name="gupshup"
											style={{ fontSize: 18 }}
											label="Age"
											onChange={(e) =>handleTemplateChange(e) }
										>
											{templates.map((t) => {
												if(t.channel =="gupshup"){
													return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
												}
												
											})
											}
										</Select>
									</FormControl>
								
									<FormControlLabel  name="Mail" value={selectedOptions.Mail} control={<Radio />} checked={selectedOptions.Mail} onClick={handleOptionChange}label="Mail" />
											<FormControl>
											<InputLabel id="demo-simple-select-label">Mail Template</InputLabel>
												<Select
													labelId="lable2"
													id="lable2"
													value={template.mail}
													name="mail"
													style={{ fontSize: 18 }}
													label="Age"
													onChange={(e) =>handleTemplateChange(e) }
												>
													{templates.map((t) => {
														if(t.channel=="mail"){
															return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
														}	
													})
													}
												</Select>
											</FormControl>
									<FormControlLabel name="Twilio" value={selectedOptions.Twilio} control={<Radio />} checked={selectedOptions.Twilio} onClick={handleOptionChange} label="WhatsApp (US)" />
											<FormControl>
											<InputLabel id="lable3">WhatsApp (US) Template</InputLabel>
												<Select
													labelId="lable3"
													id="lable3"
													value={template.twilio}
													name="twilio"
													style={{ fontSize: 18 }}
													label="Age"
													onChange={(e) =>handleTemplateChange(e) }
												>
													{templates.map((t) => {
														if(t.channel=="twilio"){
															return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
														}
													})
													}
												</Select>
											</FormControl>
							</FormControl>
							<div style={{ display: "flex", flexDirection: "column","width": "300px" }}>
								<div className={Styles.SelectionField} style={{ flex: 1, marginLeft: 16 }}>
									{gupshupSelectedTemplate() && gupshupSelectedTemplate().content}
								</div>
								{
									selectedOptions.Mail ?
										<><br></br>
											<div className={Styles.SelectionField} style={{ flex: 1, marginLeft: 16 }}>
												{mailSelectedTemplate() && mailSelectedTemplate().content}
											</div></> : <></>
								}
								{
									selectedOptions.Twilio ?
										<>	<br></br>
											<div className={Styles.SelectionField} style={{ flex: 1, marginLeft: 16 }}>
												{twilioSelectedTemplate() && twilioSelectedTemplate().content}
											</div></> : <></>
								}
							</div>
							
						</div>
						<div className={Styles.buttonGroup}>
							<Button
								variant='contained'
								className={Styles.primary}
								onClick={reachOut}
							>
								Reach out
							</Button>
							<Button variant='outlined' className={Styles.secondary}>
								Cancel
							</Button>
						</div>
					</>
				) : (
					" "
				)}
			</div>
		</div>
	);
};

const mapStateToPros = state => ({
	candidatesList: state.Outreach.candidates,
	departmentList: state.Positions.departmentList,
	postingTitles: state.Positions.postingTitles,
	jobList: state.Positions.jobList
});

export default connect(mapStateToPros, {
	addCandidate,
	removeCandidate,
	getDepartments,
	getPostingTitles,
	createCandidate,
	addMultipleCandidates,
	removeAllCandidates,
	getJobList
})(Contact);
