/** @format */

import React, { useEffect, useState } from "react";
import Styles from "./Profile.module.scss";
import {
  Dialog,
  TextField,
  Button,
  DialogContent,
  FormControl,
  FormLabel,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  DialogActions,
  MenuItem,
  Radio,
  Select,
  DialogTitle,
  Tabs,
  AppBar,
  Tab,
  Typography,
  Box
} from "@material-ui/core";
import { CloudDownload, InsertDriveFile } from "@material-ui/icons";
import { connect } from "react-redux";
import { updateCandidate, nudge } from "../../../../actions/outreach";
import { getJobList } from "../../../../actions/positions";
import { transcript, emptyChat } from "../../../../actions/chat";
import { showAlert } from "../../../../actions/alert";
import { AuthAxios } from "../../../../utils/api";
import { TabContext, TabPanel } from "@material-ui/lab";

const Profile = props => {
	// var gapi = window.gapi;
	// const CLIENT_ID =
	// 	"558966726875-lp8s1regdu81n8ischv9ocr8be7fcs6b.apps.googleusercontent.com";
	// const API_KEY = "AIzaSyDQ5r6sL4jOpTMR4FwaiJnh05f3Dgr10Dk";
	// const DISCOVERY_DOCS = [
	// 	"https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
	// ];
	// const SCOPES = "https://www.googleapis.com/auth/calendar.events";

  const {
    singleCandidate,
    setSingleCandidate,
    updateCandidate,
    showAlert,
    getJobList,
    nudge,
    candidateFiles,
    files,
    transcript,
    scriptLoading,
    script,
    filesLoading
  } = props;
  const [formData, setFormData] = useState({
    notes: "",
  });
  const [modalState, setModalState] = useState(false);
  const [scheduleData, setScheduleData] = useState({
    title: "",
  });
  const [nudgeModal, setNudgeModalState] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    WhatsApp: true,
    Mail: false,
    Twilio: false,
  });
  const [template, setTemplate] = useState({
		gupshup:"",
		twilio:"",
		mail:"",
	});
	const [templates, setTemplates] = useState([]);
  const [globalError, setGlobalError] = useState(false);
  const [filesModal, setFilesModal] = useState(false);
  const [value, setValue] = useState(0);
  const [templateError, setTemplateError] = useState(false);
  const [ scriptModal, setScriptModal ] = useState(false);
  const [transScript, setTransScript] = useState("");

  const handleOptionChange = (event) => {
    const optionName = event.target.name;
    setSelectedOptions((prevSelected) => ({
      ...prevSelected,
      [optionName]: !prevSelected[optionName],
    }));
  };
  const onScheduleChange = (e) => {
    setScheduleData({ ...scheduleData, [e.target.name]: e.target.value });
  };


  const addNotesLocal = (e, id) => {
    if (id) {
      updateCandidate(id, { notes: e.target.value }, setSingleCandidate);
    } else {
      showAlert("Please Select a candidate", "error");
      setFormData({ ...formData, notes: "" });
    }
  };
  const handleModalClose = () => {
    setModalState(false);
  };
  const addAction = (e, id, actionName) => {
    if (id) {
      updateCandidate(id, { action: actionName }, setSingleCandidate);
      if (actionName !== "Schedule") {
        getJobList();
        setSingleCandidate({});
      }
      setModalState(false);
    } else {
      showAlert("Please Select a Candidate", "error");
    }
  };

  const openModal = (e, id) => {
    if (id) {
      setModalState(true);
    } else {
      showAlert("Please Select a Candidate", "error");
    }
  };
  const openNudgeModal = () => {
    setNudgeModalState(true);
  };

  const closeNudgeModal = ()=>{
    setNudgeModalState(false);
  };

  const fetchData = async()=>{
    const data = await AuthAxios.get(`${process.env.REACT_APP_API_URL}/templates`);
		console.log(data.data);
		setTemplates(data.data);
		setTemplate({gupshup:data.data[0]._id});
  }

  useEffect( () => {
    fetchData();
	}, []);
  const handleTemplateChange = (event) => {
    const optionName = event.target.name;
    const value = event.target.value
    setTemplate((prevSelected) => ({
      ...prevSelected,
      [optionName]: value,
    }));
  }
  const reachOut = () => {
		const isSelectedChannel = Object.keys(selectedOptions).some(key=>selectedOptions[key]!=false);
    const isTemplateEmpty = Object.values(template).every((value) => value === '');
		if(!isSelectedChannel){
      setGlobalError(true);
		}else if(isTemplateEmpty){
      setTemplateError(true);
    }
    else{
      console.log('selectedCandidates:',)
			nudge([singleCandidate._id], template, selectedOptions);
      closeNudgeModal()
		}
  }

  const onSchedule = async (e, candidate) => {
    // gapi.load("client:auth2", () => {
    // 	console.log("loaded client");
    // 	gapi.client.init({
    // 		apiKey: API_KEY,
    // 		clientId: CLIENT_ID,
    // 		discoveryDocs: DISCOVERY_DOCS,
    // 		scope: SCOPES
    // 	});
    // 	gapi.client.load("calendar", "v3", () => console.log("bam!"));
    // 	gapi.auth2
    // 		.getAuthInstance()
    // 		.signIn()
    // 		.then(() => {
    // 			var event = {
    // 				summary: scheduleData.title,
    // 				description: `We have scheduled a meeting with ${singleCandidate.name}`,
    // 				start: {
    // 					date: scheduleData.date
    // 				},
    // 				end: {
    // 					date: scheduleData.date
    // 				},
    // 				attendees: [{ email: "mr.rushil17@gmail.com" }]
    // 			};
    // 			// console.log(event);
    // 			var request = gapi.client.calendar.events.insert({
    // 				calendarId: "primary",
    // 				resource: event
    // 			});
    // 			request.execute(event => {
    // 				console.log(event);
    // 				window.open(event.htmlLink);
    // 			});
    // 			setModalState(false);
    // 			addAction(e, singleCandidate._id, "Schedule");
    // 		});
    // });

    try {
      const requestBody = {
        summary: scheduleData.title,
        description: `We have scheduled a meeting with ${singleCandidate.name}`,
        start: {
          date: scheduleData.date,
        },
        end: {
          date: scheduleData.date,
        },
        attendees: [{ email: "mr.rushil17@gmail.com" }],
        reminders: {
          useDefault: false,
          overrides: [
            {
              method: "email",
              minutes: 1,
            },
          ],
        },
      };

      //   const newEvent = await Axios.post(
      //     `https://www.googleapis.com/calendar/v3/calendars/primary/events`,
      //     requestBody,
      //     {
      //       headers: {
      //         Authorization: `${tokenObj.token_type} ${tokenObj.access_token}`,
      //       },
      //     }
      //   );

      showAlert("Candidate Scheduled Successfully", "success");
      addAction(e, singleCandidate._id, "Schedule");
      setModalState(false);
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (e) => {
    setFormData({ ...formData, notes: e.target.value });
  };

  useEffect(() => {
    if (singleCandidate.notes) {
      setFormData({ ...formData, notes: singleCandidate.notes });
    } else {
      setFormData({ ...formData, notes: "" });
    }
  }, [singleCandidate]);

  console.log(singleCandidate);
  const fileName = (url)=>{
    const link = new URL(url);
    const name = link.pathname.split('.')[1];
    console.log("name", name)
    return name
  }

  const openFilesModal = ()=>{
    setFilesModal(true);
    candidateFiles(singleCandidate._id)
  }
  function fileExtenstion(text) {
    const url = new URL(text);
    const ext = url.pathname.split('.').pop();
    return ext
  }

  function MediaPreview({url}) {
    const mediaUrl = new URL(url);
    const fileName = mediaUrl.pathname.split('/')[2];
    const ext = fileExtenstion(url).toLowerCase();
    const isImage = ext=='jpg' || ext=='png' || ext=='jpeg';
    const isVideo = ext=='mp4' || ext=='avi' || ext=='mov' ||ext=='webm';
    const isAudio = ext=='odd' || ext=='mp3';
    return (
      <div className={Styles.mediaItem}>
        {isImage ? (
          <div>
            <a href={mediaUrl} target="_blank">

            <img src={mediaUrl} alt="Image" width="320px" height="200px" />
            </a>
          </div>
        ) : isVideo ? (
          <div className={Styles.videoWrapper}>
            <video controls width="320px" height="200px">
              <source src={mediaUrl} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className={Styles.videoText}>
              <p onClick={() => {getTranscript(fileName)}}>Trans script</p>
            </div>
          </div>
        ) : isAudio ? 
        <audio controls>
          <source src={mediaUrl} type="audio/ogg" />
        </audio> : (
          <p>Unsupported media type</p>
        )}
      </div>
    );
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClose =()=>{
    setFilesModal(false)
  }

  const transScriptModal = ()=>{
    setScriptModal(false)
  };

  const getTranscript = async (url)=>{  
    setScriptModal(true);
    const response = transcript(url);
  };
  console.log(filesLoading,'============filesLoading==============')

  return (
    <div className={Styles.profileContainer} style={{ overflowY: "auto" }}>
      <div className={Styles.profileHeader}>
        <div className={Styles.outerCircle}>
          <img src="/defaultimg.png" alt="" />
          <div className={Styles.innerCircle}></div>
        </div>
        <p>{singleCandidate.name}</p>
        <p>{singleCandidate.postingTitle}</p>
      </div>
      <div className={Styles.headerInfo}>
        <div className={Styles.Information}>
          <h2>Informations</h2>
          <p>Phone - {singleCandidate.phone} </p>
          <p>Email - {singleCandidate.email}</p>
          <p>User ID - {singleCandidate.uid} </p>
          <p>
            Skills -{" "}
            {singleCandidate.skills
              ? singleCandidate.skills
              : "No skill available"}
          </p>
        </div>
        <div >
          <FormControl variant="outlined">
          <InputLabel style={{ color: '#8681ff' }}>Select Action</InputLabel>
            <Select
            style={{color:"#8681ff"}}
              label="Select Action"
            >
              <MenuItem value="">Action</MenuItem>
              <MenuItem
                value="Schedule"
                className={
                  singleCandidate.action === "Schedule" ? Styles.schedule : ""
                }
                onClick={(e) => openModal(e, singleCandidate._id)}
              >
                Schedule
              </MenuItem>
              <MenuItem
                value="Disqualify"
                className={
                  singleCandidate.action === "Disqualify" ? Styles.disqualify : ""
                }
                onClick={(e) => addAction(e, singleCandidate._id, "Disqualify")}
              >
                Disqualify
              </MenuItem>
              <MenuItem
                value="Assess Further"
                className={
                  singleCandidate.action === "Assess Further" ? Styles.assess : ""
                }
                onClick={(e) => addAction(e, singleCandidate._id, "Assess Further")}
              >
                Assess Further
              </MenuItem>
              <MenuItem
                value="Future Reference"
                className={
                  singleCandidate.action === "Future Reference" ? Styles.reference : ""
                }
                onClick={(e) => addAction(e, singleCandidate._id, "Future Reference")}
              >
                Future Reference
              </MenuItem>
              <MenuItem
                value="Shortlist"
                className={
                  singleCandidate.action === "Shortlist" ? Styles.shortlist : ""
                }
                onClick={(e) => addAction(e, singleCandidate._id, "Shortlist")}
              >
                Shortlist
              </MenuItem>
              {Object.keys(singleCandidate).length ? (
                <MenuItem
                  value="Nudge"
                  className={
                    singleCandidate.action === "Shortlist" ? Styles.shortlist : ""
                  }
                  onClick={openNudgeModal}
                >
                  Nudge
                </MenuItem>
              ) : (
                <></>
              )}
            </Select>
          </FormControl>
        </div>
        <div className={Styles.files}>
          <h2>Files</h2>
          <div>
            {/* {files? ( */}
              <Button className={Object.keys(singleCandidate).length?Styles.filesBtn:Styles.disableFilesBtn} onClick={openFilesModal} disabled={Object.keys(singleCandidate).length?false:true} >
                Files
              </Button>
            {/* ) : (
              <h3>No Files Available</h3>
            )} */}
          </div>
        </div>
        <div className={Styles.notes}>
          <h4>Notes</h4>
          <TextField
            placeholder="Add Notes Here"
            multiline
            rows={6}
            variant={"outlined"}
            value={formData.notes}
            onChange={(e) => onChange(e)}
            onBlur={(e) => addNotesLocal(e, singleCandidate._id)}
          />
        </div>
      </div>
      <Dialog open={modalState} onClose={handleModalClose}>
        <div className={Styles.modal}>
          <h4>Schedule A Meeting with {`${singleCandidate.name}`} </h4>
          <div>
            <label htmlFor="">Description</label>
            <TextField
              name={"title"}
              className={Styles.textfiled}
              variant={"standard"}
              value={scheduleData.title}
              onChange={onScheduleChange}
            ></TextField>
          </div>
          <div>
            <label htmlFor="">Date</label>
            <TextField
              id="date"
              type="date"
              name="date"
              className={Styles.textField}
              InputLabelProps={{
                shrink: true,
              }}
              onChange={onScheduleChange}
            />
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => onSchedule()}
          >
            Schedule
          </Button>
        </div>
      </Dialog>
      <Dialog
        open={nudgeModal}
        onClose={closeNudgeModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{
          background: "#3f51b5",
          color:"white",
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}>
          <p style={{fontSize:"24px"}}>Nudge</p>
        </DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop:"50px", paddingBottom:"50px" }}>
        <DialogContent>
          <FormControl style={{ width: 320 }}>
            <FormLabel>Connect</FormLabel>
            <FormControlLabel
              name="WhatsApp"
              value={selectedOptions.WhatsApp}
              control={<Radio />}
              checked={selectedOptions.WhatsApp}
              onClick={handleOptionChange}
              label="Whatsapp"
            />
            	{globalError? (
							<FormHelperText
               required={true} className={Styles.error}>
								Please Select channel
							</FormHelperText>
						) : (
							""
						)}
            <FormControl>
            {templateError? (
							<FormHelperText required={true} className={Styles.error}>
								Please Select Template
							</FormHelperText>
						) : (
							""
						)}
              <InputLabel id="demo-simple-select-label">
                WhatsApp Template
              </InputLabel>
              <Select
                labelId="lable1"
                id="demo-simple-select"
                value={template.gupshup}
                name="gupshup"
                style={{ fontSize: 18 }}
                label="Age"
                onChange={(e) => handleTemplateChange(e)}
              >
                {templates.map((t) => {
                  if (t.channel === 'gupshup') {
                    return (
                      <MenuItem
                        key={t._id}
                        style={{ fontSize: 18 }}
                        value={t._id}
                      >
                        {t.name}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </FormControl>
            <FormControlLabel name="Mail" value={selectedOptions.Mail} control={<Radio />} checked={selectedOptions.Mail} onClick={handleOptionChange} label="Mail" />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Mail Template</InputLabel>
              <Select
                labelId="lable2"
                id="lable2"
                value={template.mail}
                name="mail"
                style={{ fontSize: 18 }}
                label="Age"
                onChange={(e) => handleTemplateChange(e)}
              >
                {templates.map((t) => {
                  if (t.channel == "mail") {
                    return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
                  }
                })
                }
              </Select>
            </FormControl>
            <FormControlLabel name="Twilio" value={selectedOptions.Twilio} control={<Radio />} checked={selectedOptions.Twilio} onClick={handleOptionChange} label="Twilio" />
            <FormControl>
              <InputLabel id="lable3">Twilio Template</InputLabel>
              <Select
                labelId="lable3"
                id="lable3"
                value={template.twilio}
                name="twilio"
                style={{ fontSize: 18 }}
                label="Age"
                onChange={(e) => handleTemplateChange(e)}
              >
                {templates.map((t) => {
                  if (t.channel == "twilio") {
                    return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
                  }
                })
                }
              </Select>
            </FormControl>

            {/* Add similar code for Mail and Twilio */}
          </FormControl>
        </DialogContent>
        </div>
        <DialogActions>
          <Button
            variant="contained"
            onClick={reachOut}
            color="primary"
          >
            Reach out
          </Button>
          <Button
            variant="outlined"
            onClick={closeNudgeModal}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={filesModal} onClose={handleClose} fullWidth >
        <DialogContent>
          {
            files&&Object.keys(files).length?
              <>
                <TabContext value={value}>
                  <AppBar position="static">
                    <Tabs value={value} default="0" onChange={handleChange} aria-label="Dialog Tabs">
                      {Object.keys(files).map((key, index) => {
                        return (
                          <Tab label={key} value={index} />
                        )
                      })}
                    </Tabs>
                  </AppBar>
                  {filesLoading?
                    <div className={Styles.loading}></div>
                  :
                    files ?
                      <>
                        {
                          Object.keys(files).map((key, index) => {
                              return (<>
                                <TabPanel value={index} index={index}>
                                  {
                                    key=="doc"?
                                    <ul className={Styles.filesUl}>
                                    {
                                      files[key].map((value, index) => {
                                        return (
                                          <li className={Styles.filesLi}>
                                          <Button
                                            variant="contained"
                                            style={{ background: "#636cff", color: "white", width:"700px" }}
                                            href={value}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            startIcon={<CloudDownload />}
                                          >
                                            {`file_${index+1}.${fileName(value)}`}
                                          </Button>
                                          </li>
                                        )
                                      })
                                    }
                                  </ul>
                                    :
                                    key=="media"?
                                        <div className={Styles.mediaContainer}>
                                          {files[key].map((value) => {
                                            return <MediaPreview url={value} />;
                                          })}
                                        </div>
                                    :<>
                                    </>
                                  }
                                 
                                </TabPanel>
                              </>
                              )
                          })
                        }

                      </>
                      : 
                        // <TabContext value={value}>
                        <>
                         <AppBar position="static">
                            <Tabs value={value} onChange={handleChange} aria-label="Dialog Tabs">
                            <Tab label="doc" value={'0'} />
                            <Tab label="doc" value={'1'} />
                            </Tabs>
                          </AppBar>
                          <h3 style={{marginLeft:"24px"}}>No Files Available</h3>
                        </>
                  }
                </TabContext>
              </>
              : <>
                <TabContext value={value}>
                  <AppBar position="static">
                    <Tabs value={value} onChange={handleChange} aria-label="Dialog Tabs">
                    <Tab label="doc" value='0' />
                    <Tab label="media" value='1' />
                    </Tabs>
                  </AppBar>
                  </TabContext>
                  <br></br>
                  <br></br>

               <h3>No Files Available</h3>
              </>
          }

        </DialogContent>
    
      </Dialog>
      <Dialog open={scriptModal} onClose={transScriptModal} fullWidth>
        <DialogTitle style={{
          background: "#3f51b5",
          color: "white",
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}>
          <p style={{ fontSize: "24px" }}>Trans Script</p>
        </DialogTitle>
        {!scriptLoading ?
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <DialogContent style={{ padding: '16px', backgroundColor: '#f0f0f0', border: '1px solid #ccc', textAlign: 'center' }}>
              <div>
                <p style={{ fontSize: '18px', fontWeight: 'bold', color: '#333' }}>{script}</p>
              </div>
            </DialogContent>
          </div>
          :  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight:"130px", minWidth:"130px", margin:"10px" }}>
            <DialogContent style={{ padding: '26px', textAlign: 'center' }}>
              <div className={Styles.loading}></div>
            </DialogContent>
            </div>
        }
       
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  filesLoading:state.Chat.filesLoading,
  scriptLoading: state.Chat.isloading,
  script: state.Chat.script
});

export default connect(mapStateToProps, {
  updateCandidate,
  showAlert,
  getJobList,
  nudge,
  transcript
})(Profile);
