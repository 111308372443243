/** @format */

import React, { useEffect, useState } from "react";
import Styles from "./ModifyJob.module.scss";
import _ from "lodash";
import {
	FormControl,
	MenuItem,
	Paper,
	Select,
	Table,
	TableContainer,
	TableRow,
	TableCell,
	TableHead,
	TableBody,
	Chip,
	Grid,
	TextField,
	Button,
	FormHelperText,
	Dialog
} from "@material-ui/core";
import {
	getCountries,
	getStates,
	getCities,
	emptyStates,
	emptyCities
} from "../../../actions/location";
import { Edit, Delete } from "@material-ui/icons";
import { connect } from "react-redux";
import { getJobList, modifyJob, deleteJob } from "../../../actions/positions";
import ScreeningQuestions from "../../GlobalComponents/ScreeningQuestions/ScreeningQuestions";

const ModifyJob = props => {
	const {
		getJobList,
		getCountries,
		getStates,
		getCities,
		countries,
		emptyCities,
		emptyStates,
		modifyJob,
		states,
		cities,
		jobList,
		departments,
		postingTitles,
		screeningQuestions,
		deleteJob
	} = props;

	const [screeningQuestionsLocal, setScreeningQuestionsLocal] = useState([]);
	const [changeQuestion, setChangeQuestions] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	useEffect(() => {
		if (screeningQuestions !== null && changeQuestion) {
			const unchecked = screeningQuestions.map(el => {
				return { ...el, checked: false };
			});
			const mergedArray = [...unchecked, ...jobData.screeningQuestion];
			let newArray = [];
			let uniqueObject = {};
			for (let i in mergedArray) {
				let id = mergedArray[i]["_id"];
				uniqueObject[id] = mergedArray[i];
			}
			for (let i in uniqueObject) {
				newArray.push(uniqueObject[i]);
			}
			setScreeningQuestionsLocal(newArray);
			setChangeQuestions(false);
		}
	}, [screeningQuestions]);

	useEffect(() => {
		if (jobList === null) {
			console.log("here");
			getJobList();
		}

		setJobListFiltered(jobList);
	}, [jobList]);
	const handleScreeningQuestions = e => {
		console.log(typeof e.target.value);
		const mutatedScreeningQuestion = screeningQuestionsLocal.map(el => {
			if (el._id === e.target.value) {
				return { ...el, checked: !el.checked };
			}
			return el;
		});
		setScreeningQuestionsLocal(mutatedScreeningQuestion);
	};

	const [showError, setShowError] = useState(false);

	const [jobListFiltered, setJobListFiltered] = useState(null);
	const [formData, setFormData] = useState({
		department: "0",
		country: "0",
		jobType: "0",
		jobOpeningStatus: "0"
	});

	const [flowData, setFlowData] = useState({
		edit: false
	});
	const [deleteId, setDeleteId] = useState("");
	const [jobData, setJobData] = useState({});
	const handleChange = event => {
		setFormData({ ...formData, [event.target.name]: event.target.value });
		const filterObj = {
			...formData,
			[event.target.name]: event.target.value
		};

		let mainFilter = {};
		for (var key in filterObj) {
			if (filterObj[key] !== "0") {
				mainFilter[key] = filterObj[key];
			}
		}

		const filteredList = _.filter(jobList, mainFilter);

		console.log(filteredList);
		setJobListFiltered([...filteredList]);
	};

	const handleEdit = (e, job) => {
		setFlowData({ ...flowData, edit: true });
		setJobData({ ...jobData, ...job, postingTitle: job.postingTitle._id });
		setChangeQuestions(true);
		getStates(job.country);
		getCities(job.state);
	};
	const handleModify = e => {
		setJobData({ ...jobData, [e.target.name]: e.target.value });
	};
	const handleLocation = e => {
		setJobData({
			...jobData,
			location: { ...jobData.location, [e.target.name]: e.target.value }
		});

		if (e.target.name === "country") {
			emptyCities();
			emptyStates();
			getStates(e.target.value);
		}

		if (e.target.name === "state") {
			emptyCities();
			getCities(e.target.value);
		}
	};
	const onBack = () => {
		setFlowData({ ...flowData, edit: false });
		setJobData({});
	};

	const clearFilters = () => {
		setFormData({
			...formData,
			department: "0",
			country: "0",
			jobType: "0",
			jobOpeningStatus: "0"
		});
		setJobListFiltered([...jobList]);
	};
	const handleSubmit = () => {
		let flag;
		const jobDataCopy = Object.keys(jobData);
		jobDataCopy.forEach(key => {
			if (jobData[key] === "") {
				return (flag = true);
			}
		});

		if (flag) {
			return setShowError(true);
		}

		if (!flag) {
			setShowError(false);
			modifyJob(jobData, screeningQuestionsLocal);
			setFlowData({ ...flowData, edit: false });
			setFormData({
				...formData,
				department: "0",
				country: "0",
				jobType: "0",
				jobOpeningStatus: "0"
			});
			emptyCities();
			emptyStates();
		}
	};

	const onConfirmReject = e => {
		setConfirmModal(false);
	};

	const onConfirmRequest = e => {
		setConfirmModal(true);
	};

	const onConfirmAccept = e => {
		deleteJob(jobData._id);
		setConfirmModal(false);
		setFlowData({ ...flowData, edit: false });
	};

	const showDelete = (e, id) => {
		setShowDeleteModal(true);
		setDeleteId(id);
	};
	const onDeleteCancel = () => {
		setShowDeleteModal(false);
		setDeleteId("");
	};
	const onDeleteConfirm = () => {
		setShowDeleteModal(false);
		deleteJob(deleteId);
		setDeleteId("");
	};

	return !flowData.edit ? (
		<div>
			<div className={Styles.filterFields}>
				<div className={Styles.formFiledContainer}>
					<label htmlFor=''>Department</label>
					<FormControl variant='standard' className={Styles.formControl}>
						<Select
							value={formData.department}
							name={"department"}
							onChange={handleChange}
							displayEmpty
							className={Styles.select}
						>
							<MenuItem className={Styles.menuitem} value={"0"}>
								All
							</MenuItem>
							{departments !== null &&
								departments.map((department, index) => {
									return (
										<MenuItem
											key={index}
											className={Styles.menuitem}
											value={department._id}
										>
											{department.departmentName}
										</MenuItem>
									);
								})}
						</Select>
					</FormControl>
				</div>
				<div className={Styles.formFiledContainer}>
					<label htmlFor=''>Location</label>
					<FormControl variant='standard' className={Styles.formControl}>
						<Select
							value={formData.country}
							name={"country"}
							onChange={handleChange}
							displayEmpty
							className={Styles.select}
						>
							<MenuItem className={Styles.menuitem} value={"0"}>
								All
							</MenuItem>
							{countries !== null &&
								countries.map((country, index) => {
									return (
										<MenuItem
											key={index}
											className={Styles.menuitem}
											value={country.country_name}
										>
											{country.country_name}
										</MenuItem>
									);
								})}
							{/* <MenuItem className={Styles.menuitem} value={"test"}>
								Test 1
							</MenuItem>
							<MenuItem className={Styles.menuitem} value={"test2"}>
								Test 2
							</MenuItem>
							<MenuItem className={Styles.menuitem} value={"test3"}>
								Test 3
							</MenuItem> */}
						</Select>
					</FormControl>
				</div>
				<div className={Styles.formFiledContainer}>
					<label htmlFor=''>Job </label>
					<FormControl variant='standard' className={Styles.formControl}>
						<Select
							value={formData.jobType}
							name={"jobType"}
							onChange={handleChange}
							displayEmpty
							className={Styles.select}
						>
							<MenuItem className={Styles.menuitem} value={"0"}>
								All
							</MenuItem>
							<MenuItem className={Styles.menuitem} value={"full-time"}>
								Full Time
							</MenuItem>
							<MenuItem className={Styles.menuitem} value={"contractual"}>
								Contractual
							</MenuItem>
							<MenuItem className={Styles.menuitem} value={"freelancer"}>
								Freelancer
							</MenuItem>
						</Select>
					</FormControl>
				</div>
				<div className={Styles.formFiledContainer}>
					<label htmlFor=''>Status</label>
					<FormControl variant='standard' className={Styles.formControl}>
						<Select
							value={formData.jobOpeningStatus}
							name={"jobOpeningStatus"}
							onChange={handleChange}
							displayEmpty
							className={Styles.select}
						>
							<MenuItem className={Styles.menuitem} value={"0"}>
								All
							</MenuItem>
							<MenuItem className={Styles.menuitem} value={"test"}>
								Test
							</MenuItem>
							<MenuItem className={Styles.menuitem} value={"test2"}>
								Test 2
							</MenuItem>
							<MenuItem className={Styles.menuitem} value={"test3"}>
								Test 3
							</MenuItem>
						</Select>
					</FormControl>
				</div>
			</div>
			{jobList !== null && jobList.length !== 0 ? (
				<div className={Styles.table}>
					<TableContainer
						component={Paper}
						style={{ maxHeight: "600px", marginBottom: "3rem" }}
					>
						<Table stickyHeader>
							<TableHead>
								<TableRow>
									<TableCell className={Styles.tableCell}>
										Technical Posting Title
									</TableCell>
									<TableCell className={Styles.tableCell}>
										{" "}
										Experience
									</TableCell>
									<TableCell className={Styles.tableCell}>Dept.</TableCell>
									<TableCell className={Styles.tableCell}>Location</TableCell>
									<TableCell className={Styles.tableCell}>Company Name</TableCell>
									<TableCell className={Styles.tableCell} align='center'>
										Actions
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{jobListFiltered !== null &&
									jobListFiltered.map((job, index) => {
										return (
											<TableRow key={index}>
												<TableCell className={Styles.tableCellBody}>
													{job.postingTitle.postingTitle}
												</TableCell>
												<TableCell className={Styles.tableCellBody}>
													{job.minWorkExp}-{job.maxWorkExp} Years
												</TableCell>
												<TableCell className={Styles.tableCellBody}>
													{departments.find((d) => d._id === job.department)?.departmentName}
												</TableCell>
												<TableCell className={Styles.tableCellBody}>
													{job.city}
												</TableCell>
												<TableCell className={Styles.tableCellBody}>
													{job.companyName}
												</TableCell>
												<TableCell
													className={`${Styles.tableCellBody} ${Styles.actionsCell}`}
													align='center'
												>
													<Edit
														onClick={e => handleEdit(e, job)}
														className={Styles.icon}
														fontSize='large'
													></Edit>
													<Delete
														fontSize='large'
														onClick={e => showDelete(e, job._id)}
														className={`${Styles.icon} ${Styles.deleteIcon}`}
													></Delete>
												</TableCell>
											</TableRow>
										);
									})}

								{/* <TableRow>
									<TableCell className={Styles.tableCellBody}>
										Sr. Senior Developer
									</TableCell>
									<TableCell className={Styles.tableCellBody}>
										{" "}
										3-5 Years
									</TableCell>
									<TableCell className={Styles.tableCellBody}>-</TableCell>
									<TableCell className={Styles.tableCellBody}>-</TableCell>
									<TableCell className={Styles.tableCellBody}>
										InProgress
									</TableCell>
									<TableCell
										className={`${Styles.tableCellBody} ${Styles.actionsCell}`}
										align='center'
									>
										<Edit className={Styles.icon} fontSize='large'></Edit>
									</TableCell>
								</TableRow> */}
							</TableBody>
						</Table>
					</TableContainer>
					<Button
						onClick={clearFilters}
						className={Styles.clearFilter}
						variant={"outlined"}
					>
						Clear All Filters
					</Button>
				</div>
			) : (
				"Loading..."
			)}
			<Dialog open={showDeleteModal}>
				<div className={Styles.deleteModal}>
					<p>Are you sure you want to delete this Job ?</p>
					<div>
						<Button variant='contained' onClick={onDeleteConfirm}>
							Yes
						</Button>
						<Button variant='outlined' onClick={() => onDeleteCancel()}>
							Cancel
						</Button>
					</div>
				</div>
			</Dialog>
		</div>
	) : (
		<>
			<Grid className={Styles.container} container>
				<Grid item xl={6} lg={6} md={6} xs={6}>
					<div className={Styles.modifyContainer}>
						<Button onClick={onBack} variant={"outlined"}>
							Back
						</Button>

						<div className={Styles.formContainer}>
							<div
								className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
							>
								<label htmlFor=''>Job Opening Status</label>
								<FormControl variant='standard' className={Styles.formControl}>
									<Select
										value={jobData.jobOpeningStatus}
										name={"jobOpeningStatus"}
										onChange={handleModify}
										displayEmpty
										className={Styles.select}
									>
										<MenuItem className={Styles.menuitem} value={"active"}>
											Active
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"inactive"}>
											Inactive
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"on hold"}>
											On Hold
										</MenuItem>
									</Select>
								</FormControl>
							</div>
							<div
								className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
							>
								<label>Posting Title</label>
								<FormControl variant='standard' className={Styles.formControl}>
									<Select
										value={jobData.postingTitle}
										name={"postingTitle"}
										onChange={handleModify}
										displayEmpty
										className={Styles.select}
									>
										{postingTitles.map((title, index) => {
											return (
												<MenuItem
													key={index}
													className={Styles.menuitem}
													value={title._id}
												>
													{title.postingTitle}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
								{/* <TextField
								onChange={handleModify}
								name={"postingTitle"}
								value={jobData.postingTitle}
								className={Styles.textfiled}
								variant={"standard"}
							></TextField> */}
							</div>
							<div className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}>
									<label htmlFor="">Location</label>
									<div className={Styles.fullWidth}>
										<TextField
											onChange={handleModify}
											name={"city"}
											value={jobData.city}
											className={Styles.textfiled}
											variant={"standard"}
										></TextField>
										{showError && jobData.city === "" ? (
											<FormHelperText required={true} className={Styles.error}>
												Please Enter city
											</FormHelperText>
										) : (
											""
										)}
									</div>
								</div>
							<div className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}>
									<label htmlFor="">Company Name</label>
									<div className={Styles.fullWidth}>
										<TextField
											onChange={handleModify}
											name={"companyName"}
											value={jobData.companyName}
											className={Styles.textfiled}
											variant={"standard"}
										></TextField>
										{showError && jobData.companyName === "" ? (
											<FormHelperText required={true} className={Styles.error}>
												Please Enter Company Name
											</FormHelperText>
										) : (
											""
										)}
									</div>
								</div>
							<div
								className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
							>
								<label htmlFor=''>Department</label>
								<FormControl variant='standard' className={Styles.formControl}>
									<Select
										value={jobData.department}
										name={"department"}
										onChange={handleModify}
										displayEmpty
										className={Styles.select}
									>
										{departments.map((department, index) => {
											return (
												<MenuItem
													key={index}
													className={Styles.menuitem}
													value={department._id}
												>
													{department.departmentName}
												</MenuItem>
											);
										})}
									</Select>
								</FormControl>
							</div>
							<div
								className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
							>
								<label htmlFor=''>Assigned Recruiter</label>
								<div className={Styles.fullWidth}>
									<TextField
										onChange={handleModify}
										name={"assignedRecruiter"}
										value={jobData.assignedRecruiter}
										className={Styles.textfiled}
										variant={"standard"}
									></TextField>
									{showError && jobData.assignedRecruiter === "" ? (
										<FormHelperText required={true} className={Styles.error}>
											Please Enter Assigned Recruiter
										</FormHelperText>
									) : (
										""
									)}
								</div>
							</div>
							<div
								className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
							>
								<label htmlFor=''>Assigned Recruiter Email</label>
								<div className={Styles.fullWidth}>
									<TextField
										onChange={handleModify}
										name={"assignedRecruiterEmail"}
										value={jobData.assignedRecruiterEmail}
										className={Styles.textfiled}
										variant={"standard"}
									></TextField>
									{showError && formData.recruiterEmail === "" ? (
										<FormHelperText required={true} className={Styles.error}>
											Please Enter Recruiter Email
										</FormHelperText>
									) : (
										""
									)}
								</div>
							</div>
							<div
								className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
							>
								<label htmlFor=''>Job Type</label>
								<FormControl variant='standard' className={Styles.formControl}>
									<Select
										value={jobData.jobType}
										name={"jobType"}
										onChange={handleModify}
										displayEmpty
										className={Styles.select}
									>
										<MenuItem className={Styles.menuitem} value={"full-time"}>
											Full Time
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"contractual"}>
											Contractual
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"freelancer"}>
											Freelancer
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div className={Styles.buttons}>
							<Button
								style={{ marginTop: "2rem" }}
								variant='contained'
								color='primary'
								onClick={handleSubmit}
							>
								Save
							</Button>
							<Button
								style={{ marginTop: "2rem" }}
								variant='outlined'
								color='primary'
								onClick={onConfirmRequest}
							>
								Delete
							</Button>
						</div>
					</div>
				</Grid>
				<Grid item xl={6} lg={6} md={6} xs={6}>
					<div className={Styles.modifyContainer}>
						<div
							className={`${Styles.formFiledContainer} ${Styles.biggerWidth} `}
						>
							<label className={`${Styles.alignTop}`} htmlFor=''>
								Roles & Responsibilities
							</label>
							<div className={Styles.fullWidth}>
								<TextField
									className={Styles.textfiled}
									value={jobData.responsibilities}
									onChange={handleModify}
									name={"responsibilities"}
									multiline
									rows={9}
									variant={"outlined"}
								></TextField>
								{showError && jobData.responsibilities === "" ? (
									<FormHelperText required={true} className={Styles.error}>
										Please Enter Roles & Responsibilities
									</FormHelperText>
								) : (
									""
								)}
							</div>
						</div>
						<div
							className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
						>
							<label htmlFor=''>Min-Max Work Exp</label>
							<div className={Styles.rangeControl}>
								<FormControl variant='standard' className={Styles.formControl}>
									<Select
										value={jobData.minWorkExp}
										name={"minWorkExp"}
										onChange={handleModify}
										displayEmpty
										className={Styles.select}
									>
										<MenuItem className={Styles.menuitem} value={"0"}>
											{"0 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"1"}>
											{"1 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"2"}>
											{"2 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"3"}>
											{"3 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"5"}>
											{"4 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"5"}>
											{"5 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"6"}>
											{"6 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"7"}>
											{"7 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"8"}>
											{"8 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"9"}>
											{"9 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"10"}>
											{"10 Years"}
										</MenuItem>
									</Select>
								</FormControl>
								<FormControl variant='standard' className={Styles.formControl}>
									<Select
										value={jobData.maxWorkExp}
										name={"maxWorkExp"}
										onChange={handleModify}
										displayEmpty
										className={Styles.select}
									>
										<MenuItem className={Styles.menuitem} value={"0"}>
											{"0 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"1"}>
											{"1 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"2"}>
											{"2 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"3"}>
											{"3 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"5"}>
											{"4 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"5"}>
											{"5 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"6"}>
											{"6 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"7"}>
											{"7 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"8"}>
											{"8 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"9"}>
											{"9 Years"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"10"}>
											{"10 Years"}
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div
							className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
						>
							<label htmlFor=''>Salary Range</label>
							<div className={Styles.rangeControl}>
								<FormControl variant='standard' className={Styles.formControl}>
									<Select
										value={jobData.minSalary}
										name={"minSalary"}
										onChange={handleModify}
										displayEmpty
										className={Styles.select}
									>
										<MenuItem className={Styles.menuitem} value={"1 LPA"}>
											{" 1 LPA"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"1.5 LPA"}>
											{"1.5 LPA"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={" 2 LPA"}>
											{" 2 LPA"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={" 2.5 LPA"}>
											{"2.5 LPA"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"2.5+ LPA"}>
											{"2.5 + LPA"}
										</MenuItem>
									</Select>
								</FormControl>
								<FormControl variant='standard' className={Styles.formControl}>
									<Select
										value={jobData.maxSalary}
										name={"maxSalary"}
										onChange={handleModify}
										displayEmpty
										className={Styles.select}
									>
										<MenuItem className={Styles.menuitem} value={"1 LPA"}>
											{" 1 LPA"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"1.5 LPA"}>
											{"1.5 LPA"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={" 2 LPA"}>
											{" 2 LPA"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={" 2.5 LPA"}>
											{"2.5 LPA"}
										</MenuItem>
										<MenuItem className={Styles.menuitem} value={"2.5+ LPA"}>
											{"2.5 + LPA"}
										</MenuItem>
									</Select>
								</FormControl>
							</div>
						</div>
						<div
							className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
						>
							<label htmlFor=''>Panel</label>
							<FormControl variant='standard' className={Styles.formControl}>
								<Select
									value={jobData.panel}
									name={"panel"}
									onChange={handleModify}
									displayEmpty
									className={Styles.select}
								>
									<MenuItem className={Styles.menuitem} value={"Panel 1"}>
										{"Panel 1"}
									</MenuItem>
									<MenuItem className={Styles.menuitem} value={"Panel 2"}>
										{"Panel 2"}
									</MenuItem>
									<MenuItem className={Styles.menuitem} value={"Panel 3"}>
										{"Panel 3"}
									</MenuItem>
								</Select>
								{showError && jobData.panel === "" ? (
									<FormHelperText required={true} className={Styles.error}>
										Please Select panel
									</FormHelperText>
								) : (
									""
								)}
							</FormControl>
						</div>
						<div
							className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
						>
							<label htmlFor=''>Hiring Manager</label>
							<div className={Styles.fullWidth}>
								<TextField
									onChange={handleModify}
									name={"hiringManager"}
									value={jobData.hiringManager}
									className={Styles.textfiled}
									variant={"standard"}
								></TextField>
								{showError && jobData.hiringManager === "" ? (
									<FormHelperText required={true} className={Styles.error}>
										Please Enter Hiring Manger
									</FormHelperText>
								) : (
									""
								)}
							</div>
						</div>
						<div
							className={`${Styles.formFiledContainer} ${Styles.biggerWidth}`}
						>
							<label htmlFor=''>Hiring Manager Email</label>
							<div className={Styles.fullWidth}>
								<TextField
									onChange={handleModify}
									name={"hiringManagerEmail"}
									value={jobData.hiringManagerEmail}
									className={Styles.textfiled}
									variant={"standard"}
								></TextField>
								{showError && formData.hiringManagerEmail === "" ? (
									<FormHelperText required={true} className={Styles.error}>
										Please Enter Hiring Manger
									</FormHelperText>
								) : (
									""
								)}
							</div>
						</div>
					</div>
				</Grid>
			</Grid>
			<div className={Styles.screeningContainer}>
				<div className={Styles.screeningHeader}>
					<h2>Screening Questions</h2>
					<Button variant='contained'>Edit</Button>
					<Button variant='contained'>Delete</Button>
				</div>
				<ScreeningQuestions
					handleScreeningQuestions={handleScreeningQuestions}
					screeningQuestionsLocal={screeningQuestionsLocal}
					department={jobData.department}
				/>
			</div>
			<Dialog open={confirmModal} onClose={onConfirmReject}>
				<div className={Styles.deleteModal}>
					<p>Are you sure you want to delete this Job ?</p>
					<div>
						<Button variant='contained' onClick={onConfirmAccept}>
							Yes
						</Button>
						<Button variant='outlined' onClick={onConfirmReject}>
							Cancel
						</Button>
					</div>
				</div>
			</Dialog>
		</>
	);
};

const mapStateToProps = state => ({
	jobList: state.Positions.jobList,
	countries: state.Location.countries,
	states: state.Location.states,
	cities: state.Location.cities,
	departments: state.Positions.departmentList,
	postingTitles: state.Positions.postingTitles,
	screeningQuestions: state.Positions.screeningQuestion
});

export default connect(mapStateToProps, {
	getJobList,
	getCountries,
	getStates,
	getCities,
	emptyStates,
	emptyCities,
	modifyJob,
	deleteJob
})(ModifyJob);
