/** @format */

import Axios from "axios";
import { AuthAxios } from "../utils/api";
import { showAlert } from "./alert";
import {
  ADD_CANDIDATE,
  REMOVE_CANDIDATE,
  ADD_CANDIDATE_BULK,
  SET_CANDIDATES,
  RESET_CANDIDATES,
  REMOVE_ALL_CANDIDATES,
  UPDATE_CANDIDATE,
  SET_LOADING,
  SET_ALL_CANDIDATES,
  REMOVE_UPDATED,
  UPDATE_CANDIDATE_ACTION,
  SET_UNASSIGNED_LENGTH,
  UPDATE_CANDIDATE_DETAIL,
  DELETE_CANDIDATE,
  ASSIGN_JOBS,
  CHANGE_POSITION_COUNT,
  SCHEDULED,
  SCHEDULED_LOADING,
  SCHEDULE_MODAL,
  SCHEDULED_UNSET_LOADING,
  CANDIDATES_DETAILS
} from "./constants";

export const addCandidate = (formData) => (dispatch) => {
  dispatch({ type: ADD_CANDIDATE, payload: formData });
};

export const removeCandidate = (index) => (dispatch) => {
  dispatch({ type: REMOVE_CANDIDATE, payload: index });
};

export const addCandidateBulk = (candidates) => (dispatch) => {
  dispatch({ type: ADD_CANDIDATE_BULK, payload: candidates });
};

export const removeAllCandidates = () => (dispatch) => {
  dispatch({ type: REMOVE_ALL_CANDIDATES });
};

export const createCandidate = (formData, globalData) => async (dispatch) => {
  try {
    const requestData = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      postingTitle: globalData.position,
      departmentId: globalData.department,
    };
    const createdCandidate = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates`,
      requestData
    );
    dispatch(showAlert("Candidate Created", "success"));
  } catch (error) {
    console.log(error);
  }
};

export const getCandidatesByVacancy = (id) => async (dispatch) => {
  try {
    const candidates = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/candidates/job/${id}`
    );
    dispatch({
      type: SET_CANDIDATES,
      payload: candidates.data.data.data,
    });
  } catch (error) {}
};

export const getUnassignedCandidates = () => async (dispatch) => {
  try {
    const candidates = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/candidates/unassigned`
    );

    dispatch({
      type: SET_CANDIDATES,
      payload: candidates.data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const updateCandidate = (id, updateData, cb) => async (dispatch) => {
  try {
    const reqData = {
      ...updateData,
      visible: updateData.action === "Schedule" ? true : false,
    };
    const candidate = await AuthAxios.patch(
      `${process.env.REACT_APP_API_URL}/candidates/${id}`,
      reqData
    );

    cb(candidate.data.updatedCandidate);
    dispatch({
      type: UPDATE_CANDIDATE,
      payload: candidate.data.updatedCandidate,
    });

    const key = Object.keys(updateData);
    if (key.length === 1) {
      dispatch(showAlert(`${key[0]} is updated Successfully`, "success"));
    } else {
      dispatch(showAlert("Candidate Updated Successfully", "success"));
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateCandidateDetail = (id, data) => async (dispatch) => {
  try {
    const candidate = await AuthAxios.patch(
      `${process.env.REACT_APP_API_URL}/candidates/${id}`,
      data
    );
    dispatch({
      type: UPDATE_CANDIDATE_DETAIL,
      payload: candidate.data.updatedCandidate,
    });
    dispatch(showAlert(`Candidate Updated Successfully`, "success"));
  } catch (error) {
    console.log(error);
  }
};

export const updateCandidateAction = (id, action) => async (dispatch) => {
  try {
    const reqData = { action, visible: action === "Screen" ? true : false };
    dispatch({ type: SET_LOADING });
    const candidate = await AuthAxios.patch(
      `${process.env.REACT_APP_API_URL}/candidates/${id}`,
      reqData
    );

    dispatch({
      type: UPDATE_CANDIDATE_ACTION,
      payload: candidate.data.updatedCandidate._id,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setLoading = () => (dispatch) => {
  dispatch({ type: SET_LOADING });
};

export const resetCandidates = () => (dispatch) => {
  dispatch({ type: RESET_CANDIDATES });
};

export const addMultipleCandidates = (requestData) => async (dispatch) => {
  try {
    const candidateData = requestData.candidates;
    const newData = await Promise.all(
      candidateData.map(async (el) => {
        const job = await AuthAxios.get(
          `${process.env.REACT_APP_API_URL}/job/${el.jobId}`
        );
        return {
          ...el,
          jobProfileImage: job.data.data.data.jobProfileImage,
          postingTitle: job.data.data.data.postingTitle.postingTitle,
          companyName: job.data.data.data.companyName 
        };
      })
    );
    const candidates = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates/multiple`,
      { ...requestData, candidates: newData }
    );
    dispatch(
      showAlert(
        `${requestData.candidates.length} candidates inserted successfully`,
        "success"
      )
    );
  } catch (error) {
    console.log(error);
  }
};

export const getAllCandidates = () => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const candidates = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/candidates/`
    );

    dispatch({
      type: SET_ALL_CANDIDATES,
      payload: candidates.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getCandidatesByAction = (action) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });

    const candidates = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/candidates/action/${action}`
    );

    dispatch({
      type: SET_ALL_CANDIDATES,
      payload: candidates.data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const getLengthOfUnassignedCandidates = () => async (dispatch) => {
  try {
    const candidates = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/candidates/unassigned`
    );

    dispatch({
      type: SET_UNASSIGNED_LENGTH,
      payload: candidates.data.data.data.length,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteCandidate = (id) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const candidate = await AuthAxios.delete(
      `${process.env.REACT_APP_API_URL}/candidates/${id}`
    );
    dispatch({
      type: DELETE_CANDIDATE,
      payload: id,
    });
    dispatch(showAlert("Candidate Deleted Successfully", "success"));
  } catch (error) {}
};

export const assignJobs = (count, ids) => (dispatch) => {
  dispatch({ type: ASSIGN_JOBS, payload: { count, ids } });
};

export const nudge =  (selectedCandidates, templates, selectOptions, screening=true) =>async(dispatch)=>{
  try{
    dispatch({type:SCHEDULED_LOADING})
    const candidate = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates/nudge`,
      {
        selectedCandidates,
        templates,
        selectOptions,
        screening
      }
    );
    dispatch(showAlert("Nudged successfully", "success"));
    dispatch({type:SCHEDULED})
    return true
  }catch(error){
    dispatch({type:SCHEDULED})

    console.log(error);
  }
}

export const getFilterCandidatesByVacancy = (id, filters) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADING });
    const candidates = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates/${id}/filter`,
      {filters}
    );
    dispatch({
      type: SET_CANDIDATES,
      payload: candidates.data.data.data,
    });
  } catch (error) {}
};

export const createEvent = (payload,action )=> async (dispatch)=>{
  try {
    dispatch({type:SCHEDULED_LOADING})
    const candidates = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates/createEvent`,
      {payload,
        action
      } 
    );
    dispatch(showAlert("Scheduled successfully", "success"));
    dispatch({type:SCHEDULED}) 
   } catch (error) {
    dispatch(showAlert("Something Went Wrong Please Try again", "error"));
    dispatch({type:SCHEDULED_UNSET_LOADING});
   }
}
export const openScheduleModal = () => async (dispatch) => {
  dispatch({ type: SCHEDULE_MODAL });
}

export const closeScheduleModal = ()=> async (dispatch)=>{
  dispatch({type: SCHEDULED})
}

export const candidateDetails = (candidatesIds )=> async (dispatch)=>{
  try {
    const candidates = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates/candidatesDetails`,
      {
        candidatesIds,
      }
    );
    dispatch({type:CANDIDATES_DETAILS, payload:candidates.data.candidatesDetails}) 
   } catch (error) {
   }
}