import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    common: {
      black: "#101828",
      white: "#fff",
    },
    primary: {
      main: "#9E77ED",
      dark: "#6941C6",
      light: "#E9D7FE",
      background: "#F9F5FF",
    },
    success: {
      main: "#17B26A",
      light: "#ABEFC6",
      dark: "#067647",
      background: "#ECFDF3",
    },
    warning: {
      main: "#F79009",
      dark: "#B54708",
      light: "#FEDF89",
      background: "#FFFAEB",
    },
    error: {
      main: "#F04438",
      dark: "#B42318",
      background: "#FEF3F2",
      light: "#FECDCA",
    },
    grey: {
      main: "#667085",
      dark: "#344054",
      light: "#EAECF0",
      background: "#f9fafb",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
  p: {
    default: "24px 24px 36px 36px",
  },
});
