import { Button, Box, Stack, Typography, useTheme, Modal } from "@mui/material";
import { PageContainer } from "../../styled/SharedComponents";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import BucketsTabs from "../Ats/BucketsTabs";
import {
  useAllAllowedActions,
  useDatabaseAction,
} from "../../slices/appConfig";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow:
    "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
  p: "24px",
};
/**
 * Database component
 *
 * @returns JSX for the Database page
 */
export default () => {
  const databaseAllowedActions = useDatabaseAction();
  console.log(databaseAllowedActions);
  return (
    <PageContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        height="100%"
        width="100%"
      >
        <Box>
          <Typography variant="h3" fontWeight={600}>
            Database
          </Typography>
          <Typography variant="h5" paddingTop={"8px"}>
            View the full list of resume repository on our platform
          </Typography>
        </Box>
        <Box>
          <Stack direction={"row"} spacing={2}>
            <Button
              sx={{
                width: "8vw !important",
                padding: "0 16px",
                textDecoration: "none",
                boxShadow: "none",
              }}
              color="primary"
              variant="outlined"
              startIcon={<CloudDownloadIcon />}
            >
              Export
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "24px",
        }}
      >
        <BucketsTabs
          allowedActions={databaseAllowedActions}
          showAllApplicants={false}
        />
      </Box>
    </PageContainer>
  );
};
