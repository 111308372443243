/** @format */

import {
	ADD_CANDIDATE,
	ADD_CANDIDATE_BULK,
	REMOVE_ALL_CANDIDATES,
	REMOVE_CANDIDATE,
	RESET_CANDIDATES,
	SET_CANDIDATES,
	UPDATE_CANDIDATE,
	SET_LOADING,
	SET_ALL_CANDIDATES,
	REMOVE_UPDATED,
	UPDATE_CANDIDATE_ACTION,
	SET_UNASSIGNED_LENGTH,
	UPDATE_CANDIDATE_DETAIL,
	DELETE_CANDIDATE,
	ASSIGN_JOBS,
	SCHEDULED,
	SCHEDULE_MODAL,
	SCHEDULED_LOADING,
	SCHEDULED_UNSET_LOADING,
	CANDIDATES_DETAILS
} from "../actions/constants";

const INITIAL_STATE = {
	candidates: [],
	candidateList: [],
	allCandidates: [],
	loading: false,
	unassignedCandidates: null,
	openScheduleModal:false,
	modalLoading:false,
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case ADD_CANDIDATE:
			return {
				...state,
				candidates: [...state.candidates, payload]
			};
		case ADD_CANDIDATE_BULK:
			return {
				...state,
				candidates: [...state.candidates, ...payload]
			};
		case REMOVE_CANDIDATE:
			return {
				...state,
				candidates: state.candidates.filter((el, index) => index !== payload)
			};
		case SET_CANDIDATES:
			return {
				...state,
				candidateList: payload,
				loading: false
			};
		case RESET_CANDIDATES:
			return {
				...state,
				candidateList: [],
				loading: false
			};
		case SET_LOADING:
			return {
				...state,
				loading: true
			};
		case REMOVE_ALL_CANDIDATES:
			return {
				...state,
				candidates: []
			};
		case SET_UNASSIGNED_LENGTH:
			return {
				...state,
				unassignedCandidates: payload
			};
		case UPDATE_CANDIDATE:
			return {
				...state,

				candidateList: state.candidateList.map(el => {
					if (el._id === payload._id) {
						return payload;
					}
					return el;
				}),
				loading: false
			};
		case SET_ALL_CANDIDATES:
			return {
				...state,
				allCandidates: payload,
				loading: false
			};
		case UPDATE_CANDIDATE_ACTION:
			return {
				...state,
				allCandidates: state.allCandidates.filter(el => el._id !== payload),
				loading: false
			};
		case UPDATE_CANDIDATE_DETAIL:
			return {
				...state,
				allCandidates: state.allCandidates.map(el => {
					if (el._id === payload._id) {
						return { ...payload };
					} else {
						return { ...el };
					}
				}),
				loading: false
			};
		case DELETE_CANDIDATE:
			return {
				...state,
				allCandidates: state.allCandidates.filter(el => {
					if (el._id !== payload) {
						return { ...el };
					}
				}),
				loading: false
			};
		case ASSIGN_JOBS:
			return {
				...state,
				unassignedCandidates: state.unassignedCandidates - payload.count,
				candidateList: state.candidateList.filter(
					candidate => !payload.ids.includes(candidate._id)
				),
				loading: false
			};
		case SCHEDULED:
			return {
				...state,
				modalLoading:false,
				openScheduleModal:false
			}
		case SCHEDULE_MODAL:
			return {
				...state,
				openScheduleModal: true
			}
		case SCHEDULED_LOADING:
			return {
				...state,
				modalLoading:true,
			}
		case SCHEDULED_UNSET_LOADING:
			return {
				...state,
				modalLoading:false,
			}
		case CANDIDATES_DETAILS:
			return {
				...state,
				candidateDetails:payload
			}
		default:
			return { ...state };
	}
}
