/** @format */

import Axios from "axios";
import { SET_CURRENT_WORKSPACE } from "./constants";

/** @format */
export const switchCurrentWorkspace =
  (workspaceId) => async (dispatch, getState) => {
    const state = getState();

    dispatch({
      type: SET_CURRENT_WORKSPACE,
      payload: {
        workspaceId,
      },
    });
  };
