import {
  Avatar,
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";
import WorkIcon from "@mui/icons-material/Work";
import { useCallback, useEffect, useState } from "react";
import { useAllAllowedActions, useGetActionById } from "../../slices/appConfig";
import { useChangeCandidateStageByIdMutation } from "../../slices/candidates";
export default ({ id, action, parsedData }) => {
  const allAllowedActions = useAllAllowedActions();
  const [changeCandidateStageById, { isLoading: isChangingStage }] =
    useChangeCandidateStageByIdMutation();

  const [value, setValue] = useState();
  useEffect(() => {
    if (action) {
      setValue(action);
    }
  }, [action]);
  return (
    <Box
      display={"flex"}
      alignItems={"flex-start"}
      justifyContent={"space-between"}
      columnGap={"16px"}
    >
      <Avatar
        sx={{
          width: "100px",
          height: "100px",
        }}
      />
      {parsedData ? (
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Typography
            fontWeight={600}
            color={"#000"}
            sx={{
              textTransform: "none",
            }}
          >
            {parsedData?.Name.FullName}
          </Typography>

          <Typography
            sx={{
              paddingTop: "8px",
              color: "#667085",
              fontSize: "14px",
              fontWeight: "400",
              width: "100%",
            }}
          >
            <Stack gap={1} direction={"row"}>
              <WorkIcon />
              {parsedData?.JobProfile}
            </Stack>
          </Typography>
          <Typography
            sx={{
              color: "#667085",
              fontSize: "14px",
              fontWeight: "400",
              width: "100%",
            }}
          >
            <Stack gap={1} direction={"row"}>
              <HourglassFullIcon />
              Experience: {parsedData?.WorkedPeriod.TotalExperienceInYear} yrs
            </Stack>
          </Typography>
        </Box>
      ) : (
        <Skeleton variant="rectangular" width={"100%"} height={100} />
      )}

      {action ? (
        <Box minWidth={120}>
          <FormControl fullWidth>
            <InputLabel id="bucket-select">Candidate Bucket</InputLabel>
            <Select
              labelId="bucket-select"
              id="bucket-selection"
              value={action}
              onChange={(e) => {
                setValue(e.target.value);
                changeCandidateStageById({ id, stage: e.target.value });
              }}
            >
              {allAllowedActions.map((action) => {
                return (
                  <MenuItem key={action._id} value={action._id}>
                    {action.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      ) : (
        <Skeleton width={"100%"} height={50} />
      )}
    </Box>
  );
};
