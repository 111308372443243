import { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { showAlert } from "../../actions/alert";
import { getJobList, getDepartments } from "../../actions/positions";
import {
  parseResume,
  setLoading,
  addParsedCandidates,
} from "../../actions/upload";
import {
  Box,
  Button,
  CircularProgress,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { CandidateService } from "../../services/candidates.services";
import { UploadFile } from "@mui/icons-material";
import { PictureAsPdf } from "@material-ui/icons";
import { candidatesApi } from "../../slices/candidates";
import JobSelect from "../GlobalComponents/JobSelect/JobSelect";

/**
 * AddResume component props.
 *
 * @param {Object} props - Component props
 * @param {Function} props.showAlert - Function to show alert
 * @param {Function} props.parseResume - Function to parse resume
 * @param {Function} props.getJobList - Function to get job list
 * @param {Array} props.jobList - Array of jobs
 * @param {Function} props.addParsedCandidates - Function to add parsed candidates
 * @param {Function} props.getDepartments - Function to get departments
 * @param {Array} props.departments - Array of departments
 * @param {Function} props.close - Function to close modal
 */

/**
 * AddResume component.
 *
 * Allows user to upload resumes, select department and position to attach resumes to,
 * and upload resumes to parse candidates.
 *
 * @returns {JSX.Element} - AddResume component JSX
 */
function AddResume(props) {
  const { showAlert, getJobList, getDepartments, close } = props;

  const [files, setFiles] = useState([]);
  const [filterData, setFilterData] = useState({
    position: "",
    department: "",
  });
  const [error,setError] = useState(false)
  //load jobs and departments
  useEffect(() => {
    getJobList();
    getDepartments();
  }, []);
  const handleDropResume = (acceptedFiles) =>
    setFiles([...files, ...acceptedFiles.map((file) => file)]);
  const onReject = (files) => {
    const fileString = files?.map((file) => file.file.name).join();
    showAlert(`${fileString} are not valid`, "error");
  };

  const [isUploading, setIsUploading] = useState(false);

  const dispatch = useDispatch();

  const onUpload = async () => {
    // files
    if(filterData.position==""){
      setError(true)
      return
    }
    if(filterData.position!==""){
      setError(false)
    }
    if(filterData.department==""){
      setError(true)
      return
    }
    if(filterData.department!==""){
      setError(false)
    }
    setIsUploading(true);
    try {
      const resumes = await CandidateService.uploadResumes(files);
      await CandidateService.bulkUploadCandidatesForJob(
        filterData.position,
        resumes
      );
      dispatch(candidatesApi.util.invalidateTags(["Candidate"]));
      close();
    } catch (error) {
      showAlert(error.message, "error");
    } finally {
      setIsUploading(false);
    }
  };
  return (
    <>
      <Typography variant="h5" fontWeight={600}>
        Add Resume
      </Typography>
      <Typography variant="p">
        Upload and attach files to this project.
      </Typography>
      {error?<>
        <Typography variant="body1" style={{ color: 'red' }}>
  Please select department
</Typography>
      </>:<></>}
      <Dropzone
        onDrop={handleDropResume}
        accept=".pdf,.docx,.doc"
        onDropRejected={onReject}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps({ className: `dropzone` })}>
            <Box
              sx={{
                border: "1px solid #eaecf0",
                borderRadius: "8px",
                padding: "10px",
                fontWeight: "600",
              }}
            >
              <input {...getInputProps()} />
              <UploadFile />
            </Box>
            <h4>Drop Resumes</h4>
            <p>
              Drag & drop files, or click to select
              <br />
              Allowed file types are .pdf & .docx
            </p>
          </div>
        )}
      </Dropzone>
      <Box
        sx={{
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <JobSelect value={filterData} onChange={setFilterData} fullWidth />
      </Box>
      <Box
        sx={{
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {files.length !== 0 ? (
          files?.map((file, index) => {
            return (
              <>
                <Box
                  sx={{
                    width: "100%!important",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                  }}
                  key={index}
                >
                  <Stack spacing={2} direction={"row"}>
                    <PictureAsPdf />
                    <Typography
                      sx={{
                        maxWidth: "70%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {file.name}
                    </Typography>
                    <Typography fontWeight={600}>
                      {Math.round(file.size / 1024)} KB
                    </Typography>
                  </Stack>
                </Box>
              </>
            );
          })
        ) : (
          <h4>Please Upload Resumes</h4>
        )}
      </Box>
      <Box
        sx={{
          marginTop: "24px",
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Button
          sx={{
            width: "100%!important",
            textTransform: "none",
          }}
          variant="contained"
          onClick={onUpload}
          disabled={isUploading}
        >
          {isUploading && <CircularProgress size={24} />}
          <Typography variant="h5">Upload Resume</Typography>
        </Button>
        <Button
          sx={{
            width: "100%!important",
            textTransform: "none",
          }}
          variant="outlined"
          // onClick={cancelUpload}
          disabled={isUploading}
        >
          <Typography variant="h5">Cancel</Typography>
        </Button>
      </Box>
    </>
  );
}
const mapStateToProps = (state) => ({
  jobList: state.Positions.jobList,
  departments: state.Positions.departmentList,
});

export default connect(mapStateToProps, {
  showAlert,
  parseResume,
  setLoading,
  getJobList,
  addParsedCandidates,
  getDepartments,
})(AddResume);
