import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { WorkspaceService } from "../../../services/workspaces";
import {
  Alert,
  Box,
  Button,
  Chip,
  Container,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import Styles from "./manage.module.scss";
import {
  ContaierHorizontal,
  ContainerVertical,
} from "../../../styled/SharedComponents";
import { Delete } from "@material-ui/icons";
export default function ManageWorkspaces() {
  const [user] = useAuthState(auth);
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loadMembers = () => {
    WorkspaceService.getCurrentWorkspaceMembers()
      .then((members) => {
        setMembers(members);
        setError(null);
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    loadMembers();
  }, []);

  const handleInvite = (e) => {
    e.preventDefault();
    const email = e.target[0].value;
    WorkspaceService.inviteUser(email)
      .then(() => {
        loadMembers();
      })
      .catch(setError);
  };

  const handleRevoke = (id) => {
    WorkspaceService.revokeUser(id)
      .then(() => {
        loadMembers();
      })
      .catch(setError);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
          paddingLeft: "20%",
          paddingTop: "15vh",
          paddingRight: "5%",
          paddingBottom: "15vh",
        }}
      >
        <ContaierHorizontal
          sx={{
            justifyContent: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "30%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              rowGap: "32px",
              // height: "100%",
            }}
          >
            <Typography variant="h3" fontWeight={600}>
              Invite new members to your workspace
            </Typography>

            <form
              style={{
                width: "100%",
              }}
              onSubmit={handleInvite}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "12px",
                }}
              >
                <TextField
                  sx={{
                    width: "100%!important",
                  }}
                  variant="outlined"
                  type="text"
                  placeholder="Invite e-mail"
                />
                <Button
                  sx={{
                    width: "100%!important",
                    padding: "16px",
                  }}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  <Typography
                    variant="h5"
                    textTransform={"none"}
                    fontWeight={600}
                  >
                    Invite
                  </Typography>
                </Button>
              </Box>
            </form>
          </Box>
          <Divider orientation="vertical" flexItem />
          <Box
            sx={{
              width: "70%",
            }}
          >
            <Typography variant="h3" fontWeight={600}>
              Current members of your workspace
            </Typography>
            <TableContainer
              sx={{
                marginTop: "32px",
                boxShadow: "none",
                border: "1px solid #eaecf0",
              }}
              component={Paper}
            >
              <Table
                sx={{
                  minWidth: 650,
                  boxShadow: "none",
                }}
                aria-label="simple table"
              >
                <TableBody
                  sx={{
                    boxShadow: "none",
                  }}
                >
                  {loading && <div>Loading...</div>}
                  {error && <div>{error.message}</div>}
                  {!loading &&
                    !error &&
                    members.map((row) => (
                      <TableRow
                        key={row.name}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                          boxShadow: "none",
                        }}
                      >
                        <TableCell
                          sx={{
                            fontFamily: "inter",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                          component="th"
                          scope="row"
                        >
                          <Typography fontWeight={600}>{row.email}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "inter",
                            fontSize: "16px",
                          }}
                          component="th"
                          scope="row"
                        >
                          <Typography fontWeight={300}>Total credit: {row.creditCoins}</Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            fontFamily: "inter",
                            fontSize: "16px",
                            fontWeight: "400",
                          }}
                          align="left"
                        >
                          <Chip
                            sx={{
                              fontSize: "12px",
                              fontWeight: 600,
                              textTransform: "none",
                            }}
                            label={row.status}
                            variant="outlined"
                            color={
                              row.status.toLowerCase() === "accepted"
                                ? "success"
                                : "warning"
                            }
                          />
                        </TableCell>
                        <TableCell align="right">
                          <Button
                            variant="contained"
                            disabled={row.email === user?.email}
                            onClick={() => handleRevoke(row.id)}
                            color="error"
                            sx={{
                              fontFamily: "inter",
                              fontSize: "12px",
                              fontWeight: "600",
                              cursor: "pointer",
                              boxShadow: "none",
                              textTransform: "none",
                            }}
                            endIcon={<Delete />}
                          >
                            Remove
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </ContaierHorizontal>
      </Box>
    </>
  );
}
