import React, { useState, useEffect } from "react";
import Styles from "../Dashboard/Candidates/Candidates.module.scss";
import {
    FormControl,
    Select,
    MenuItem,
    easing,
    Dialog,
    Button,
    TextField,
    FormLabel,
    FormControlLabel,
    InputLabel,
    Radio,
    DialogTitle,
    DialogContent,
    DialogActions,
    FormHelperText,
    Divider
} from "@material-ui/core";
import { AuthAxios } from "../../utils/api";
import { connect } from "react-redux";
import {
    nudge,
    closeScheduleModal,
    createEvent,
    candidateDetails
  } from "../../actions/outreach";
import { Close } from "@material-ui/icons";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const ReachOut = (props) => {
    const
        {
            selectedCandidates,
            nudgeModalState,
            setNudgeModalState,
            nudge,
            scheduleLoading,
            closeScheduleModal,
            scheduleModal,
            openScheduleModal,
            setScheduleModal,
            candidateObj,
            createEvent,
            candidateDetails,
            candidatesData
        } =
            props;

        useEffect(()=>{
            candidateDetails(selectedCandidates);
        },[selectedCandidates]);

    const [selectedOptions, setSelectedOptions] = useState({
        WhatsApp: false,
        Mail: false,
        Twilio: false,
    });

    const [template, setTemplate] = useState({
        gupshup: "",
        twilio: "",
        mail: "",
    });

    const [templates, setTemplates] = useState([]);
    const [globalError, setGlobalError] = useState(false);
    const [templateError, setTemplateError] = useState(false);
    
    const [slot, setSlot] = useState({
    });
    const [startDate, setStartDate] = useState({
        key: ''
    });
    const [endDate, setEndDate] = useState({
        key: ''
    });

    const handleStartDateChange = (newValue, id) => {
        setStartDate({...startDate, [id]:newValue.toISOString()});
        };
    const closeNudgeModal = () => {
        setNudgeModalState(false);
    };
    const apiHit = async ()=>{
        const data = await AuthAxios.get(`${process.env.REACT_APP_API_URL}/templates`);
        setTemplates(data.data);
    }
    useEffect( () => {
        apiHit()
    }, []);

    const handleOptionChange = (event) => {
        const optionName = event.target.name;
        if (optionName === 'WhatsApp' && event.target.value==='true') {
          setTemplate((prevSelected)=>({
            ...prevSelected,
            gupshup:""
          }))
        } else if (optionName == 'Mail' && event.target.value==='true') {
          setTemplate((prevSelected)=>({
            ...prevSelected,
            mail:""
          }));
        }
        else if(optionName == 'Twilio' && event.target.value==='true'){
          setTemplate((prevSelected)=>({
            ...prevSelected,
            twilio:""
          }));
        }
        setSelectedOptions((prevSelected) => ({
          ...prevSelected,
          [optionName]:!prevSelected[optionName],
        }));
    };

    const handleModalClose = () => {
        setScheduleModal(false)
        setSlot({});
    };

    const handleTemplateChange = (event) => {
        const optionName = event.target.name;
        const value = event.target.value
        setTemplate((prevSelected) => ({
            ...prevSelected,
            [optionName]: value,
        }));
    };

    const reachOut = () => {
        const isSelectedChannel = Object.keys(selectedOptions).some(key => selectedOptions[key] != false);
        const isTemplateEmpty = Object.values(template).every((value) => value === '');
        if (!isSelectedChannel) {
            setGlobalError(true);
        } else if (isTemplateEmpty) {
            setTemplateError(true);
        }
        else {
            let screening = true;
            nudge(selectedCandidates, template, selectedOptions, screening);
            closeNudgeModal()
        }
    };

    const handleSlotChange = (e, id) => {
        const selectedSlot = e.target.value;
        setSlot({ ...slot, [id]: selectedSlot });
        // Calculate the end date based on the selected slot and start date
        if (startDate[id]) {
            const startDateDate = new Date(startDate[id]);
            const startTimestamp = startDateDate.getTime();
            console.log(startTimestamp, 'startTimestamp')
            let endTimestamp = startTimestamp;
            if (selectedSlot === '30min') {
                endTimestamp += 30 * 60 * 1000; // 30 minutes in milliseconds
            } else if (selectedSlot === '45min') {
                endTimestamp += 45 * 60 * 1000; // 45 minutes in milliseconds
            } else if (selectedSlot === '1hour') {
                endTimestamp += 60 * 60 * 1000; // 1 hour in milliseconds
            }
            const selectedEndDate = new Date(endTimestamp);
            setEndDate({ ...endDate, [id]: selectedEndDate });
        }
    };

    const onScheduleNow = () => {
        const payload = candidatesData.map(e => {
            return {
                id: e._id,
                hmName: e.jobId.hiringManager,
                hmMail: e.jobId.hiringManagerEmail,
                recName: e.jobId.assignedRecruiter,
                recEmail: e.jobId.assignedRecruiterEmail,
                companyName: e.companyName || "neuralday",
                jobName: e.postingTitle,
                candidateName: e.name,
                candidateEmail: e.email,
                startDate: startDate[e._id],
                endDate: endDate[e._id],
            }
        })
        createEvent(payload, "scheduling");
    };

    return (
        <>
            <Dialog
                disableBackdropClick={true}
                open={openScheduleModal}
                onClose={handleModalClose}
                maxWidth="md"
                fullWidth={true}
            >
                <div className={`${Styles.modal} ${scheduleLoading ? Styles.modalContainerLoading : ""}`}>
                    <Close onClick={handleModalClose} className={Styles.closeButton} />
                    <>
                        {scheduleLoading ? (<div className={Styles.loading}></div>) :
                            <>
                                <div>
                                    <p>Total Candidates Selected</p>
                                    <p>{candidatesData?.length}</p>
                                </div>
                                {candidatesData?.map((ele, index) => {
                                    return (
                                        <>
                                            {""}
                                            
                                            <div>
                                                <p>Department</p>
                                                <p>{ele?.departmentName}</p>
                                            </div>
                                            <div>
                                                <p>Position/Job Title</p>
                                                <p>
                                                    {ele?.postingTitle}
                                                </p>
                                            </div>
                                            <div>
                                                <p>Hiring Manager</p>
                                                <TextField
                                                    className={Styles.Control}
                                                    value={ele?.jobId?.hiringManager}
                                                    // onChange={changeCurrentJob}
                                                    name={"hiringManager"}
                                                />
                                            </div>
                                            <div>
                                                <p>Hiring Manager Email</p>
                                                <TextField
                                                    className={Styles.Control}
                                                    value={ele?.jobId?.hiringManagerEmail}
                                                    // onChange={changeCurrentJob}
                                                    name={"hiringManagerEmail"}
                                                />
                                            </div>
                                            <div>
                                                <p>Recruiter</p>
                                                <TextField
                                                    className={Styles.Control}
                                                    value={ele?.jobId?.assignedRecruiter}
                                                    // onChange={changeCurrentJob}
                                                    name={"assignedRecruiter"}
                                                />
                                            </div>
                                            <div>
                                                <p>Recruiter Email</p>
                                                <TextField
                                                    className={Styles.Control}
                                                    value={ele?.jobId?.assignedRecruiterEmail}
                                                    // onChange={changeCurrentJob}
                                                    name={"assignedRecruiterEmail"}
                                                />
                                            </div>
                                            <Divider />
                                            <div>
                                                    
                                                <div>
                                                    <p className={Styles.schedule}>Schedule Date</p>
                                                </div>
                                                <div>
                                                    <div key={index} className={Styles.candidates}>
                                                        <p className={Styles.schedule}>Candidate {ele.name || ""}</p>
                                                        <div className={Styles.datePickerContainer}>
                                                            <div className={Styles.datePickerTitle}>
                                                            </div>
                                                            <div>
                                                                <br />
                                                                <div className={Styles.datePicker}>
                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                        <DateTimePicker
                                                                            label="Start Date"
                                                                            value={startDate}
                                                                            onChange={(e) => handleStartDateChange(e, ele._id)}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                                <br />
                                                                <div className={Styles.slots}>
                                                                    <p>Duration</p>
                                                                    <br />
                                                                    <FormControl variant="standard" >
                                                                        <Select
                                                                            onChange={(e) => handleSlotChange(e, ele._id)}
                                                                            name={"slot"}
                                                                            displayEmpty
                                                                            className={Styles.select}
                                                                            value={slot[ele._id]}
                                                                            disabled={!startDate[ele._id]}
                                                                        >
                                                                            <MenuItem className={Styles.menuitem} value={"30min"}>
                                                                                {"30 min"}
                                                                            </MenuItem>
                                                                            <MenuItem className={Styles.menuitem} value={"45min"}>
                                                                                {"45 min"}
                                                                            </MenuItem>
                                                                            <MenuItem className={Styles.menuitem} value={"1hour"}>
                                                                                {"1 hour"}
                                                                            </MenuItem>
                                                                        </Select>
                                                                    </FormControl>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <Divider style={{border: "1px solid black"}} />

                                        </>
                                    )
                                })
                                }
                                <div className={Styles.buttonContainer}>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        style={{ backgroundColor: '#9e77ed', color: '#ffffff' }}
                                        onClick={() => onScheduleNow()}
                                        disabled={Object.keys(slot).length ? false : true}
                                    >
                                        Schedule Now
                                    </Button>
                                </div>
                            </>
                        }
                    </>
                </div>
            </Dialog>
            <Dialog
                open={nudgeModalState}
                onClose={closeNudgeModal}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle style={{
                    background: "#9e77ed",
                    color: "white",
                    display: "flex",
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                }}>
                    <p style={{ fontSize: "24px" }}>Reach Out</p>
                </DialogTitle>

                {scheduleLoading ?
                    (<div className={`${Styles.modal} ${scheduleLoading ? Styles.modalContainerLoading : ""}`}>
                        <div className={Styles.loading}>
                        </div>
                    </div>) :
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop: "50px", paddingBottom: "50px" }}>
                            <DialogContent>
                                <FormControl style={{ width: 320 }}>
                                    <FormLabel>Outreach Candidate and Screen through AI</FormLabel>
                                    <FormControlLabel
                                        name="WhatsApp"
                                        value={selectedOptions.WhatsApp}
                                        control={<Radio />}
                                        checked={selectedOptions.WhatsApp}
                                        onClick={handleOptionChange}
                                        label="Whatsapp"
                                    />
                                    {globalError ? (
                                        <FormHelperText required={true} className={Styles.error}>
                                            Please Select channel
                                        </FormHelperText>
                                    ) : (
                                        ""
                                    )}
                                    <FormControl>
                                        {templateError ? (
                                            <FormHelperText required={true} className={Styles.error}>
                                                Please Select Template
                                            </FormHelperText>
                                        ) : (
                                            ""
                                        )}
                                        <InputLabel id="demo-simple-select-label">
                                            WhatsApp Template
                                        </InputLabel>
                                        <Select
                                            labelId="lable1"
                                            id="demo-simple-select"
                                            value={template.gupshup}
                                            name="gupshup"
                                            style={{ fontSize: 18 }}
                                            label="Age"
                                            onChange={(e) => handleTemplateChange(e)}
                                        >
                                            {templates.map((t) => {
                                                if (t.channel === 'gupshup') {
                                                    return (
                                                        <MenuItem
                                                            key={t._id}
                                                            style={{ fontSize: 18 }}
                                                            value={t._id}
                                                        >
                                                            {t.name}
                                                        </MenuItem>
                                                    );
                                                }
                                                return null;
                                            })}
                                        </Select>
                                    </FormControl>
                                    <FormControlLabel name="Mail" value={selectedOptions.Mail} control={<Radio />} checked={selectedOptions.Mail} onClick={handleOptionChange} label="Mail" />
                                    <FormControl>
                                        <InputLabel id="demo-simple-select-label">Mail Template</InputLabel>
                                        <Select
                                            labelId="lable2"
                                            id="lable2"
                                            value={template.mail}
                                            name="mail"
                                            style={{ fontSize: 18 }}
                                            label="Age"
                                            onChange={(e) => handleTemplateChange(e)}
                                        >
                                            {templates.map((t) => {
                                                if (t.channel == "mail") {
                                                    return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
                                                }
                                            })
                                            }
                                        </Select>
                                    </FormControl>
                                    <FormControlLabel name="Twilio" value={selectedOptions.Twilio} control={<Radio />} checked={selectedOptions.Twilio} onClick={handleOptionChange} label="Twilio" />
                                    <FormControl>
                                        <InputLabel id="lable3">Twilio Template</InputLabel>
                                        <Select
                                            labelId="lable3"
                                            id="lable3"
                                            value={template.twilio}
                                            name="twilio"
                                            style={{ fontSize: 18 }}
                                            label="Age"
                                            onChange={(e) => handleTemplateChange(e)}
                                        >
                                            {templates.map((t) => {
                                                if (t.channel == "twilio") {
                                                    return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
                                                }
                                            })
                                            }
                                        </Select>
                                    </FormControl>

                                    {/* Add similar code for Mail and Twilio */}
                                </FormControl>
                            </DialogContent>
                        </div>
                        <DialogActions>
                            <Button
                                variant="contained"
                                onClick={reachOut}
                                color="#9e77ed"
                                style={{ backgroundColor: '#9e77ed', color: '#ffffff' }}
                            >
                                Reach out
                            </Button>
                            <Button
                                variant="outlined"
                                onClick={closeNudgeModal}
                                color="secondary"
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </>}

            </Dialog>
        </>
    )
}
const mapStateToProps = (state) => ({
    scheduleModal: state.Outreach.openScheduleModal,
    scheduleLoading: state.Outreach.modalLoading,
    candidateLoading: state.Outreach.loading,
    candidatesData: state.Outreach.candidateDetails
});
export default connect(mapStateToProps, {
    nudge,
    closeScheduleModal,
    createEvent,
    candidateDetails
})(ReachOut);

