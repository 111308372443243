import Axios from "axios";
import { auth } from "../firebaseConfig";
import { AuthAxios } from "../utils/api";

/**
 * @typedef {import('./types').Workspace} Workspace
 * @typedef {import('./types').WorkspaceInput} WorkspaceInput
 * @typedef {import('./types').WorkspaceMember} WorkspaceMember
 */

export class WorkspaceService {
  /**
   * Returns a list of workspaces accessible to the current user.
   * @returns {Promise<Workspace[]>}
   */
  static async getWorkspaces() {
    const res = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/workspace`
    );
    return res.data;
  }

  /**
   * Creates a new workspace.
   * @param {WorkspaceInput} workspace
   * @returns {Promise<Workspace>}
   */
  static async createWorkspace(workspace) {
    const res = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/workspace`,
      workspace
    );
    // Force refresh of token to get new claims
    auth.currentUser.getIdToken(true);
    return res.data;
  }

  /**
   * Invite a user to a workspace.
   * @param {string} email
   */
  static async inviteUser(email) {
    await AuthAxios.post(`${process.env.REACT_APP_API_URL}/workspace/member`, {
      email,
    });
  }

  static async revokeUser(uid) {
    await AuthAxios.delete(
      `${process.env.REACT_APP_API_URL}/workspace/member/${uid}`
    );
  }

  /**
   * Returns a list of members in the current workspace.
   * @returns {Promise<WorkspaceMember[]>}
   * */
  static async getCurrentWorkspaceMembers() {
    const res = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/workspace/member`
    );
    return res.data;
  }
}
