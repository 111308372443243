/** @format */

import { Button, Menu, MenuItem, Paper } from "@material-ui/core";
import { Language, WhatsApp, SmsOutlined, Send, CloudDownload } from "@material-ui/icons";
import React, { useState } from "react";
import { connect } from "react-redux";
import { showAlert } from "../../../../actions/alert";
import { updateCandidate } from "../../../../actions/outreach";
import Styles from "./Message.module.scss";
import ScrollToBottom from 'react-scroll-to-bottom';

const Message = (props) => {
  const {
    singleCandidate,
    setSingleCandidate,
    updateCandidate,
    chat,
    addMessage,
    showAlert,
    chatLoading,
  } = props;

  const { name } = singleCandidate;

  const [anchorEl, setAnchorEl] = useState(null);
  const [secondAnchor, setSecondAnchor] = useState(null);
  const [chatPlatform, setChatPlatform] = useState("Rest");

  const channelIcon = () => {
    if (chatPlatform === "whatsapp") return <WhatsApp />;
    if (chatPlatform === "sms") return <SmsOutlined />;
    return <Language />;
  };

  const [formData, setFormData] = useState({
    sender: "Recruiter",
    text: "",
    channel: chatPlatform,
  });

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChatClick = (e) => {
    setSecondAnchor(e.currentTarget);
  };

  const handleChatClose = () => {
    setSecondAnchor(null);
  };

  const changeChatState = (e, id, chatState) => {
    if (id) {
      updateCandidate(id, { chatState: chatState }, setSingleCandidate);
      handleClose();
    } else {
      showAlert("Select a candidate to update state", "error");
      handleClose();
    }
  };

  const sendMessage = () => {
    if (singleCandidate._id) {
      const newFormData = {
        ...formData,
        channel: chatPlatform,
        to:
          chatPlatform === "whatsapp"
            ? "whatsapp:" + singleCandidate.phone
            : singleCandidate.phone,
      };
      setFormData(newFormData);
      addMessage(singleCandidate._id, newFormData, singleCandidate.uid);
    } else {
      showAlert("Please select a candidate to chat", "error");
    }
    setFormData({ ...formData, text: "" });
  };

	const selectPlatform = (platform) => {
		setChatPlatform(platform);
		setSecondAnchor(null);
	}
	function isValidURL(text) {
		try {
		  const url = new URL(text);
		  return url.protocol === 'http:' || url.protocol === 'https:';
		} catch (error) {
		  return false;
		}
	  }

	  function fileExtenstion (text){
		const url = new URL(text);
		const ext = url.pathname.split('.').pop();
		return ext
	  }
	  const filePath ={	
		"pdf":1,
	  }

	  const handleDownloadClick = (url) => {
		const link = document.createElement('a');
		link.href = url;
		link.target="_blank"
		link.download = `image.${fileExtenstion(url)}`;
		link.click();
	  };

	return (
		<div className={Styles.messageContainer}>
			<div className={Styles.messageBar}>
				<div className={Styles.detail}>
					<div className={Styles.icon}>
						<img src='/defaultimg.png' alt='' />
					</div>
					<div>
						<p className={Styles.chatTitle}>{name}</p>
						<p>
							Last Seen -{" "}
							{singleCandidate.date && singleCandidate.time
								? ` ${singleCandidate.date}-${singleCandidate.time}`
								: ""}
						</p>
					</div>
				</div>
				<div>
					<div
						aria-controls='simple-menu'
						aria-haspopup='true'
						onClick={handleClick}
						className={`${Styles.menuButton}  ${singleCandidate.chatState === "active"
								? Styles.active
								: singleCandidate.chatState === "inactive"
									? Styles.inactive
									: Styles.complete
							} `}
					></div>
					<Menu
						id='simple-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						<MenuItem
							className={Styles.menuItem}
							onClick={handleClose}
							onClick={e => changeChatState(e, singleCandidate._id, "active")}
						>
							Active
						</MenuItem>
						<MenuItem
							className={Styles.menuItem}
							onClick={handleClose}
							onClick={e => changeChatState(e, singleCandidate._id, "inactive")}
						>
							Inactive
						</MenuItem>
						<MenuItem
							className={Styles.menuItem}
							onClick={handleClose}
							onClick={e =>
								changeChatState(e, singleCandidate._id, "completed")
							}
						>
							Completed
						</MenuItem>
					</Menu>
				</div>
			</div>
			<div
				className={`${Styles.chatBox} ${chatLoading ? Styles.chatBoxLoading : ""
					} `}
			>
				<ScrollToBottom>
					{!chatLoading ? (
						chat === null ? (
							<h5>Please Select A candidate</h5>
						) : chat.length === 0 ? (
							<h5>Please send a message</h5>
						) : (
							<div className={Styles.chatSubBox}>
								{chat !== null &&
									chat !== undefined &&
									chat.length !== 0 &&
									chat.map(chat => {
										return (
											<div
												className={`${chat.sender === "user"
													? Styles.chatContainer
													: Styles.chatContainerBot
													}`}
											>
												<div
													className={`${chat.sender === "user"
														? Styles.iconUser
														: Styles.iconBot
														}`}
												>
													{chat.sender === "user" ? (
														<img src='/defaultimg.png'></img>
													) : chat.sender === "recruiter" ||
														chat.sender === "Recruiter" ? (
														<img src='/recruiter.png' />
													) : (
														<img src='/botimg.png' />
													)}
												</div>
												{
													isValidURL(chat.text) ?
														fileExtenstion(chat.text) =="pdf" ?
															<iframe src={chat.text} style={{ "height": "300px" }}>
															</iframe>
															: fileExtenstion(chat.text) == "mp4" ||fileExtenstion(chat.text) == "webm"?
																<video class="userMsg" width="320" height="240" controls>
																		<source src={chat.text} type='video/mp4' />
																		Your browser does not support the video tag.
																	</video>
																:
																fileExtenstion(chat.text) == "ogg" ? <>
																	<audio controls>
																		<source src={chat.text} type="audio/ogg" />
																	</audio>
																</> : 
																fileExtenstion(chat.text) =="jpg"||
																fileExtenstion(chat.text) =="jpeg"||
																fileExtenstion(chat.text) =="png"?
																<Paper
																style={{
																  backgroundImage: `url(${chat.text})`,
																  backgroundSize: 'cover',
																  width: '300px',
																  height: '200px',
																  cursor: 'pointer',
																}}
																onClick={e=>handleDownloadClick(chat.text)}
															  >
																{/* {showOptions && (
																  <div>
																	<Button onClick={e=>handleDownloadClick(chat.text)}>Download</Button>
																	<Button onClick={e=>handleOpenInNewTabClick(chat.text)}>Open in New Tab</Button>
																  </div>
																)} */}
															  </Paper>
																:
																<>
																<Button
																	variant="contained"
																	style={{ background: "#636cff", color: "white" }}
																	href={chat.text}
																	target="_blank"
																	rel="noopener noreferrer"
																	startIcon={<CloudDownload />}
																>
																	Download File
																</Button>
															</>
																	
														: <p>{chat.text}</p>
												}	
												{/* <p>{chat.text}</p> */}
											</div>
										);
									})}
							</div>
						)
					) : (
						<div className={Styles.loading}></div>
					)}
				</ScrollToBottom>
			</div>
			<div className={Styles.textBar}>
				<div>
					<div
						aria-controls='message-menu'
						aria-haspopup='true'
						onClick={handleChatClick}
						className={Styles.icons}
					>
						{channelIcon()}
					</div>
					<Menu
						id='message-menu'
						anchorEl={secondAnchor}
						keepMounted
						open={Boolean(secondAnchor)}
						onClose={handleChatClose}
					>
						<MenuItem onClick={() => selectPlatform('Rest')}>
							<Language />
							REST
						</MenuItem>
						<MenuItem onClick={() => selectPlatform('whatsapp')}>
							<WhatsApp />
							WhatsApp
						</MenuItem>
						<MenuItem onClick={() => selectPlatform('sms')}>
							<SmsOutlined />
							SMS
						</MenuItem>
					</Menu>
				</div>
				<div className={Styles.inputBar}>
					<input
						type='text'
						placeholder='Type a message here...'
						value={formData.text}
						onChange={e => setFormData({ ...formData, text: e.target.value })}
					/>
				</div>
				<div>
					<div className={Styles.icons} onClick={sendMessage}>
						<Send />
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, { updateCandidate, showAlert })(
  Message
);
