/** @format */

import Axios from "axios";
import {AuthAxios} from "../utils/api";
import { showAlert } from "./alert";
import {
	GET_GENERAL_QUESTIONS,
	GET_DEPARTMENT_BASED_QUESTIONS,
	ADD_DEPARTMENT_QUESTION,
	ADD_GENERAL_QUESTION,
	DELETE_SCREENING_QUESTION,
	EDIT_QUESTION
} from "./constants";

export const getGeneralQuestions = () => async dispatch => {
	try {
		const questions = await AuthAxios.get(
			`${process.env.REACT_APP_API_URL}/screeningQuestion/general`
		);
		dispatch({
			type: GET_GENERAL_QUESTIONS,
			payload: questions.data.data.data
		});
	} catch (error) {}
};

export const createQuestion = (questionBody, dept) => async dispatch => {
	try {
		const question = await AuthAxios.post(
			`${process.env.REACT_APP_API_URL}/screeningQuestion`,
			questionBody
		);

		if (dept === "General") {
			dispatch({
				type: ADD_GENERAL_QUESTION,
				payload: question.data.data.data
			});
		} else {
			dispatch({
				type: ADD_DEPARTMENT_QUESTION,
				payload: question.data.data.data
			});
		}
	} catch (error) {
		console.log(error);
	}
};

export const updateQuestion = (id, questionBody, dept) => async dispatch => {
	try {
		const updateQuestion = await AuthAxios.patch(
			`${process.env.REACT_APP_API_URL}/screeningQuestion/${id}`,
			questionBody
		);

		console.log(updateQuestion.data.data.data);

		dispatch({
			type: EDIT_QUESTION,
			payload: { data: updateQuestion.data.data.data, dept }
		});
	} catch (error) {
		console.log(error);
	}
};

export const getDepartmentWiseQuestion = department => async dispatch => {
	try {
		const questions = await AuthAxios.get(
			`${process.env.REACT_APP_API_URL}/screeningQuestion/department/${department}`
		);

		dispatch({
			type: GET_DEPARTMENT_BASED_QUESTIONS,
			payload: questions.data.data.data
		});
	} catch (error) {
		console.log(error);
	}
};

export const deleteQuestion = (id, department) => async dispatch => {
	try {
		await AuthAxios.delete(
			`${process.env.REACT_APP_API_URL}/screeningQuestion/${id}`
		);

		dispatch({
			type: DELETE_SCREENING_QUESTION,
			payload: { id, department }
		});
	} catch (error) {}
};

export const getScreeningQuestions = async () => {
	try {
		const questions = await AuthAxios.get(
			`${process.env.REACT_APP_API_URL}/screeningQuestion/general`
		);
		return questions.data.data.data
	} catch (error) {}
};
