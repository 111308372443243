/** @format */

import { Grid } from "@material-ui/core";
import { SmsOutlined, NotificationsNoneOutlined } from "@material-ui/icons";
import React, { useEffect } from "react";
import Styles from "./Chat.module.scss";
import Message from "./Message/Message";
import Profile from "./Profile/Profile";
const Chat = props => {
	const {
		singleCandidate,
		setSingleCandidate,
		chat,
		addMessage,
		getMessage,
		chatLoading,
		hidden,
		candidateFiles,
		files
	} = props;

	useEffect(() => {
		let interval = setInterval(() => {
			if(singleCandidate._id) getMessage(singleCandidate._id)
		}, 4000);
		return () => {
			clearTimeout(interval);
		};
	},[singleCandidate]);

	return (
		<div className={Styles.chatContainer}>
			<div className={Styles.messageBar}>
				<h2>MESSAGE</h2>
				<div>
					<SmsOutlined />
					<NotificationsNoneOutlined />
					<div className={Styles.icon}>
						<img src='/defaultimg.png' alt='' />
					</div>
				</div>
			</div>
			<Grid container className={Styles.container}>
				<Grid
					item
					xl={`${hidden ? 9 : 8}`}
					lg={`${hidden ? 9 : 8}`}
					md={`${hidden ? 9 : 8}`}
					xs={`${hidden ? 9 : 8}`}
					className={Styles.bar}
				>
					<Message
						singleCandidate={singleCandidate}
						setSingleCandidate={setSingleCandidate}
						chat={chat}
						addMessage={addMessage}
						chatLoading={chatLoading}
					/>
				</Grid>
				<Grid
					item
					xl={`${hidden ? 3 : 4}`}
					lg={`${hidden ? 3 : 4}`}
					md={`${hidden ? 3 : 4}`}
					xs={`${hidden ? 3 : 4}`}
				>
					<Profile
						singleCandidate={singleCandidate}
						setSingleCandidate={setSingleCandidate}
						candidateFiles={candidateFiles}
						files={files}
					/>
				</Grid>
			</Grid>
		</div>
	);
};

export default Chat;
