/** @format */
import { useState } from "react";
import {
  Button,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Dialog,
  IconButton,
} from "@material-ui/core";
import React from "react";
import Logo from "../../assets/logo-white.svg";
import Styles from "./Navbar.module.scss";
import {
  Chat,
  Contacts,
  DesktopMac,
  Ballot,
  Person,
  Help,
  Publish,
  AccessTime,
} from "@material-ui/icons";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { SignOut } from "../../firebaseConfig";
import { Analytics } from "@mui/icons-material";
import CloseIcon from '@material-ui/icons/Close';
import { HowItsWorkVideo } from "../../actions/constants";
const Navbar = (props) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [nestedModalOpen, setNestedModalOpen] = useState(false);

  const [ListItems, setListItems] = useState([
    {
      title: "How it works",
      icon: Ballot,
      link: "/helper",
      active: false,
    },
    {
      title: "Create Job",
      icon: Ballot,
      link: "/positions",
      active: false,
    },
    {
      title: "Screening Questions",
      icon: Help,
      link: "/screening",
      active: false,
    },
    {
      title: "ATS",
      icon: Person,
      link: "/ats",
      active: false,
    },
    {
      title: "Inbox",
      icon: Chat,
      link: "/dashboard",
      active: false,
    },
    {
      title: "Analytics",
      icon: Analytics,
      link: "/analytics",
      active: false,
    },
    {
      title: "Campaign",
      icon: Contacts,
      link: "/outreach",
      active: false,
    },
  ]);

  const showConfirmDialog = () => {
    setModalOpen(true);
  };
  const showNestedDialog = () => {
    setNestedModalOpen(true);
  };

  const onCancel = () => {
    setModalOpen(false);
    setNestedModalOpen(false);
  };
  const onAccept = () => {
    const log = SignOut();
    console.log(log);
  };
  return (
    <div>
      <Drawer app variant="permanent" className={Styles.drawer}>
        {/* <div className={Styles.logoContainer}>
          <img src={Logo} alt="" />
        </div> */}
        <List className={Styles.menu}>
          {ListItems.map((item, i) => {
            return (
              <>
              {item.title=="How it works"?
              <Button className={Styles.button} onClick={showNestedDialog}>
                <NavLink
                className={`${Styles["nav-item"]}`}
                activeClassName={Styles.active}
              >
                <item.icon className={Styles.icon}></item.icon>
                <p>{item.title}</p>
              </NavLink>
                
            </Button>
              :
              <Button className={Styles.button}>
              <NavLink
                to={item.link}
                className={`${Styles["nav-item"]}`}
                activeClassName={Styles.active}
              >
                <item.icon className={Styles.icon}></item.icon>
                <p>{item.title}</p>
              </NavLink>
            </Button>
              }
              </>
              
            );
          })}
        </List>
        <List className={Styles["bottom-nav"]}>
        <Button className={Styles.button}>
            <NavLink
              to="/database"
              className={`${Styles["nav-item"]}`}
              activeClassName={Styles.active}
            >
              <DesktopMac className={Styles.icon}></DesktopMac>
              <p>Database</p>
            </NavLink>
          </Button>
          <Button className={Styles.button}>
            <NavLink
              to="/workspaces/manage"
              className={`${Styles["nav-item"]}`}
              activeClassName={Styles.active}
            >
              <Person className={Styles.icon}></Person>
              <p>Manage Users</p>
            </NavLink>
          </Button>
          {/* <ListItem className={`${Styles["bottom-nav-item"]}`}>
            <Person className={Styles.icon}></Person>
            <p>Recruiter</p>
          </ListItem> */}
          <ListItem className={`${Styles["bottom-nav-item"]}  `}>
            <p onClick={showConfirmDialog}>Logout</p>
          </ListItem>
        </List>
      </Drawer>
      <Dialog open={modalOpen}>
        <div className={Styles.deleteModal}>
          <p>Are you sure you want to logout ?</p>
          <div>
            <Button variant="contained" onClick={onAccept}>
              Yes
            </Button>
            <Button variant="outlined" onClick={onCancel}>
              Cancel
            </Button>
          </div>
        </div>
      </Dialog>
      <Dialog open={nestedModalOpen} onClose={onCancel}>
        <div className={Styles.nestedModal}>
          <div className={Styles.modalHeader}>
            <IconButton className={Styles.closeButton} onClick={onCancel}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={Styles.modalContent}>
            <p>How it works</p>
            <video controls width="100%">
              <source src={HowItsWorkVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(Navbar);
