/** @format */

import React from "react";
import Styles from "./ImportBar.module.scss";
import { Grid, Button } from "@material-ui/core";
import { ArrowForward } from "@material-ui/icons";
import Papa from "papaparse";
import { connect } from "react-redux";
import { addCandidateBulk } from "../../../actions/outreach";
const ImportBar = props => {
	const { addCandidateBulk } = props;
	const onChange = e => {
		const file = e.target.files[0];

		console.log("here");

		Papa.parse(file, {
			complete: result => onComplete(result),
			header: true
		});
	};

	const onComplete = result => {
		const data = result.data;
		addCandidateBulk(data);
	};

	return (
		<Grid item xl={3} lg={3} md={3} xs={3}>
			<div className={Styles.container}>
				<div className={Styles.uploadCard}>
					<p>
						Upload <br /> Contact List
					</p>
					<span>Drag and Drop</span>
					<div>
						<a href="/outreach_sample.csv" download="outreach_template.csv"><span>Download Template</span></a>
					</div>
					<div className={Styles.importBTN}>
						<label htmlFor='list'>IMPORT</label>
						<input type='file' onChange={e => onChange(e)} id='list'></input>
					</div>
				</div>
				<div className={Styles.info}>
					<div>
						<span>OR</span> <ArrowForward className={Styles.icon} />
					</div>
					<span>Add Candidate one by one</span>
				</div>
			</div>
		</Grid>
	);
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { addCandidateBulk })(ImportBar);
