/** @format */

import { REMOVE_ALERT, SHOW_ALERT } from "./constants";

export const showAlert = (message, variant) => dispatch => {
	dispatch({
		type: SHOW_ALERT,
		payload: { message, variant }
	});

	setTimeout(() => {
		dispatch({ type: REMOVE_ALERT });
	}, 3000);
};
