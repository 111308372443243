import { Box, Skeleton, Stack, Typography } from "@mui/material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
export default ({ parsedData }) => {
  return (
    <Box
      sx={{
        width: "28%",
        marginTop: "24px",
        // border: "1px solid black"
      }}
    >
      <Box
        sx={{
          width: "100%",
          padding: "24px 20px",
          borderRadius: "16px",
          border: "1px dashed ",
          borderColor: "#5a27ee",
        }}
      >
        <Typography
          variant="h4"
          color={
            "linear-gradient(to bottom, #5a27ee 0%,#be0efc 53.13%, #bb01bf 100%)"
          }
          sx={{ fontWeight: 700 }}
        >
          <Stack gap={1} direction={"row"}>
            <AutoAwesomeIcon />
            Smart Summary
          </Stack>
        </Typography>

        {parsedData?.ExecutiveSummary ? (
          <Typography
            sx={{
              paddingTop: " 16px",
            }}
          >
            {parsedData.ExecutiveSummary}
          </Typography>
        ) : (
          <Skeleton width={"100%"} height={"400px"} />
        )}
      </Box>
    </Box>
  );
};
