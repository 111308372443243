import { createSlice } from "@reduxjs/toolkit";
import { candidatesApi } from "./candidates";

export const candidateFiltersSlice = createSlice({
  name: "candidateFilters",
  initialState: {
    action: null,
    yearsOfExperience: [],
    matchScore: [],
    companyType: "",
    totalActiveFilters: 0,
    department: "",
    position: "",
  },
  reducers: {
    setAction: (state, action) => {
      state.action = action.payload;
    },
    setYearsOfExperience: (state, action) => {
      state.yearsOfExperience = action.payload;
      state.totalActiveFilters += state.yearsOfExperience.length;
    },
    setMatchScore: (state, action) => {
      state.matchScore = action.payload;
      state.totalActiveFilters += state.matchScore.length;
    },
    setCompanyType: (state, action) => {
      const prevCompanyType = state.companyType;
      state.companyType = action.payload;
      if (state.companyType && !prevCompanyType) state.totalActiveFilters += 1;
      if (!state.companyType && prevCompanyType) state.totalActiveFilters -= 1;
    },
    setDepartment: (state, action) => {
      const prevDepartment = state.department;
      state.department = action.payload;
      if (state.department && !prevDepartment) state.totalActiveFilters += 1;
      if (!state.department && prevDepartment) state.totalActiveFilters -= 1;
    },
    setPosition: (state, action) => {
      const prevPosition = state.position;
      state.position = action.payload;
      if (state.position && !prevPosition) state.totalActiveFilters += 1;
      if (!state.position && prevPosition) state.totalActiveFilters -= 1;
    },
  },
});

export const {
  setAction,
  setYearsOfExperience,
  setMatchScore,
  setCompanyType,
  setDepartment,
  setPosition,
} = candidateFiltersSlice.actions;

export const selectCandidateFilters = (state) => state.candidateFilters;
export const selectFilteredCandidates = (state) => {
  const allCandidates =
    candidatesApi.endpoints.getCandidates.select(undefined)(state)?.data;
  const filters = selectCandidateFilters(state);

  if (!allCandidates) {
    return [];
  }

  let filtered = allCandidates;
  if (filters.action) {
    filtered = allCandidates.filter(
      (candidate) => candidate.action === filters.action
    );
  }
  const { yearsOfExperience, matchScore, companyType } = filters;

  if (yearsOfExperience?.length) {
    filtered = filtered.filter((candidate) => {
      const yoe = candidate?.rawParsedData?.WorkedPeriod?.TotalExperienceInYear;
      return yearsOfExperience.some((range) => {
        const [min, max] = range.split("-");
        if (!max) return yoe >= parseInt(min);
        if (!min) return yoe <= parseInt(max);
        return yoe >= parseInt(min) && yoe <= parseInt(max);
      });
    });
  }

  if (matchScore?.length) {
    filtered = filtered.filter((candidate) => {
      const score = candidate?.matchScore;
      return matchScore.some((range) => {
        const [min, max] = range.split("-");
        if (!max) return score >= parseInt(min);
        if (!min) return score <= parseInt(max);
        return score >= parseInt(min) && score <= parseInt(max);
      });
    });
  }

  if (companyType === "Priority") {
    filtered = filtered.filter((candidate) => {
      return candidate?.rawNewAnalysis?.is_top_company;
    });
  } else if (companyType === "Blacklist") {
    filtered = filtered.filter((candidate) => {
      return candidate?.rawNewAnalysis?.is_company_blacklisted;
    });
  }

  if (filters.department) {
    filtered = filtered.filter((candidate) => {
      return candidate?.jobId?.department === filters.department;
    });
  }

  if (filters.position) {
    filtered = filtered.filter((candidate) => {
      return candidate?.jobId?._id === filters.position;
    });
  }

  return filtered;
};
