import { useEffect, useState } from "react";
import { SignOut, auth } from "../../firebaseConfig";
import { useAuthState, useSignInWithGoogle } from "react-firebase-hooks/auth";
import GoogleButton from 'react-google-button'
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithRedirect,
  sendEmailVerification,
  sendPasswordResetEmail,
} from "firebase/auth";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import {
  Container,
  TextField,
  Typography,
  Box,
  Button,
  useTheme,
  Tab,
  Tabs,
} from "@mui/material";
import { ContainerVertical } from "../../styled/SharedComponents";
import Styles from "./SignIn.module.scss";
import GoogleIcon from "@mui/icons-material/Google";
import CircularProgress from '@mui/material/CircularProgress';
//styles

export default function SignIn(props) {
  // Will try to use previously entered email, defaults to an empty string
  const [email, setEmail] = useState(
    window.localStorage.getItem("emailForSignIn") || ""
  );
  const [signInOrUp, setSignInOrUp] = useState("signin"); // ["signIn", "signUp"]
  const [password, setPassword] = useState("");

  const [loginLoading, setLoginLoading] = useState(false);
  const [errorResponse, setErrorResponse] = useState("");
  const [mailSent, setMailSent] = useState(false);
  const navigate = useNavigate();
  const [inValidError, setInValidError] = useState('');

  // When this component renders
  const [user, loading] = useAuthState(auth);
  useEffect(() => {
    console.log(user);
    if (user) {
      // user is already signed in
      if(!isPublicEmail(user.email)){
        setErrorResponse("");
        navigate("/workspaces");
      }else{
        setErrorResponse("Please enter Valid Work Email");
        SignOut()
      }
    }
  }, [user, navigate]);

  const clearError = () => {
    if (errorResponse != "") {
      setErrorResponse("");
    }
  };

  const isPublicEmail = (email) => {
    // public email domains
    const publicEmailDomains =
      /(gmail\.com|yahoo\.com|outlook\.com|hotmail\.com|yopmail\.com)/i;
    return publicEmailDomains.test(email);
  };
  const updateEmail = (e) => {
    clearError();
    setEmail(e.target.value);
  };

  const updatePassword = (e) => {
    clearError();
    setPassword(e.target.value);
  };

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    if (email === "") {
      setErrorResponse("Please enter email");
      setLoginLoading(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);

      setErrorResponse("");
      setMailSent(true);
    } catch (err) {
      console.log(err);
      switch (err.code) {
        case "auth/user-not-found":
          setErrorResponse("User not found");
          break;
        default:
          setErrorResponse("An unknown error has occured");
      }
    } finally {
      setLoginLoading(false);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    setMailSent(false);
    if (email === "" || password === "") {
      setErrorResponse("Please enter email and password");
      setLoginLoading(false);
      return;
    }
    if (isPublicEmail(email)) {
      setErrorResponse("Please enter Valid Work Email");
      setLoginLoading(false);
      return;
    }
    try {
      if (signInOrUp === "signup") {
        const u = await createUserWithEmailAndPassword(auth, email, password);
        await sendEmailVerification(u.user);
        setMailSent(true);
      } else {
        await signInWithEmailAndPassword(auth, email, password);
      }
      setErrorResponse("");
    } catch (err) {
      switch (err.code) {
        case "auth/email-already-in-use":
          setErrorResponse("Email already in use");
          break;
        case "auth/user-not-found":
          setErrorResponse("User not found");
          break;
        case "auth/invalid-login-credentials":
          setErrorResponse("Invalid Login Credentials")
          break;
        default:
          setErrorResponse("An unknown error has occured");
      }
    } finally {
      setLoginLoading(false);
    }
  };

  const handleTabChange = (value) => {
    setSignInOrUp(value);
  };

  const handleSignInWithGoogle = async (e) => {
    e.preventDefault();
    setLoginLoading(true);
    console.log("signing in with google");
    try {
      const provider = new GoogleAuthProvider();
      await signInWithRedirect(auth, provider);
    } catch (err) {
      console.log(err);
      switch (err.code) {
        default:
          setErrorResponse("An unknown error has occured");
      }
    } finally {
      setLoginLoading(false);
    }
  };

  // jsx
  const theme = useTheme();
  return (
    <>
    <div style={{
      width:"100%",
      backgroundImage:"url(https://frontend-staging-dot-top-zoo-283414.uc.r.appspot.com/static/media/login-grapg.ffac4f58.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize:"100%"
    }}>
      <Container
        sx={{
          height: "100vh",
          padding: 0,
          position: "relative",
          maxWidth: "1365px !important",
          
        }}
      >
        <ContainerVertical>
          <Box
            sx={{
              maxWidth: "1365px !important",
              width:"40%"
            }}
          >
            <Tabs
              value={signInOrUp}
              onChange={(e, value) => handleTabChange(value)}
              variant="fullWidth"
              aria-label="Login or Sign Up"
            >
              <Tab label="Login" value="signin" />
              <Tab label="Sign Up" value="signup" />
            </Tabs>
            <br></br>
            <Typography
              sx={{
                paddingTop: "16px",
              }}
              textAlign={"center"}
              variant="h5"
              color={theme.palette.grey.main}
            >
              {signInOrUp === "signin"
                ? "Welcome back! Please Login to your account."
                : "Welcome to NeuralDay! Sign up for a new account to simplify your hiring process."}
            </Typography>
          </Box>
          <Box
            sx={{
              width: "40%",
            }}
          >
            <TextField
              type="email"
              required
              id="outlined-required"
              variant="outlined"
              placeholder="name@work-email.com"
              value={email}
              onChange={updateEmail}
            />
          </Box>
          <Box
            sx={{
              width: "40%",
            }}
          >
            <TextField
              type="password"
              required
              id="outlined-required"
              label="password"
              variant="outlined"
              value={password}
              onChange={updatePassword}
            />
          </Box>
          <Box
            sx={{
              width: "40%",
            }}
          >
          </Box>
          <Box
            sx={{
                width: "40%",
                display: "flex",
                flexDirection: "column",
                gap: "20px",
            }}
          >
            {!mailSent ? (
              <Button
                sx={{
                  width: "100% !important",
                  padding: "16px",
                  textDecoration: "none",
                }}
                color="primary"
                variant="contained"
                disabled={loginLoading || loading}
                onClick={handleSignIn}
              >
                <Typography width={"100%"} variant="h5">
                  {signInOrUp === "signin" ? "LogIn" : "Sign Up"}
                </Typography>
              </Button>
            ) : (
              <Button
                sx={{
                  width: "100% !important",
                  padding: "16px",
                  textDecoration: "none",
                }}
                color="primary"
                variant="contained"
                href={`mailto:${email}`}
              >
                Open Mail
              </Button>
            )}
            <Button
            variant="contained"
              sx={{
                color:"white",
                width: "100% !important",
                padding: "16px",
                textDecoration: "none",
              }}
              color="primary"
              onClick={handleForgotPassword}
            >
              <Typography width={"100%"} variant="h5">
                Forgot Password?
              </Typography>
            </Button>
          </Box>
          
            {mailSent ? (
              <Box
              sx={{
                width: "40%",
              }}
            >
              <Typography variant="h5" color={theme.palette.success.main}>
                Link has been sent to your mail
              </Typography>
          </Box>

            ) : (
              <></>
            )}

          <Box width={"40%"}>
            <Typography textAlign={"center"} variant="h5">
              OR
            </Typography>
          </Box>

          <Box width={"40%"}>
              <GoogleButton
                style={{ width: "100%" }}
                onClick={(e) => { handleSignInWithGoogle(e) }}
              />
          </Box>
          <Box>
            <Typography variant="h5" color={theme.palette.error.main}>
              {errorResponse}
            </Typography>
          </Box>
        </ContainerVertical>
      </Container>
      </div>
      {loginLoading||loading && (
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: 'rgba(255, 255, 255, 0.8)', // adjust the opacity to your preference
          zIndex: 9999,
        }}
      >
        <CircularProgress />
      </Box>
    )}
    </>
    
  );
}
