import { styled } from "@mui/system";

const ContainerVertical = styled("div")({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  rowGap: "32px",
  height: "100%",
  width: "100%",
});

const ContaierHorizontal = styled("div")({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  columnGap: "32px",
  height: "100%",
  width: "100%",
});

const PageContainer = styled("div")({
  minHeight: "100vh",
  width: "100%",
  padding: `15px 23px 23px 16vw}`,
});
export { ContainerVertical, ContaierHorizontal, PageContainer };
