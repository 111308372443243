//@ts-check
import { initializeApp } from "firebase/app";
import { getAuth, signOut } from "firebase/auth";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBrGLv5fbpnvvMQoTJ33XASNBPbPHxK7n0",
  authDomain: "devneuralday.firebaseapp.com",
  projectId: "devneuralday",
  storageBucket: "devneuralday.appspot.com",
  messagingSenderId: "681596143504",
  appId: "1:681596143504:web:5195f4853133167a905515",
  measurementId: "G-T6LGY5YB5C",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
export const SignOut = () => {
  let response;
  signOut(auth)
    .then(() => {
      response = "SignedOut";
    })
    .catch((error) => {
      response = error;
    });

  return response;
};
const storage = getStorage(app);
export { auth, storage };
