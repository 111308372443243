/** @format */

import React, { useState, useEffect } from "react";
import Styles from "./AddQuestion.module.scss";
import {
	TextField,
	Button,
	FormControl,
	Select,
	MenuItem,
	RadioGroup,
	FormControlLabel,
	Radio
} from "@material-ui/core";
import { cardActionAreaClasses } from "@mui/material";
const AddQuestion = props => {
	const {
		category,
		createQuestion,
		setModalOpen,
		editQuestionData,
		editQuestion,
		updateQuestion,
		screeningQuestionsLocal,
		setAddQuestion,
		addQuestion
	} = props;
	const [updateFormData, setUpdateFormData] = useState(true);
	const [formData, setFormData] = useState({
		type: "Short Text",
		yesorno: "yes",
		mcq: 0
	});
	const [questionBody, setQuestionBody] = useState({
		department: category,
		question: "",
		correctAnswer: "",
		questionType: "Short Text",
		questionCategory: category
	});
	const [mcqOptions, setMcqOptions] = useState(["", "", "", ""]);
	useEffect(() => {
		if (editQuestion && updateFormData) {
			setFormData({
				...formData,
				type: editQuestionData.questionType,
				mcq:
					editQuestionData.questionType === "MCQ"
						? +editQuestionData.correctAnswer
						: formData.mcq,
				yesorno:
					editQuestionData.questionType === "True/False"
						? editQuestionData.correctAnswer
						: formData.yesorno
			});
			setQuestionBody({
				...questionBody,
				question: editQuestionData.question,
				correctAnswer:
					editQuestionData.questionType === "Short Text"
						? editQuestionData.correctAnswer
						: formData.type,
				department: editQuestionData.department,
				questionType: editQuestionData.questionType,
				questionCategory: editQuestionData.questionCategory
			});
			if (editQuestionData.questionType === "MCQ") {
				setMcqOptions(editQuestionData.options);
			}

			setUpdateFormData(false);
		}
	});

	const handleChange = e => {
		setFormData({
			...formData,
			[e.target.name]:
				e.target.name === "mcq" ? +e.target.value : e.target.value
		});
		setQuestionBody({
			...questionBody,
			questionType: e.target.value,
			question: "",
			correctAnswer: ""
		});
		setMcqOptions(["", "", "", ""]);
	};

	const handleInputs = e => {
		setQuestionBody({ ...questionBody, [e.target.name]: e.target.value });
	};

	const onSubmit = e => {
		let questionData = { ...questionBody };
		if (formData.type === "True/False") {
			questionData.correctAnswer = formData.yesorno;
			questionData.options = ["yes", "no"];
		}
		if (formData.type === "MCQ") {
			questionData.correctAnswer = formData.mcq;
			questionData.options = [...mcqOptions];
			questionData.questionType = "MCQ";
		}

		if (!editQuestion) {
			createQuestion(questionData, category);
			setTimeout(()=>{setAddQuestion(!addQuestion)},2000)
		} else {
			questionData = {
				...questionData,
				correctAnswer:
					questionData.questionType === "MCQ"
						? `${questionData.correctAnswer}`
						: questionData.correctAnswer
			};
			updateQuestion(
				editQuestionData._id,
				questionData,
				editQuestionData.department
			);
			setTimeout(()=>{setAddQuestion(!addQuestion)},2000)
		}
		setModalOpen(false);
	};

	const addMcqOption = (e, i) => {
		const newArray = mcqOptions.map((el, index) => {
			if (i === index) {
				return `${e.target.value}`;
			} else {
				return el;
			}
		});
		setMcqOptions(newArray);
	};
	const handleChangeOption = e => {
		setFormData({
			...formData,
			[e.target.name]:
				e.target.name === "mcq" ? +e.target.value : e.target.value
		});
	};

	return (
		<div className={Styles.container}>
			<div className={Styles.formFiledContainer}>
				<label htmlFor=''>Question Type</label>
				<FormControl variant='standard' className={Styles.formControl}>
					<Select
						value={formData.type}
						name={"type"}
						onChange={handleChange}
						displayEmpty
						className={Styles.select}
						disabled={editQuestion}
					>
						<MenuItem className={Styles.menuitem} value={"Short Text"}>
							Text
						</MenuItem>
						<MenuItem className={Styles.menuitem} value={"True/False"}>
							Yes/No
						</MenuItem>
						<MenuItem className={Styles.menuitem} value={"MCQ"}>
							Multiple Choice
						</MenuItem>
						<MenuItem className={Styles.menuitem} value={"Video"}>
							Video Answer
						</MenuItem>
						<MenuItem className={Styles.menuitem} value={"File"}>
							Receive File
						</MenuItem>
					</Select>
				</FormControl>
			</div>

			{formData.type === "Short Text" ? (
				<div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Question</label>
						<TextField
							onChange={handleInputs}
							name={"question"}
							value={questionBody.question}
							className={Styles.textfiled}
							variant={"standard"}
						></TextField>
					</div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Modal Answer</label>
						<TextField
							onChange={handleInputs}
							name={"correctAnswer"}
							multiline
							value={questionBody.correctAnswer}
							className={Styles.textfiled}
							variant={"outlined"}										
						></TextField>
					</div>
				</div>
			) : (
				""
			)}
			{formData.type === "True/False" ? (
				<div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Question</label>
						<TextField
							onChange={handleInputs}
							name={"question"}
							value={questionBody.question}
							className={Styles.textfiled}
							variant={"standard"}
						></TextField>
					</div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Modal Answer</label>
						<RadioGroup
							className={Styles.radioGroup}
							aria-label='gender'
							name='yesorno'
							value={formData.yesorno}
							onChange={handleChangeOption}
						>
							<FormControlLabel
								value='yes'
								control={<Radio color='primary' />}
								label='Yes'
							/>
							<FormControlLabel
								value='no'
								control={<Radio color='primary' />}
								label='No'
							/>
						</RadioGroup>
					</div>
				</div>
			) : (
				""
			)}
			{formData.type === "MCQ" ? (
				<div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Question</label>
						<TextField
							onChange={handleInputs}
							name={"question"}
							value={questionBody.question}
							className={Styles.textfiled}
							variant={"standard"}
						></TextField>
					</div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Modal Answer</label>
						<RadioGroup
							className={Styles.radioGroupGrid}
							aria-label='mcq'
							name='mcq'
							onChange={handleChangeOption}
							value={formData.mcq}
						>
							<div>
								<Radio value={0} color={"primary"} />
								<TextField
									placeholder='Option 1'
									onChange={e => addMcqOption(e, 0)}
									value={mcqOptions[0]}
								></TextField>
							</div>
							<div>
								<Radio value={1} color={"primary"} />
								<TextField
									placeholder='Option 2'
									onChange={e => addMcqOption(e, 1)}
									value={mcqOptions[1]}
								></TextField>
							</div>
							<div>
								<Radio value={2} color={"primary"} />
								<TextField
									placeholder='Option 3'
									onChange={e => addMcqOption(e, 2)}
									value={mcqOptions[2]}
								></TextField>
							</div>
							<div>
								<Radio value={3} color={"primary"} />
								<TextField
									placeholder='Option 4'
									onChange={e => addMcqOption(e, 3)}
									value={mcqOptions[3]}
								></TextField>
							</div>
						</RadioGroup>
					</div>
				</div>
			) : (
				""
			)}
			{formData.type === "Video" ? (
				<div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Question</label>
						<TextField
							onChange={handleInputs}
							name={"question"}
							value={questionBody.question}
							className={Styles.textfiled}
							variant={"standard"}
						></TextField>
					</div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Modal Answer</label>
						<TextField
							onChange={handleInputs}
							name={"correctAnswer"}
							multiline
							value={questionBody.correctAnswer}
							className={Styles.textfiled}
							variant={"outlined"}	
							disabled // set the disabled attribute to disable the field
							InputProps={{
							  style: { color: "grey", cursor: "not-allowed" }, // adjust the style to show the field as grayed out
							}}									
						></TextField>
					</div>
				</div>
			) : (
				""
			)}
				{formData.type === "File" ? (
				<div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Question</label>
						<TextField
							onChange={handleInputs}
							name={"question"}
							value={questionBody.question}
							className={Styles.textfiled}
							variant={"standard"}
						></TextField>
					</div>
					<div className={Styles.formFiledContainer}>
						<label htmlFor=''>Modal Answer</label>
						<TextField
							onChange={handleInputs}
							name={"correctAnswer"}
							multiline
							value={questionBody.correctAnswer}
							className={Styles.textfiled}
							variant={"outlined"}
							disabled // set the disabled attribute to disable the field
							InputProps={{
							  style: { color: "grey", cursor: "not-allowed" }, // adjust the style to show the field as grayed out
							}}										
						></TextField>
					</div>
				</div>
			) : (
				""
			)}
			<Button variant='contained' color='primary' onClick={onSubmit}>
				{editQuestion ? "Update Question" : ` Add question in ${category}`}
			</Button>
		</div>
	);
};

export default AddQuestion;
