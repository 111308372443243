/** @format */

import {AuthAxios} from "../utils/api";
import { GET_CHAT, SEND_CHAT, LOADING_CHAT, REMOVE_CHAT, USER_FILES, SET_LOADING, TRAN_SCRIPT, IS_LOADING, FILES_LOADING } from "./constants";
import { showAlert } from "./alert";

export const addMessage = (id, formData, uid) => async dispatch => {
	try {
		const requestData = {
			...formData,
			uid
		};

		const chats = await AuthAxios.post(
			`${process.env.REACT_APP_API_URL}/conversation/${id}`,
			requestData
		);

		console.log(chats.data);
		dispatch({
			type: SEND_CHAT,
			payload: chats.data
		});
	} catch (error) {
		console.log(error);
	}
};

export const emptyChat = id => async dispatch => {
	dispatch({
		type: LOADING_CHAT
	});
};

export const removeChat = () => dispatch => {
	dispatch({
		type: REMOVE_CHAT
	});
};

export const getMessage = id => async dispatch => {
	console.log("get message");
	try {
		const chats = await AuthAxios.get(
			`${process.env.REACT_APP_API_URL}/conversation/${id}`
		);

		dispatch({
			type: GET_CHAT,
			payload: chats.data
		});
	} catch (error) {
		console.log(error);
	}
};

export const candidateFiles = (id, formData, uid) => async dispatch => {
	try {
		dispatch({type:FILES_LOADING});
		const requestData = {
			...formData,
			uid
		};
		const chats = await AuthAxios.get(
			`${process.env.REACT_APP_API_URL}/conversation/files/${id}`,
		);

		console.log('chat',chats.data);
		dispatch({
			type: USER_FILES,
			payload: chats.data
		});
	} catch (error) {
		dispatch({
			type: USER_FILES,
			payload:{}
		});
		console.log(error);
	}
};

export const transcript = (url) => async (dispatch) => {
	try {
		dispatch({ type: IS_LOADING });
		
		AuthAxios.post(
			`${process.env.REACT_APP_API_URL}/ai/transScript`,
			{
				fileUrl:url
			}
		).then((data)=>{
			dispatch({
				type:TRAN_SCRIPT,
				payload:data.data.data.transcript_text,
			})
		});
		
	} catch (error) {
		console.log(error);
	}
};
