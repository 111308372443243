/** @format */

import { Checkbox } from "@material-ui/core";
import Styles from "./Candidate.module.scss";
import React from "react";
import { CallMissed, CallMissedOutgoing } from "@material-ui/icons";
import { CircularScore } from "../../../CircularScore/CircularScore";
const Candidate = props => {
	const {
		candidate,
		handleCheck,
		setSingleCandidate,
		getMessage,
		emptyChat,
		candidateStatus
	} = props;
	const onClickHandel = candidate => {
		emptyChat();
		getMessage(candidate._id);
		setSingleCandidate(candidate);
	};
	return (
		<div className={Styles.candidateBox}>
			<div>
				<Checkbox
					color='primary'
					className={Styles.checkBox}
					onClick={() => handleCheck(candidate._id, candidate)}
				/>
			</div>
			<div
				className={Styles.candidateDetail}
				onClick={() => onClickHandel(candidate)}
			>
				<div>
					<img className={Styles.userimg} src='/defaultimg.png' />
				</div>

				<div style={{minWidth: "70px"}}>
					<p>{candidate.name}</p>
					<p className={`${Styles.userStatusBanner} ${Styles.yetToRespond}`}>{candidate.chatState?candidate.chatState:""}</p> {/* Add this line */}
				</div>
				<div>
					
                      <CircularScore score={candidate.score} />
					<div className={Styles.statusContainer}>
						<div
							className={`${Styles.status}  ${
								candidate.chatState === "active"
									? Styles.active
									: candidate.chatState === "inactive"
									? Styles.inactive
									: Styles.completed
							}`}
						></div>
						{candidate.chatType === "outbound" ? (
							<CallMissedOutgoing />
						) : (
							<CallMissed />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default Candidate;
