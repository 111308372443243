import {
  Box,
  Chip,
  Typography,
  FormControlLabel,
  RadioGroup,
  Radio,
  Stack,
} from "@mui/material";

/**
 * SingleSelectFilter component allows selecting a single filter value from a predefined list.
 * It takes in the filter heading, list of filter values, callback to update selected value,
 * and an icon component.
 *
 * It displays the heading and icon. Renders a radio group of options to select from.
 * Calls the callback on change to update the selected filter value.
 */
export const SingleSelectFilter = ({
  heading,
  filterValues,
  setFilterValue,
  currentValue,
  IconComponent,
}) => {
  return (
    <Box>
      {/* Header */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} gap={1}>
          <IconComponent />
          <Typography>{heading}</Typography>
        </Stack>
        {/* <Chip variant="outlined" color="primary" label={5} /> */}
      </Box>

      {/* filters */}
      <Box
        sx={{
          width: "100%",
          padding: "4px 12px",
          borderRadius: "8px",
          background: "#f9fafb",
          marginTop: "12px",
        }}
      >
        <RadioGroup
          name={heading}
          onChange={(e) => {
            setFilterValue(e.target.value);
          }}
        >
          {filterValues.map((filterValue, i) => (
            <FormControlLabel
              key={filterValue.value}
              control={<Radio checked={currentValue === filterValue.value} />}
              value={filterValue.value}
              label={filterValue.label}
            />
          ))}
        </RadioGroup>
      </Box>
    </Box>
  );
};
