import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { sendEmailVerification } from "firebase/auth";
import { useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Container,
  Snackbar,
  Typography,
  useTheme,
} from "@mui/material";
import { ContainerVertical } from "../../styled/SharedComponents";
import { useNavigate } from "react-router-dom";
import Header from "../Header/Header";

// VerifyEmail displays a message asking the user to verify their email address.
// It uses Firebase Auth to send a verification email to the current user when the "Send Verification Email" button is clicked.
// While waiting for the user to verify their email, it shows a snackbar notification that the email was sent.
export default function VerifyEmail() {
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  useEffect(()=>{
    if(user.emailVerified){
      navigate("/workspaces");
    }
  })
  const handleSend = () => {
    sendEmailVerification(user).then(() => {
      setSnackbarOpen(true);
    });
  };

  const theme = useTheme();
  return (
    <>
     <Header/>
     <div style={{
      width:"100%",
      backgroundImage:"url(https://frontend-staging-dot-top-zoo-283414.uc.r.appspot.com/static/media/login-grapg.ffac4f58.png)",
      backgroundRepeat: "no-repeat",
      backgroundSize:"100%"
    }}>
   
    <Container
      sx={{
        height: "100vh",
        padding: 0,
        position: "relative",
      }}
    >
      <ContainerVertical>
        <Box
          sx={{
            width: "40%",
          }}
        >
          <Typography
            color={theme.palette.common.black}
            textAlign={"center"}
            variant="h2"
            fontWeight={600}
          >
            Please verify your email
          </Typography>
        </Box>
        <Box>
          <Button
            sx={{
              width: "100% !important",
              padding: "16px",
              textDecoration: "none",
            }}
            color="primary"
            variant="contained"
            onClick={handleSend}
          >
            <Typography width={"100%"} variant="h5">
              Resend verification email
            </Typography>
          </Button>
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={() => setSnackbarOpen(false)}
        >
          <Alert severity="success">Verification email sent</Alert>
        </Snackbar>
      </ContainerVertical>
    </Container>
    </div>
    </>
  );
}
