/** @format */

import Axios from "axios";
import { AuthAxios } from "../utils/api";
import { showAlert } from "./alert";
import {
  SET_UPLOAD_LOADING,
  GET_UPLOADED_CANDIDATES,
  MAKE_VISIBLE,
} from "./constants";
import { v4 as uuidv4 } from "uuid";
export const parseResume =
  (base64, cb, cbData, JDBinary) => async (dispatch) => {
    try {
      const requestData = {
        filedata: base64,
        filename: "resume.pdf",
        userkey: "NJJ595RF",
        version: "8.0.0",
        subuserid: "Sahil Madaan",
      };

      // console.log(base64);

      const parsedResume = await Axios.post(
        "https://rest.rchilli.com/RChilliParser/Rchilli/parseResumeBinary",
        requestData
      );

      if (JDBinary) {
        const matchData = {
          index: {
            indexKey: "NJJ595RF",
          },
          match: {
            resumeContent: base64,
            resumeFileName: "Resume.pdf",
            jdContent: JDBinary,
            jdFileName: "Jd.pdf",
          },
          matchType: "Resume to JD",
          explainScore: true,
          explainScoreType: "json",
        };
        const matchScore = await Axios.post(
          "https://searchengine.rchilli.com/RChilliSearchEngineAPI/RChilli/oneMatch",
          matchData,
          {
            headers: {
              "content-type": "application/json",
            },
          }
        );

        return {
          ...parsedResume.data.ResumeParserData,
          matchScore: matchScore.data.explainScore,
        };
      }
      return parsedResume.data.ResumeParserData;
    } catch (error) {
      console.log(error);
    }
  };

export const setLoading = () => (dispatch) => {
  dispatch({ type: SET_UPLOAD_LOADING });
};

export const getCandidates = () => async (dispatch) => {
  try {
    const candidates = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/candidates/not-visible`
    );
    dispatch({
      type: GET_UPLOADED_CANDIDATES,
      payload: candidates.data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const makeCandidateVisible = (id, data) => async (dispatch) => {
  try {
    const candidate = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates/${id}/screen`
    );

    dispatch({
      type: MAKE_VISIBLE,
      payload: id,
    });

    dispatch(showAlert("Candidate Updated", "success"));
  } catch (error) {
    console.log(error);
  }
};

export const changeState = (id, data) => async (dispatch) => {
  try {
    const candidate = await AuthAxios.patch(
      `${process.env.REACT_APP_API_URL}/candidates/${id}`,
      data
    );

    dispatch({
      type: MAKE_VISIBLE,
      payload: id,
    });
    dispatch(showAlert("Candidate Updated", "success"));
  } catch (error) {}
};

export const addParsedCandidates = (parsedData, jobId) => async (dispatch) => {
  try {
    console.log(jobId);
    const jobData = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/job/${jobId}`
    );
    console.log(jobData);
    const reqData = parsedData.map((data) => {
      return {
        name: data.Name.FullName,
        email: data.Email[0].EmailAddress,
        phone:
          data.PhoneNumber &&
          data.PhoneNumber[0] &&
          data.PhoneNumber[0].FormattedNumber
            ? data.PhoneNumber[0].FormattedNumber.replaceAll(" ", "")
            : "",
        dob: data.DateOfBirth,
        jobId,
        visible: false,
        educations: data.SegregatedQualification,
        experiences: data.SegregatedExperience,
        postingTitle: jobData.data.data.data.postingTitle.postingTitle,
        departmentId: jobData.data.data.data.department,
        skills: data.SkillKeywords,
        matchScore:
          data.matchScore && data.matchScore[0]
            ? data.matchScore[0].explaination
            : "N/A",
        rawParsedData: data,
      };
    });
    console.log("request Data", reqData);
    const candidates = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates/multiple-add`,
      reqData
    );
  } catch (error) {
    console.log(error);
  }
};
