/** @format */

import React, { useState, useEffect } from "react";
import Styles from "./Candidates.module.scss";
import {
  FormControl,
  Select,
  MenuItem,
  easing,
  Dialog,
  Button,
  TextField,
  FormLabel,
  FormControlLabel,
  InputLabel,
  Radio,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormHelperText,
  Divider
} from "@material-ui/core";
import Candidate from "./Candidate/Candidate";
import _ from "lodash";
import { connect } from "react-redux";
import {
  updateCandidate,
  updateCandidateDetail,
  getUnassignedCandidates,
  getLengthOfUnassignedCandidates,
  setLoading,
  assignJobs,
  nudge,
  getFilterCandidatesByVacancy,
  getCandidatesByVacancy,
  createEvent,
  openScheduleModal,
  closeScheduleModal
} from "../../../actions/outreach";
import { setLoadingPositions } from "../../../actions/positions";
import { emptyChat } from "../../../actions/chat";
import { ArrowLeft, ArrowRight, Close, Style } from "@material-ui/icons";
import { AuthAxios } from "../../../utils/api";
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

const Candidates = (props) => {
  const [formData, setFormData] = useState({
    chatType: "",
    chatStatus: "",
    sortBy: "",
  });
  const [slot, setSlot] = useState({
  });

  const [assignModal, setAssignModal] = useState(false);
  const [assignData, setAssignData] = useState({
    position: "",
  });
  const {
    selectedCandidates,
    setSelectedCandidates,
    candidateList,
    setSingleCandidate,
    updateCandidate,
    getMessage,
    emptyChat,
    candidateLoading,
    setHidden,
    hidden,
    currentJob,
    setCurrentJob,
    selectedVacancy,
    jobList,
    updateCandidateDetail,
    getJobList,
    setLoading,
    setLoadingPositions,
    assignJobs,
    nudge,
    getFilterCandidatesByVacancy,
    getCandidatesByVacancy,
    createEvent,
    openScheduleModal,
    scheduleLoading,
    scheduleModal,
    closeScheduleModal
  } = props;
  const [filterAbleCandidateList, setFilterAbleCandidateList] = useState([]);
  const [modalState, setModalState] = useState(false);
  const [nudgeModal, setNudgeModalState] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    WhatsApp: true,
    Mail: false,
    Twilio: false,
  });
  const [template, setTemplate] = useState({
		gupshup:"",
		twilio:"",
		mail:"",
	});
	const [templates, setTemplates] = useState([]);
  const [globalError, setGlobalError] = useState(false);
  const [templateError, setTemplateError] = useState(false);
  const [candidateStatus, setCandidateStatus] = useState();
  const [selectedCandidate, setSelectedCandidate] = useState([]);
  const [startDate, setStartDate] = useState({
    key:''
  });
  const [endDate, setEndDate] = useState({
    key:''
  });
  const [totalCount, setTotalCount] = useState();

  const handleStartDateChange = (newValue, id) => {
    setStartDate({...startDate, [id]:newValue.toISOString()});
  };
  const handleEndDateChange = (newValue, id) => {
    setEndDate({...endDate, [id]:newValue.toISOString()});
  };

  const handleOptionChange = (event) => {
    const optionName = event.target.name;
    if (optionName == 'WhatsApp' && event.target.value==false) {
      setTemplate((prevSelected)=>({
        ...prevSelected,
        gupshup:""
      }))
    } else if (optionName == 'Mail' && event.target.value==false) {
      setTemplate((prevSelected)=>({
        ...prevSelected,
        mail:""
      }));
    }
    else if(optionName == 'Twilio' && event.target.value==false){
      setTemplate((prevSelected)=>({
        ...prevSelected,
        twilio:""
      }));
    }
    setSelectedOptions((prevSelected) => ({
      ...prevSelected,
      [optionName]: !prevSelected[optionName],
    }));
  };
  useEffect(() => {
    if (candidateList !== null) {
      setFilterAbleCandidateList(candidateList);
    }
  }, [candidateList]);
  const handleCheck = (id, candidate) => {
    setTotalCount(totalCount+1)
    if (selectedCandidates.length === 0) {
      setSelectedCandidates([id]);
      setSelectedCandidate([candidate])
    } else if (selectedCandidates.length !== 0) {
      if (selectedCandidates.includes(id)) {
        const selected = selectedCandidates.filter((el) => el !== id);
        const candidates = selectedCandidate.filter((el) => el._id !== id);
        setSelectedCandidates(selected);
        setSelectedCandidate(candidates)
      } else {
        setSelectedCandidates([...selectedCandidates, id]);
        setSelectedCandidate([...selectedCandidate, candidate])

      }
    }
  };
  const onFilter = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const filterObj = {
      ...formData,
      [e.target.name]: e.target.value,
    };
    setCandidateStatus(e.target.value)
    let mainFilter = {};
    for (var key in filterObj) {
      if (filterObj[key] !== "") {
        mainFilter[key] = filterObj[key];
      }
    }
    getFilterCandidatesByVacancy(selectedVacancy._id, filterObj)
  };

  const onShort = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    if (e.target.value === "score d") {
      const sorted = _.orderBy(filterAbleCandidateList, ["score"], ["desc"]);
      setFilterAbleCandidateList(sorted);
    } else if (e.target.value === "score a") {
      const sorted = _.orderBy(filterAbleCandidateList, ["score"], ["asc"]);
      setFilterAbleCandidateList(sorted);
    }
  };

  const updateMultipleAction = (e, actionName) => {
    selectedCandidates.forEach((candidate) => {
      updateCandidate(candidate, { action: actionName }, () => {});
    });
    if (actionName !== "Schedule") {
      getJobList();
    }
  };

  const openModal = () => {
    openScheduleModal()
  };

  const handleModalClose = () => {
    closeScheduleModal()
    setSlot({});
  };

  const changeCurrentJob = (e) => {
    setCurrentJob({ ...currentJob, [e.target.name]: e.target.value });
  };

  const openNudgeModal = () => {
    setNudgeModalState(true);
  };

  const closeNudgeModal = ()=>{
    setNudgeModalState(false);
  };


  useEffect( () => {
		const data =  AuthAxios.get(`${process.env.REACT_APP_API_URL}/templates`).then((data)=>{
      console.log('data:',data,'=============data==========')
      setTemplates(data.data);
    });
	}, []);

  const onScheduleNow = () => {
    const payload = selectedCandidate.map(e=>{
      return {
        id:e._id,
        hmName: currentJob.hiringManager,
        hmMail: currentJob.hiringManagerEmail,
        recName: currentJob.assignedRecruiter,
        recEmail: currentJob.assignedRecruiterEmail,
        companyName: currentJob.companyName||"neuralday",
        jobName: currentJob.postingTitle.postingTitle,
        candidateName: e.name,
        candidateEmail: e.email,
        startDate:startDate[e._id],
        endDate:endDate[e._id],
      }
    })
    createEvent(payload, "scheduling");
  };

  const showAssignModal = () => {
    setAssignModal(true);
  };

  const handleAssignModalClose = () => {
    setAssignModal(false);
    setAssignData({ ...assignData, position: "" });
  };

  const onAssignChange = (e) => {
    setAssignData({ ...assignData, [e.target.name]: e.target.value });
  };

  const onAssign = async (e) => {
    setLoadingPositions();
    setLoading();
    selectedCandidates.forEach((el) => {
      updateCandidateDetail(el, { jobId: assignData.position });
    });

    getJobList();
    assignJobs(selectedCandidates.length, selectedCandidates);
  };

  const handleTemplateChange = (event) => {
    const optionName = event.target.name;
    const value = event.target.value
    setTemplate((prevSelected) => ({
      ...prevSelected,
      [optionName]: value,
    }));
  }
  const reachOut = () => {
		const isSelectedChannel = Object.keys(selectedOptions).some(key=>selectedOptions[key]!=false);
    const isTemplateEmpty = Object.values(template).every((value) => value === '');
		if(!isSelectedChannel){
      setGlobalError(true);
		}else if(isTemplateEmpty){
      setTemplateError(true);
    }
    else{
      let screening = false;
			nudge(selectedCandidates, template, selectedOptions, screening);
      closeNudgeModal()
		}
  }
  const handleSlotChange = (e, id) => {
    const selectedSlot = e.target.value;
    setSlot({ ...slot, [id]: selectedSlot });
    // Calculate the end date based on the selected slot and start date
    if (startDate[id]) {
      const startDateDate = new Date(startDate[id]);
      const startTimestamp = startDateDate.getTime();
      console.log(startTimestamp, 'startTimestamp')
      let endTimestamp = startTimestamp;
      if (selectedSlot === '30min') {
        endTimestamp += 30 * 60 * 1000; // 30 minutes in milliseconds
      } else if (selectedSlot === '45min') {
        endTimestamp += 45 * 60 * 1000; // 45 minutes in milliseconds
      } else if (selectedSlot === '1hour') {
        endTimestamp += 60 * 60 * 1000; // 1 hour in milliseconds
      }
      const selectedEndDate = new Date(endTimestamp);
      setEndDate({ ...endDate, [id]: selectedEndDate });
    }
  };
  console.log(currentJob,scheduleLoading,
    scheduleModal,'======currentJob===========')
  return (
    <div className={Styles.candidatesContainer}>
      <span className={Styles.collapseBar} onClick={() => setHidden(!hidden)}>
        {hidden ? <ArrowRight /> : <ArrowLeft />}
      </span>
      <h2>CANDIDATES</h2>
      <div className={Styles.filterBox}>
        <FormControl variant="standard" className={Styles.formControl}>
          <Select
            name={"chatType"}
            displayEmpty
            className={Styles.select}
            value={formData.chatType}
            onChange={onFilter}
          >
            <MenuItem className={Styles.menuitem} value="">
              Type
            </MenuItem>
            <MenuItem className={Styles.menuitem} value="outbound">
              Outbound
            </MenuItem>
            <MenuItem className={Styles.menuitem} value="inbound">
              Inbound
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" className={Styles.formControl}>
          <Select
            name={"chatStatus"}
            displayEmpty
            className={Styles.select}
            value={formData.chatStatus}
            onChange={onFilter}
          >
            <MenuItem className={Styles.menuitem} value="">
              Status
            </MenuItem>
            <MenuItem className={Styles.menuitem} value='0'>
              No Reply
            </MenuItem>
            <MenuItem className={Styles.menuitem} value="1">
              Ongoing
            </MenuItem>{" "}
            <MenuItem className={Styles.menuitem} value="2">
              Completed
            </MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="standard" className={Styles.formControl}>
          <Select
            name={"sortBy"}
            displayEmpty
            className={Styles.select}
            value={formData.sortBy}
            onChange={onShort}
          >
            <MenuItem className={Styles.menuitem} value="">
              Sort By
            </MenuItem>
            <MenuItem className={Styles.menuitem} value="score a">
              Score Asc
            </MenuItem>
            <MenuItem className={Styles.menuitem} value="score d">
              Score Desc
            </MenuItem>
          </Select>
        </FormControl>
      </div>
      <div
        className={`${
          selectedCandidates.length !== 0
            ? Styles.candidateList
            : Styles.candidateListFull
        } ${candidateLoading ? Styles.loadingList : ""} `}
      >
        {!candidateLoading ? (
          filterAbleCandidateList.length !== 0 &&
          filterAbleCandidateList.map((candidate) => {
            return (
              candidate.visible !== false && (
                <Candidate
                  candidate={candidate}
                  handleCheck={handleCheck}
                  setSingleCandidate={setSingleCandidate}
                  getMessage={getMessage}
                  emptyChat={emptyChat}
                  candidateStatus={candidateStatus}
                />
              )
            );
          })
        ) : (
          <div className={Styles.loading}></div>
        )}
        {!candidateLoading
          ? filterAbleCandidateList.length === 0 && (
              <h6> No Candidate Available </h6>
            )
          : ""}
      </div>
      {selectedCandidates.length !== 0&& (
        <div className={Styles.actionBox}>
        <p onClick={selectedCandidates.length <= 3? openModal : null}>Schedule</p> 
          <p onClick={(e) => updateMultipleAction(e, "Disqualify")}>
            Disqualify
          </p>
          <p onClick={(e) => updateMultipleAction(e, "offer")}>
          Offer
          </p>
          <p onClick={(e) => updateMultipleAction(e, "Future Reference")}>
            Future Reference
          </p>
          <p onClick={(e) => updateMultipleAction(e, "interviewing")}>Interview</p>
          {selectedVacancy.position === "Unassigned" && (
            <p onClick={showAssignModal}>Assign Job</p>
          )}
          <p onClick={openNudgeModal}>
            Nudge
          </p>
        </div>
      )}
        <Dialog
          disableBackdropClick={true}
          open={scheduleModal}
          onClose={handleModalClose}
          maxWidth="md"
          fullWidth={true}
        >
          <div className={`${Styles.modal} ${scheduleLoading?Styles.modalContainerLoading:""}`}>
            <Close onClick={handleModalClose} className={Styles.closeButton} />
            {currentJob !== undefined && (
             <>
              {scheduleLoading ? (<div className={Styles.loading}></div>) :
                <>
                  {" "}
                  <div>
                    <p>Total Candidates Selected</p>
                    <p>{selectedCandidates.length}</p>
                  </div>
                  <div>
                    <p>Department</p>
                    <p>{currentJob.departmentName}</p>
                  </div>
                  <div>
                    <p>Position/Job Title</p>
                    <p>
                      {currentJob.postingTitle &&
                        currentJob.postingTitle.postingTitle}
                    </p>
                  </div>
                  <div>
                    <p>Panel</p>
                    <p>{currentJob.panel}</p>
                  </div>
                  <div>
                    <p>Hiring Manager</p>
                    <TextField
                      className={Styles.Control}
                      value={currentJob.hiringManager}
                      onChange={changeCurrentJob}
                      name={"hiringManager"}
                    />
                  </div>
                  <div>
                    <p>Hiring Manager Email</p>
                    <TextField
                      className={Styles.Control}
                      value={currentJob.hiringManagerEmail}
                      onChange={changeCurrentJob}
                      name={"hiringManagerEmail"}
                    />
                  </div>
                  <div>
                    <p>Recruiter</p>
                    <TextField
                      className={Styles.Control}
                      value={currentJob.assignedRecruiter}
                      onChange={changeCurrentJob}
                      name={"assignedRecruiter"}
                    />
                  </div>
                  <div>
                    <p>Recruiter Email</p>
                    <TextField
                      className={Styles.Control}
                      value={currentJob.assignedRecruiterEmail}
                      onChange={changeCurrentJob}
                      name={"assignedRecruiterEmail"}
                    />
                  </div>
                  <Divider />
                  <div>
                    <div>
                      <p className={Styles.schedule}>Schedule Date</p>
                    </div>
                    <div>
                      {selectedCandidate.map((ele, index) => (
                        <div key={index} className={Styles.candidates}>
                          <p className={Styles.schedule}>Candidate {ele.name || ""}</p>
                          <div className={Styles.datePickerContainer}>
                            <div className={Styles.datePickerTitle}>
                            </div>
                            <div>
                              <br />
                              <div className={Styles.datePicker}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                  <DateTimePicker
                                    label="Start Date"
                                    value={startDate}
                                    onChange={(e) => handleStartDateChange(e, ele._id)}
                                  />
                                </LocalizationProvider>
                              </div>
                              <br />
                              <div className={Styles.slots}>
                                <p>Duration</p>
                                <br />
                                <FormControl variant="standard" >
                                  <Select
                                    onChange={(e) => handleSlotChange(e, ele._id)}
                                    name={"slot"}
                                    displayEmpty
                                    className={Styles.select}
                                    value={slot[ele._id]}
                                    disabled={!startDate[ele._id]}
                                  >
                                    <MenuItem className={Styles.menuitem} value={"30min"}>
                                      {"30 min"}
                                    </MenuItem>
                                    <MenuItem className={Styles.menuitem} value={"45min"}>
                                      {"45 min"}
                                    </MenuItem>
                                    <MenuItem className={Styles.menuitem} value={"1hour"}>
                                      {"1 hour"}
                                    </MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className={Styles.buttonContainer}>
                    <Button
                      variant={"contained"}
                      color={"primary"}
                      onClick={() => onScheduleNow()}
                      disabled={Object.keys(slot).length ? false : true}

                    >
                      Schedule Now
                    </Button>
                  </div>
                </>
              }
             </>
            )}

          </div>
        </Dialog>
      <Dialog
        disableBackdropClick={true}
        open={assignModal}
        onClose={handleAssignModalClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <div className={Styles.modal}>
          <Close
            onClick={handleAssignModalClose}
            className={Styles.closeButton}
          />
          <div className={Styles.SelectionField}>
            <label htmlFor="">Applying Position</label>
            <FormControl variant="standard" className={Styles.formControl}>
              <Select
                value={assignData.position}
                onChange={(e) => onAssignChange(e)}
                name={"position"}
                displayEmpty
                className={Styles.select}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem className={Styles.menuitem} value="" disabled>
                  Select Position Here
                </MenuItem>
                {jobList !== null &&
                  jobList.length !== 0 &&
                  jobList.map((jobList) => {
                    return (
                      <MenuItem className={Styles.menuitem} value={jobList._id}>
                        {jobList.postingTitle.postingTitle}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <Button
              onClick={() => onAssign()}
              variant="contained"
              color="primary"
            >
              Assign
            </Button>
          </div>
        </div>
      </Dialog>
      {/* nudge dialog box */}
      <Dialog
        open={nudgeModal}
        onClose={closeNudgeModal}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle style={{
          background: "#9e77ed",
          color:"white",
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
        }}>
          <p style={{fontSize:"24px"}}>Nudge</p>
        </DialogTitle>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', paddingTop:"50px", paddingBottom:"50px" }}>
        <DialogContent>
          <FormControl style={{ width: 320 }}>
            <FormLabel>Outreach Candidate and Screen through AI</FormLabel>
            <FormControlLabel
              name="WhatsApp"
              value={selectedOptions.WhatsApp}
              control={<Radio />}
              checked={selectedOptions.WhatsApp}
              onClick={handleOptionChange}
              label="Whatsapp"
            />
            	{globalError? (
							<FormHelperText required={true} className={Styles.error}>
								Please Select channel
							</FormHelperText>
						) : (
							""
						)}
            <FormControl>
              {templateError? (
							<FormHelperText required={true} className={Styles.error}>
								Please Select Template
							</FormHelperText>
						) : (
							""
						)}
              <InputLabel id="demo-simple-select-label">
                WhatsApp Template
              </InputLabel>
              <Select
                labelId="lable1"
                id="demo-simple-select"
                value={template.gupshup}
                name="gupshup"
                style={{ fontSize: 18 }}
                label="Age"
                onChange={(e) => handleTemplateChange(e)}
              >
                {templates.map((t) => {
                  if (t.channel === 'gupshup') {
                    return (
                      <MenuItem
                        key={t._id}
                        style={{ fontSize: 18 }}
                        value={t._id}
                      >
                        {t.name}
                      </MenuItem>
                    );
                  }
                  return null;
                })}
              </Select>
            </FormControl>
            <FormControlLabel name="Mail" value={selectedOptions.Mail} control={<Radio />} checked={selectedOptions.Mail} onClick={handleOptionChange} label="Mail" />
            <FormControl>
              <InputLabel id="demo-simple-select-label">Mail Template</InputLabel>
              <Select
                labelId="lable2"
                id="lable2"
                value={template.mail}
                name="mail"
                style={{ fontSize: 18 }}
                label="Age"
                onChange={(e) => handleTemplateChange(e)}
              >
                {templates.map((t) => {
                  if (t.channel == "mail") {
                    return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
                  }
                })
                }
              </Select>
            </FormControl>
            <FormControlLabel name="Twilio" value={selectedOptions.Twilio} control={<Radio />} checked={selectedOptions.Twilio} onClick={handleOptionChange} label="Twilio" />
            <FormControl>
              <InputLabel id="lable3">Twilio Template</InputLabel>
              <Select
                labelId="lable3"
                id="lable3"
                value={template.twilio}
                name="twilio"
                style={{ fontSize: 18 }}
                label="Age"
                onChange={(e) => handleTemplateChange(e)}
              >
                {templates.map((t) => {
                  if (t.channel == "twilio") {
                    return <MenuItem style={{ fontSize: 18 }} value={t._id}>{t.name}</MenuItem>
                  }
                })
                }
              </Select>
            </FormControl>
            {/* Add similar code for Mail and Twilio */}
          </FormControl>
        </DialogContent>
        </div>
        <DialogActions>
          <Button
            variant="contained"
            onClick={reachOut}
            style={{ backgroundColor: '#9e77ed', color: '#ffffff' }}
          >
            Reach out
          </Button>
          <Button
            variant="outlined"
            onClick={closeNudgeModal}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = (state) => ({
  scheduleModal:state.Outreach.openScheduleModal,
  scheduleLoading: state.Outreach.modalLoading,
  candidateLoading: state.Outreach.loading,
});

export default connect(mapStateToProps, {
  updateCandidate,
  emptyChat,
  updateCandidateDetail,
  getUnassignedCandidates,
  getLengthOfUnassignedCandidates,
  setLoading,
  setLoadingPositions,
  assignJobs,
  nudge,
  getFilterCandidatesByVacancy,
  getCandidatesByVacancy,
  createEvent,
  openScheduleModal,
  closeScheduleModal
})(Candidates);
