import React, { useRef, useEffect } from 'react';
import * as echarts from 'echarts';

const EChartComponent = (prop) => {
    const { data } = prop;
  const chartRef = useRef(null);

  useEffect(() => {
    const myChart = echarts.init(chartRef.current);
    const option = {
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'shadow'
        }
      },
      grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
      },
      xAxis: {
        type: 'value',
        boundaryGap: [0, 0.01]
      },
      yAxis: {
        type: 'category',
        data: ['Schedule', 'Interview', 'Offer Stage','Screen', 'Source']
      },
      series: data,
      barWidth:"50%",
      barGap: '20%'
    };

    option && myChart.setOption(option);

    // Clean up
    return () => {
      myChart.dispose();
    };
  });

  return <div ref={chartRef} style={{ width: '100%', height: 400 }} />;
};

export default EChartComponent;
