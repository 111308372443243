/** @format */

import React from "react";
import Styles from "./Job.module.scss";
import {
	setLoading,
	getUnassignedCandidates
} from "../../../../actions/outreach";
import { connect } from "react-redux";

const Job = props => {
	const {
		job,
		changeClicked,
		setLoading,
		getUnassignedCandidates,
		changeJob
	} = props;

	const onClickHandle = (e, id, job, active) => {
		if (!active && !job.unassigned) {
			setLoading();
			changeClicked(e, id, job);
			changeJob(e, job);
		} else if (job.unassigned) {
			setLoading();
			getUnassignedCandidates();
			changeClicked();
			changeJob(e, {});
		}
	};

	return (
		<div
			className={`${Styles.jobItem} ${job.clicked ? Styles.active : ""} `}
			onClick={e => onClickHandle(e, job._id, job, job.clicked)}
		>
			<div className={Styles.appliedNum}>
				{job.candidateCount < 10
					? `0${job.candidateCount}`
					: job.candidateCount}
			</div>
			<div className={Styles.detail}>
				<p>{job.postingTitle.postingTitle}</p>

				{job.minWorkExp !== undefined ? (
					<p>{`(${job.minWorkExp}-${job.maxWorkExp}) yrs`}</p>
				) : null}
				<p>{job.city}</p>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, {
	setLoading,
	getUnassignedCandidates
})(Job);
