/**
 * CandidateDetails component page. Takes id as argument and use getCandidateDetails() to get the data of the candidate.
 */

import { Link, useLocation } from "react-router-dom";
import { useGetCandidateByIdQuery } from "../../slices/candidates";
import { PageContainer } from "../../styled/SharedComponents";
import {
  Box,
  Breadcrumbs,
  Skeleton,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import DetailContent from "./DetailContent";
import { useEffect, useState } from "react";
import { CustomTabPanel } from "../Ats/BucketsTabs";
import HeaderDetail from "./HeaderDetail";
import SideBar from "./SideBar";
import AboutMe from "./AboutMe";
import { Analytics } from "./Analytics";

/**
 * Generates props for accessibility roles for tabs.
 *
 * @param {number} index - The index of the tab.
 * @returns {Object} The props object with id and aria-controls generated from the index.
 */
function allyProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
/**
 * Component to display candidate details page.
 *
 * @param {Object} props - Component props
 * @returns {JSX.Element} - JSX for component
 */
export default () => {
  /**
   * seraching for params in the url to get the id of the candidate. The param is after '/candidate/'
   */
  const params = useLocation().pathname.split("/")[2];
  const [parsedData, setParsedData] = useState();
  const [analytics, setAnalytics] = useState();

  const [currentTab, setCurrentTab] = useState("Overview");

  /**
   * get the data of the candidate using the id.
   * @param {string} params - id of the candidate
   * @returns {Object} - data of the candidate
   */
  const candidiateDetails = useGetCandidateByIdQuery(params);
  useEffect(() => {
    if (candidiateDetails.data?.rawParsedData) {
      setParsedData(candidiateDetails.data.rawParsedData);
    }
    if (candidiateDetails.data?.rawNewAnalysis)
      setAnalytics(candidiateDetails.data?.rawNewAnalysis);
    console.log(candidiateDetails.data?.rawParsedData);
  }, [candidiateDetails]);
  return (
    <PageContainer>
      <Breadcrumbs aria-label="breadcrumb">
        <Link underline="hover" color="inherit" to="/ats">
          ATS
        </Link>
        <Typography color="text.primary">Candidate Details</Typography>
      </Breadcrumbs>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        {/* MAIN COMPONENT */}
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexDirection={"column"}
          width={"70%"}
        >
          {/* Header with Tabs */}
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent="space-between"
            // alignItems="center"
            padding={"24px 24px 0 24px"}
            marginTop={"24px"}
            border={"1px solid #EaEcf0"}
            borderRadius={"16px"}
            width={"100%"}
          >
            {/* Header file */}
            <HeaderDetail
              id={candidiateDetails?.data?._id}
              action={candidiateDetails?.data?.action}
              parsedData={parsedData}
            />
            {/* Tabs */}
            <Box>
              <Tabs
                // defaultValue="Overview"
                variant="scrollable"
                scrollButtons="auto"
                value={currentTab}
                onChange={(event, newValue) => {
                  setCurrentTab(newValue);
                }}
              >
                <Tab
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    textTransform: "none",
                  }}
                  value="Overview"
                  label="Overview"
                  {...allyProps("Overview")}
                />
                <Tab
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    textTransform: "none",
                  }}
                  value="Analytics"
                  label="Analytics"
                  {...allyProps("Analytics")}
                />
                <Tab
                  sx={{
                    fontWeight: "600",
                    fontSize: "14px",
                    textTransform: "none",
                  }}
                  value="Notes"
                  label="Notes"
                  {...allyProps("Notes")}
                />
              </Tabs>
            </Box>
          </Box>

          {/* Header with Tabs */}

          {/* Panels */}

          <CustomTabPanel value={currentTab} index="Overview">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                paddingTop: "16px",
                paddingBottom: "16px",
              }}
            >
              {/* main content */}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "24px",
                }}
              >
                <AboutMe parsedData={parsedData} />
                {candidiateDetails.data?.rawParsedData?.SegregatedSkill[0] ? (
                  <DetailContent
                    heading={"Skills"}
                    skills={
                      candidiateDetails.data.rawParsedData.SegregatedSkill
                    }
                  />
                ) : (
                  <Skeleton width={"55vw"} height={"400px"} />
                )}
                {candidiateDetails.currentData?.experiences[0]?.Employer ? (
                  <DetailContent
                    heading={"Experience"}
                    experiences={candidiateDetails.currentData?.experiences}
                  />
                ) : (
                  <Skeleton width={"100%"} height={"400px"} />
                )}
                {parsedData?.SegregatedQualification[0] ? (
                  <DetailContent
                    heading={"Qualification"}
                    qualification={parsedData?.SegregatedQualification}
                  />
                ) : (
                  <Skeleton width={"100%"} height={"400px"} />
                )}
                {parsedData?.LanguageKnown ? (
                  <DetailContent
                    heading={"Language"}
                    languageKnown={parsedData?.LanguageKnown}
                  />
                ) : (
                  <Skeleton width={"100%"} height={"400px"} />
                )}
              </Box>
            </Box>
          </CustomTabPanel>
          <CustomTabPanel value={currentTab} index={"Analytics"}>
            {analytics ? (
              <Analytics analytics={analytics} minWidth={"55vw"} />
            ) : (
              <Skeleton width={"50vw"} height={"400px"} />
            )}
          </CustomTabPanel>
          <CustomTabPanel
            value={currentTab}
            index={"Notes"}
            sx={{ width: "100%" }}
          >
            <Typography>Notes</Typography>
          </CustomTabPanel>
        </Box>
        {/* MAIN COMPONENT */}
        {/* Side bar */}
        <SideBar parsedData={parsedData} />
        {/* Side bar */}
      </Box>
    </PageContainer>
  );
};
