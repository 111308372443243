/** @format */

import {
  CREATE_DEPARTMENT,
  CREATE_JOB,
  GET_DEPARTMENTS,
  GET_JOB_LIST,
  UPDATE_JOB,
  GET_POSTING_TITLE,
  CREATE_POSTING_TITLE,
  GET_SCREENING_QUESTION,
  SET_LOADING_POSITIONS,
  DELETE_JOB,
  CREATE_JOB_LOADING,
  GET_ANALYTICS,
  LOADING_ANALYTICS,
} from "./constants";
import Axios from "axios";
import { AuthAxios } from "../utils/api";
import { showAlert } from "./alert";
export const getJobList = () => async (dispatch) => {
  try {
    console.log("here");
    const jobs = await AuthAxios.get(`${process.env.REACT_APP_API_URL}/job`);

    dispatch({
      type: GET_JOB_LIST,
      payload: jobs.data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const setLoadingPositions = () => (dispatch) => {
  dispatch({ type: SET_LOADING_POSITIONS });
};

export const createJob = (formData, screeningQuestions) => async (dispatch) => {
  console.log(formData);
  try {
    dispatch({type: CREATE_JOB_LOADING })
    let screeningQuestion = [];
    screeningQuestions.forEach((el) => {
      if (el.checked) {
        screeningQuestion.push(el._id);
      }
    });

    const requestData = {
      jobOpeningStatus: formData.status,
      postingTitle: formData.postingTitle,
      department: formData.department,
      assignedRecruiter: formData.assignedRecruiter,
      jobType: formData.jobType,
      responsibilities: formData.responsibilities,
      minWorkExp: formData.minWorkExp,
      maxWorkExp: formData.maxWorkExp,
      minSalary: formData.minSalaryRange,
      maxSalary: formData.maxSalaryRange,
      country: formData.country,
      state: formData.state,
      city: formData.city,
      panel: formData.panel,
      hiringManager: formData.hiringManager,
      hiringManagerEmail: formData.hiringManagerEmail,
      assignedRecruiterEmail: formData.recruiterEmail,
      screeningQuestion: screeningQuestion,
      jobDescription: formData.jobDescription,
      companyName: formData.companyName,
      jobProfileImage: formData.jobProfileImage
    };
    console.log(requestData);
    const createdJob = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/job`,
      requestData
    );
    console.log(createdJob.data.data.data);
    dispatch({
      type: CREATE_JOB,
      payload: createdJob.data.data.data,
    });

    dispatch(showAlert("Job Created Successfully", "success"));
  } catch (error) {
    dispatch(showAlert("Something Went Wrong Please Try again", "error"));
  }
};

export const modifyJob = (jobData, screeningQuestions) => async (dispatch) => {
  try {
    let screeningQuestion = [];
    screeningQuestions.forEach((el) => {
      if (el.checked) {
        screeningQuestion.push(el._id);
      }
    });

    console.log(jobData);
    const id = jobData._id;
    const requestData = { ...jobData, screeningQuestion: screeningQuestion };
    delete requestData._id;
    delete requestData.__V;
    console.log(requestData);
    const updatedJob = await AuthAxios.patch(
      `${process.env.REACT_APP_API_URL}/job/${id}`,
      requestData
    );
    dispatch({
      type: UPDATE_JOB,
      payload: updatedJob.data.data.data,
    });
    dispatch(showAlert("Job Modified Successfully", "success"));
  } catch (error) { }
};

export const getDepartments = () => async (dispatch) => {
  try {
    const departments = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/department`
    );

    dispatch({
      type: GET_DEPARTMENTS,
      payload: departments.data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const createDepartment = (formData, cb) => async (dispatch) => {
  try {
    const department = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/department`,
      formData
    );

    dispatch({
      type: CREATE_DEPARTMENT,
      payload: department.data.data.data,
    });

    cb("department", department.data.data.data.departmentName);

    dispatch(showAlert("Department Created", "success"));
  } catch (error) {
    console.log(error);
  }
};

export const getPostingTitles = () => async (dispatch) => {
  try {
    const postingTitles = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/postingTitle`
    );
    dispatch({
      type: GET_POSTING_TITLE,
      payload: postingTitles.data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const createPostingTitle = (formData, cb) => async (dispatch) => {
  try {
    const postingTitle = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/postingTitle`,
      formData
    );

    dispatch({
      type: CREATE_POSTING_TITLE,
      payload: postingTitle.data.data.data,
    });

    cb("postingTitle", postingTitle.data.data.data.postingTitle);

    dispatch(showAlert("Posting Title Created", "success"));
  } catch (error) {
    console.log(error);
  }
};

export const getScreeningQuestions = (department) => async (dispatch) => {
  try {
    const screeningQuestion = await AuthAxios.get(
      `${process.env.REACT_APP_API_URL}/screeningQuestion/${department}`
    );

    dispatch({
      type: GET_SCREENING_QUESTION,
      payload: screeningQuestion.data.data.data,
    });
  } catch (error) {
    console.log(error);
  }
};

export const deleteJob = (id) => async (dispatch) => {
  try {
    await AuthAxios.delete(`${process.env.REACT_APP_API_URL}/job/${id}`);

    dispatch({ type: DELETE_JOB, payload: id });
    dispatch(showAlert("Job Deleted Successfully", "success"));
  } catch (error) {
    console.log(error);
  }
};

export const uploadFile = (file) => async (dispatch) => {
  try {
    const jobProfileImage = await Axios.post(`${process.env.REACT_APP_API_URL}/uploadIntro`, file, {
      headers: {
        'content-type': "application/x-www-form-urlencoded",
      },
    }
    );
    return jobProfileImage.data.url
  } catch (error) {
    dispatch(showAlert("Something Went Wrong Please Try again", "error"));
  }
}

export const getAnalyticsData = (filterData) => async (dispatch) => {
  try {
    dispatch({
      type:LOADING_ANALYTICS,
    })
    const analyticsData = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/analytics/analytics`, {filterData}
    );
    dispatch({
      type: GET_ANALYTICS,
      payload: analyticsData.data.data,
    });
  } catch (error) {
    console.log(error);
    dispatch({
      type: GET_ANALYTICS,
      payload:{},
    });
  }
};