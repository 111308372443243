/** @format */

import { combineReducers } from "redux";
import Location from "./location";
import Positions from "./positions";
import Alert from "./alert";
import Outreach from "./outreach";
import Chat from "./chat";
import ScreeningQuestion from "./screeningQuestion";
import Auth from "./auth";
import Upload from "./upload";
export default combineReducers({
	Location,
	Positions,
	Outreach,
	Chat,
	ScreeningQuestion,
	Alert,
	Auth,
	Upload
});
