/** @format */

import {
	GET_DEPARTMENT_BASED_QUESTIONS,
	GET_GENERAL_QUESTIONS,
	ADD_GENERAL_QUESTION,
	ADD_DEPARTMENT_QUESTION,
	DELETE_SCREENING_QUESTION,
	EDIT_QUESTION
} from "../actions/constants";

const INITIAL_STATE = {
	generalQuestions: null,
	departmentQuestions: null
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_GENERAL_QUESTIONS:
			return {
				...state,
				generalQuestions: payload
			};
		case GET_DEPARTMENT_BASED_QUESTIONS:
			return {
				...state,
				departmentQuestions: payload
			};
		case ADD_GENERAL_QUESTION:
			return {
				...state,
				generalQuestions: [...state.generalQuestions, payload]
			};
		case ADD_DEPARTMENT_QUESTION:
			return {
				...state,
				departmentQuestions: [...state.departmentQuestions, payload]
			};
		case DELETE_SCREENING_QUESTION:
			if (payload.department === "General") {
				return {
					...state,
					generalQuestions: state.generalQuestions.filter(
						el => el._id !== payload.id
					)
				};
			} else {
				return {
					...state,
					departmentQuestions: state.departmentQuestions.filter(
						el => el._id !== payload.id
					)
				};
			}
		case EDIT_QUESTION:
			if (payload.dept === "General") {
				return {
					...state,
					generalQuestions: state.generalQuestions.map(el => {
						if (el._id === payload.data._id) {
							return { ...payload.data };
						} else {
							return { ...el };
						}
					})
				};
			} else {
				return {
					...state,
					departmentQuestions: state.departmentQuestions.map(el => {
						if (el._id === payload.data._id) {
							return { ...payload.data };
						} else {
							return { ...el };
						}
					})
				};
			}

		default:
			return {
				...state
			};
	}
}
