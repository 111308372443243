/** @format */

import { Grid, AppBar, Tab, Tabs } from "@material-ui/core";
import Styles from "./AddCandidate.module.scss";
import React, { useState } from "react";
import Panel from "../../GlobalComponents/Panel/Panel";
import Contact from "./Contact/Contact";
import List from "./List/List";
import Template from "./Template/Template";

const AddCandidate = () => {
	const [value, setValue] = useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handelChangeIndex = index => {
		setValue(index);
	};
	return (
		<Grid item xl={9} lg={9} md={9} xs={9}>
			<div className={Styles.container}>
				<Panel value={value} index={0}>
					<Contact />
				</Panel>
			</div>
		</Grid>
	);
};

export default AddCandidate;
