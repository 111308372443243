/** @format */

import React from "react";
import Styles from "./Outreach.module.scss";
import { Grid } from "@material-ui/core";
import ImportBar from "./ImportBar/ImportBar";
import AddCandidate from "./AddCandidate/AddCandidate";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../firebaseConfig";
import { PageContainer } from "../../styled/SharedComponents";
const Outreach = (props) => {
  const [user, loading, error] = useAuthState(auth);
  return user ? (
    <PageContainer>
      <Grid className={`${Styles.container} container`} container>
        <ImportBar />
        <AddCandidate />
      </Grid>
    </PageContainer>
  ) : (
    <Navigate to="/" />
  );
};

const mapStateToProps = (state) => ({
  // isAuthenticated: state.Auth.isAuthenticated,
});
export default connect(mapStateToProps, {})(Outreach);
