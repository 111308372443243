/** @format */

import {
	CREATE_DEPARTMENT,
	CREATE_JOB,
	GET_DEPARTMENTS,
	GET_JOB_LIST,
	UPDATE_JOB,
	GET_POSTING_TITLE,
	CREATE_POSTING_TITLE,
	GET_SCREENING_QUESTION,
	SET_LOADING_POSITIONS,
	DELETE_JOB,
	CHANGE_POSITION_COUNT,
	CREATE_JOB_LOADING,
	GET_ANALYTICS,
	LOADING_ANALYTICS
} from "../actions/constants";

const INITIAL_STATE = {
	jobList: [],
	departmentList: [],
	postingTitles: [],
	screeningQuestion: [],
	loading: true,
	jobLoading: false,
	analyticsLoading:false
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case GET_JOB_LIST:
			return {
				...state,
				jobList: payload,
				loading: false
			};

		case CREATE_JOB:
			return {
				...state,
				jobList: [...state.jobList, payload],
				jobLoading: false
			};
		case GET_POSTING_TITLE:
			return {
				...state,
				postingTitles: payload
			};
		case CREATE_POSTING_TITLE:
			return {
				...state,
				postingTitles: [...state.postingTitles, payload]
			};
		case UPDATE_JOB:
			return {
				...state,
				jobList: [
					...state.jobList.filter(el => el._id !== payload._id),
					payload
				]
			};
		case DELETE_JOB:
			return {
				...state,
				jobList: [...state.jobList.filter(el => el._id !== payload)]
			};
		case GET_DEPARTMENTS:
			return {
				...state,
				departmentList: payload
			};
		case CREATE_DEPARTMENT:
			return {
				...state,
				departmentList: [...state.departmentList, payload]
			};
		case GET_SCREENING_QUESTION:
			return {
				...state,
				screeningQuestion: payload
			};
		case SET_LOADING_POSITIONS:
			return {
				...state,
				loading: true
			};
		case CHANGE_POSITION_COUNT:
			return {
				...state,
				loading: true
			};
		case CREATE_JOB_LOADING:
			return {
				...state,
				jobLoading: true
			}
		case GET_ANALYTICS:
			return {
				...state,
				getAnalytics: payload,
				analyticsLoading:false
			}
		case LOADING_ANALYTICS:
			return {
				...state,
				analyticsLoading: true
			}
		default:
			return { ...state };
	}
}
