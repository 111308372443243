import { store } from "../store";
import { FilePathBuilder, StorageService } from "./storage.service";

export class JobsService {
  /**
   * Uploads job description file
   * @param {File} file
   * @param {string} contentType
   */
  static async uploadJobDescription(file, contentType) {
    const workspaceId = store.getState().Auth.workspaceId;
    console.log("uploadJobDescription", workspaceId);
    const path = new FilePathBuilder(workspaceId)
      .setFileType("JOB_DESCRIPTION")
      .setFileName(file.name)
      .build();

    return await StorageService.uploadFile(file, path, {
      contentType,
    });
  }
}
