import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import { Button, Card, Stack, Typography, Skeleton } from "@mui/material";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Gauge from "../Gauge/Gauge";
import { useGetActionById } from "../../slices/appConfig";
import { CandidateDetailMenu } from "./CandidateDetailMenu";
import { Link } from "react-router-dom";
import { CircularScore } from "../CircularScore/CircularScore";
import { Analytics } from "../CandidateDetails/Analytics";
import { styled } from '@mui/material/styles';


/**
 * Compares two table rows (a and b) to determine sort order based on given orderBy column.
 *
 * @param {object} a - First table row object
 * @param {object} b - Second table row object
 * @param {string} orderBy - Key of column to sort by
 * @returns {number} -1 if b comes before a, 1 if a comes before b, 0 if equal
 */
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

/**
 * Gets a comparator function for sorting rows based on order and orderBy.
 *
 * @param {string} order - Sort order ('asc' or 'desc')
 * @param {string} orderBy - Key to sort by
 * @returns {Function} Comparator function that compares two rows
 */
/**
 * Gets a comparator function for sorting rows based on order and orderBy.
 *
 * @param {string} order - Sort order ('asc' or 'desc')
 * @param {string} orderBy - Key to sort by
 * @returns {Function} Comparator function that compares two rows
 */
function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

/**
 * Sorts the given array while preserving the original order of equal elements.
 *
 * @param {Array} array - The array to sort
 * @param {Function} comparator - A comparator function that returns a sort order for two elements
 * @returns {Array} A new array containing the elements of the input array sorted according to the comparator
 */
/**
 * Sorts the given array while preserving the original order of equal elements.
 *
 * @param {Array} array - The array to sort
 * @param {Function} comparator - A comparator function that returns a sort order for two elements
 * @returns {Array} A new array containing the elements of the input array sorted according to the comparator
 */
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: "Candidates",
    numeric: false,
    disablePadding: true,
    label: "Candidates",
  },
  {
    id: "Roles",
    numeric: false,
    disablePadding: false,
    label: "Roles",
  },
  {
    id: "Contact",
    numeric: false,
    disablePadding: false,
    label: "Contact No.",
  },
  {
    id: "Match",
    numeric: false,
    disablePadding: false,
    label: "JD Match Score",
  },
  {
    id: "Score",
    numeric: false,
    disablePadding: false,
    label: "Screening Score",
  },
  {
    id: "Status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

/**
 * EnhancedTableHead component.
 *
 * @param {Object} props - The props passed to the component.
 * @param {Function} props.onSelectAllClick - Callback for when select all is clicked.
 * @param {String} props.order - The current sort order ('asc' or 'desc').
 * @param {String} props.orderBy - The id of the column to sort by.
 * @param {Number} props.numSelected - The number currently selected.
 * @param {Number} props.rowCount - The total number of rows.
 * @param {Function} props.onRequestSort - Callback for sort request.
 *
 * @returns {JSX.Element} The rendered component.
 */
function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              "aria-label": "select all desserts",
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              <Typography variant="h5">
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </Typography>
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell align="right"></TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

/**
 * CandidatesTable component props.
 *
 * @param {Object[]} candidates - Array of candidate objects to display in table.
 * @param {Function} setNumberOfSelected - Callback function to set number of selected candidates.
 */
export default function CandidatesTable({ candidates, setNumberOfSelected, setCandidatesObj, loading=true }) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [selectedCandidates, setSelectedCandidates] = React.useState([]);

  const getActionById = useGetActionById();

  React.useEffect(() => {
    setNumberOfSelected(selected);
    setCandidatesObj(selectedCandidates);
  }, [selected]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = candidates.map((n) => n._id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id, candidate) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];
    let newSelectedCandidates = [];
  
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
      newSelectedCandidates = newSelectedCandidates.concat(selectedCandidates, candidate);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
      newSelectedCandidates = newSelectedCandidates.concat(
        selectedCandidates.filter((c) => c._id !== id)
      );
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
      newSelectedCandidates = newSelectedCandidates.concat(
        selectedCandidates.filter((c) => c._id !== id)
      );
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
      newSelectedCandidates = newSelectedCandidates.concat(
        selectedCandidates.filter((c) => c._id !== id)
      );
    }
  
    setSelected(newSelected);
    setSelectedCandidates(newSelectedCandidates);
  };
  


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;
  const mapArray = [1,2,3,4,5];

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - candidates.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(candidates, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, candidates]
  );
  return (
    <Box sx={{ width: "100%" }}>
      <Paper
        sx={{
          width: "100%",
          mb: 2,
          boxShadow: "none",
          border: "1px solid #d3d3d3",
        }}
      >
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={candidates.length}
            />
            {loading?
            
              <TableBody>
                {mapArray.map((e) => {
                  return (
                    <TableRow>
                      <TableCell colSpan={8} >
                        <Skeleton variant="retangular" width={"100%"} height={50} />
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            :
            <TableBody>
            {visibleRows.map((row, index) => {
              const isItemSelected = isSelected(row._id);
              const labelId = `enhanced-table-checkbox-${index}`;

              return (
                <TableRow
                  hover
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  key={row._id}
                  selected={isItemSelected}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      color="primary"
                      checked={isItemSelected}
                      onClick={(event) => handleClick(event, row._id, row)}
                      inputProps={{
                        "aria-labelledby": labelId,
                      }}
                    />
                  </TableCell>
                  <TableCell
                    component="th"
                    id={labelId}
                    scope="row"
                    padding="none"
                  >
                    <Stack>
                      <Typography
                        variant="h5"
                        fontSize={"14px"}
                        fontFamily={"Inter"}
                        fontWeight={600}
                        sx={{
                          "&:hover": {
                            textDecoration: "underline",
                          },
                        }}
                      >
                        <Link
                          style={{
                            textDecoration: "none",
                            textTransform: "none",
                            color: "inherit",
                          }}
                          to={`/candidates/${row._id}`}
                        >
                          {row.name}
                        </Link>
                      </Typography>
                      <Typography
                        variant="h5"
                        fontFamily={"Inter"}
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"#475467"}
                      >
                        {row.email}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">
                    <Stack>
                      <Typography
                        variant="h5"
                        fontSize={"14px"}
                        fontFamily={"Inter"}
                        fontWeight={500}
                      >
                        {row.rawParsedData?.JobProfile}
                      </Typography>
                      <Typography
                        variant="h5"
                        fontFamily={"Inter"}
                        fontSize={"14px"}
                        fontWeight={400}
                        color={"#475467"}
                      >
                        {row.rawParsedData?.CurrentEmployer}
                      </Typography>
                    </Stack>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h5">{row.phone}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <LightTooltip
                      title={
                        <Analytics analytics={row.rawNewAnalysis} />
                      }
                    >
                      <Button>
                        <CircularScore score={row.matchScore} />
                      </Button>
                    </LightTooltip>
                  </TableCell>
                  <TableCell align="left">
                      <Button>
                        <CircularScore score={row.score} />
                      </Button>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="h5">
                      {getActionById(row.action)?.label || "New Application"}
                    </Typography>
                  </TableCell>
                  {/* menu issue here */}
                  <TableCell key={row._id} align="right" width={"10px"}>
                    <CandidateDetailMenu id={row._id} />
                  </TableCell>
                </TableRow>
              );
            })}
            {emptyRows > 0 && (
              <TableRow
                style={{
                  height: 53 * emptyRows,
                }}
              >
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
            }
            
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={candidates.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}
