const matchScoreLabels = [
  {
    label: "80-100%",
    value: "80-100",
  },
  {
    label: "60-79%",
    value: "60-79",
  },
  {
    label: "40-59%",
    value: "40-59",
  },
  {
    label: "Bellow 40%",
    value: "0-39",
  },
];

const yearsOfExperienceLabels = [
  {
    label: "15+",
    value: "15-",
  },
  {
    label: "10+",
    value: "10-",
  },
  {
    label: "8-10",
    value: "8-10",
  },
  {
    label: "2-5",
    value: "2-5",
  },
  {
    label: "1-2",
    value: "1-2",
  },
  {
    label: "Freshers",
    value: "0-1",
  },
];
const companyTypeLabels = [
  {
    label: "Priority Companies",
    value: "Priority",
  },
  {
    label: "Blacklist Companies",
    value: "Blacklist",
  },
  {
    label: "All",
    value: "",
  },
];

export { matchScoreLabels, yearsOfExperienceLabels, companyTypeLabels };
