/** @format */

import React, { useState } from "react";
import Styles from "./Candidate.module.scss";
import { MoreVert } from "@material-ui/icons";
import { Dialog, Menu, MenuItem } from "@material-ui/core";
import { connect } from "react-redux";
import CandidateDetails from "./CandidateDetails/CandidateDetails";
import { getMessage, removeChat } from "../../../actions/chat";
const Candidate = props => {
	const {
		candidate,
		filterData: { sort },
		updateCandidate,
		selectedOption,
		setLoadData,
		getMessage,
		removeChat
	} = props;
	const [anchorEl, setAnchorEl] = useState(null);
	const [modalState, setModalState] = useState(false);

	const onModalClose = e => {
		removeChat();
		setModalState(false);
	};

	const handleClick = e => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = action => {
		if (typeof action === "string") {
			setLoadData(true);
			updateCandidate(candidate._id, action);
		}
		setAnchorEl(null);
	};

	const openModal = () => {
		getMessage(candidate._id);
		setModalState(true);
	};

	return (
		<div className={Styles.candidateContainer}>
			<div>
				<div onClick={() => openModal()}>
					<img src='/defaultimg.png' />
					<p className={`${sort === "name" ? Styles.active : ""}`}>
						{candidate.name}
					</p>
				</div>
				<div>
					<MoreVert className={Styles.moreIcon} onClick={handleClick} />
					<Menu
						id='simple-menu'
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					>
						{selectedOption !== "Schedule" && (
							<MenuItem
								className={Styles.menuItem}
								onClick={e => handleClose("Schedule")}
							>
								Schedule
							</MenuItem>
						)}
						{selectedOption !== "Disqualify" && (
							<MenuItem
								className={Styles.menuItem}
								onClick={e => handleClose("Disqualify")}
							>
								Disqualify
							</MenuItem>
						)}
						{selectedOption !== "Assess Further" && (
							<MenuItem
								className={Styles.menuItem}
								onClick={e => handleClose("Assess Further")}
							>
								Assess Further
							</MenuItem>
						)}
						{selectedOption !== "Future Reference" && (
							<MenuItem
								className={Styles.menuItem}
								onClick={e => handleClose("Future Reference")}
							>
								Future Reference
							</MenuItem>
						)}
						{selectedOption !== "Shortlist" && (
							<MenuItem
								className={Styles.menuItem}
								onClick={e => handleClose("Shortlist")}
							>
								Shortlist
							</MenuItem>
						)}
						<MenuItem
							className={Styles.menuItem}
							onClick={e => handleClose("Screen")}
						>
							Screen
						</MenuItem>
					</Menu>
				</div>
			</div>
			<div className={Styles.candidateDetails}>
				<div
					className={`${Styles.detailTitle} ${
						sort === "email" ? Styles.active : ""
					} `}
				>
					Email:
				</div>
				<div
					className={`${Styles.detailValue} ${
						sort === "email" ? Styles.active : ""
					}`}
				>
					{candidate.email}
				</div>
				<div
					className={`${Styles.detailTitle} ${
						sort === "phone" ? Styles.active : ""
					}`}
				>
					Phone:
				</div>
				<div
					className={`${Styles.detailValue} ${
						sort === "phone" ? Styles.active : ""
					} `}
				>
					{candidate.phone}
				</div>
				<div
					className={`${Styles.detailTitle} ${
						sort === "job" ? Styles.active : ""
					} `}
				>
					Applying Position:
				</div>
				<div
					className={`${Styles.detailValue} ${
						sort === "postingTitle" ? Styles.active : ""
					}`}
				>
					{candidate.jobId && candidate.jobId.postingTitle.postingTitle}
				</div>
				<div
					className={`${Styles.detailTitle} ${
						sort === "Department" ? Styles.active : ""
					} `}
				>
					Department:
				</div>
				<div
					className={`${Styles.detailValue} ${
						sort === "departmentName" ? Styles.active : ""
					} `}
				>
					{candidate.jobId && candidate.jobId.department}
				</div>

				<div
					className={`${Styles.detailTitle} ${
						sort === "score" ? Styles.active : ""
					} `}
				>
					Score:
				</div>
				<div
					className={`${Styles.detailValue} ${
						sort === "score" ? Styles.active : ""
					} `}
				>
					{candidate.score}
				</div>
			</div>
			<Dialog
				disableBackdropClick={true}
				maxWidth='md'
				open={modalState}
				className={Styles.dialog}
				onClose={onModalClose}
			>
				<CandidateDetails
					onModalClose={onModalClose}
					candidate={candidate}
					setLoadData={setLoadData}
				/>
			</Dialog>
		</div>
	);
};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { getMessage, removeChat })(Candidate);
