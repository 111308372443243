/** @format */

import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Styles from "./ScreeningQuestion.module.scss";
import { getScreeningQuestions } from "../../../actions/positions";
import {
	getDepartmentWiseQuestion,
	createQuestion,
	updateQuestion,
	deleteQuestion,
	getGeneralQuestions
} from "../../../actions/screeningQuestions";
import { Paper, Checkbox, Dialog, Button } from "@material-ui/core";
import AddQuestion from "../../Positions/ScreeningQuestion/AddQuestion/AddQuestion";
import { Delete, Edit } from "@material-ui/icons";
const ScreeningQuestions = props => {
	const {
		department,
		screeningQuestions,
		getScreeningQuestions,
		handleScreeningQuestions,
		screeningQuestionsLocal,
		addQuestions=false,
		getDepartmentWiseQuestion,
		departmentQuestions,
		deleteQuestion,
		createQuestion,
		updateQuestion,
	} = props;
	useEffect(() => {
		if (department !== "") {
			getScreeningQuestions(department);
			getDepartmentWiseQuestion(department);
			setDepartmentQuestionLocal(departmentQuestions)
		}
	}, [department]);
	const [addQuestionModalOpen, setAddQuestionModalOpen] = useState(false);
	const [editQuestionData, setEditQuestionData] = useState({});
 	const [editQuestion, setEditQuestion] = useState(false);
	const [confirmModal, setConfirmModal] = useState(false);
	const [deleteQuestionData, setDeleteQuestionData] = useState({});
	const [category, setCategory] = useState('');
	const [departmentQuestionLocal, setDepartmentQuestionLocal] = useState();
	const [addQuestion, setAddQuestion] = useState(false);
	const onConfirmReject = () => {
		setConfirmModal(false);
		setDeleteQuestionData({});
	  };
	
	  const onConfirmAccept = () => {
		deleteQuestion(deleteQuestionData._id, deleteQuestionData.department);
		setConfirmModal(false);
		setDeleteQuestionData({});
	  };
	const openAddQuestionModal = (name)=>{
		setCategory(name)
		setAddQuestionModalOpen(true);
	  }
	const onEditQuestionClick = (question) => {
		setEditQuestionData(question);
		setEditQuestion(true);
		setAddQuestionModalOpen(true);
	  };
	
	  const onConfirmRequest = (question) => {
		setConfirmModal(true);
		setDeleteQuestionData(question);
	  };
	  const closeAddQuestionModal = ()=>{
		setAddQuestionModalOpen(false)
	  }
	return (
		<div>
			{ screeningQuestionsLocal!==null&& screeningQuestionsLocal?.length === 0 ? (
				<h1 className={Styles.heading}>
					There Are No Questions in this Department
				</h1>
			) : screeningQuestionsLocal!==null&&screeningQuestionsLocal?.length !== 0 ? (
				<>
					<div className={Styles.questionContainer}>
						<Paper elevation={3}>
							<h3>General Questions</h3>
							<div className={Styles.question}>
								{screeningQuestionsLocal !== null &&
									screeningQuestionsLocal.map((question, index) => {
										if (question.department === "General") {
											return (
											<p key={index}>
												<Checkbox
													value={question._id}
													checked={question.checked}
													onChange={e => handleScreeningQuestions(e)}
													color='primary'
													className={Styles.checkBox}
												/>
												{question.question}
										<span>
											<Edit	
												className={Styles.editIcon}
												size="large"
												onClick={() => onEditQuestionClick(question)}
											/>
											<Delete
												className={Styles.deleteIcon}
												size="large"
												onClick={(e) => onConfirmRequest(question)}
											/>
										</span>{" "}
									</p>
												
											);
										}
									})}
							</div>
							<Button
								onClick={(e) => { openAddQuestionModal("General") }}
								size={"small"}
								color={"primary"}
								variant={"contained"}
								aria-label={"General"}
							>
								Add Question
							</Button>
						</Paper>
						{/* <Paper>
							<h3>Department Based Questions</h3>
							<div className={Styles.question}>
							{departmentQuestions !== null ? (
								departmentQuestions?.length !== 0 ? (
									departmentQuestions?.map((question) => (
										<p>
											<Checkbox
													value={question._id}
													checked={question.checked}
													onChange={e => handleDepartmentScreeningQuestions(e)}
													color='primary'
													className={Styles.checkBox}
												/>
											{question.question}
											<span>
												<Edit
													className={Styles.editIcon}
													size="large"
													onClick={() => onEditQuestionClick(question)}
												/>
												<Delete
													className={Styles.deleteIcon}
													size="large"
													onClick={(e) => onConfirmRequest(question)}
												/>
											</span>
										</p>
									))
								) : (
									<h3>There are no questions in this department</h3>
								)
							) : (
								""
							)}
							</div>
							<Button
								onClick={(e)=>{openAddQuestionModal(department)}}
								size={"small"}
								color={"primary"}
								variant={"contained"}
								aria-label={department}
							>
								Add Question
							</Button>
						</Paper> */}
					</div>
				</>
			) : (
				""
			)}
			<Dialog open={addQuestionModalOpen} onClose={closeAddQuestionModal}>
          <AddQuestion
            category={category}
            createQuestion={createQuestion}
            setModalOpen={setAddQuestionModalOpen}
            editQuestionData={editQuestionData}
            editQuestion={editQuestion}
            updateQuestion={updateQuestion}
			screeningQuestionsLocal={screeningQuestionsLocal}
			addQuestion={addQuestion}
            setAddQuestion={setAddQuestion}
          />
        </Dialog>
		<Dialog open={confirmModal} onClose={onConfirmReject}>
          <div className={Styles.deleteModal}>
            <p>Are you sure you want to delete this Screening Question ?</p>
            <div>
              <Button variant="contained" onClick={onConfirmAccept}>
                Yes
              </Button>
              <Button variant="outlined" onClick={onConfirmReject}>
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>
		</div>
	);
};

const mapStateToProps = state => ({
	screeningQuestions: state.Positions.screeningQuestion,
	departmentQuestions: state.ScreeningQuestion.departmentQuestions,
});

export default connect(mapStateToProps, { getScreeningQuestions,
	getDepartmentWiseQuestion,
	deleteQuestion,
	createQuestion,
	updateQuestion,
	getGeneralQuestions
 })(
	ScreeningQuestions,
);
