/** @format */

import { Button, FormControl, Select, MenuItem } from "@material-ui/core";
import { ArrowDownward, ArrowUpward, SearchOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import Candidate from "./Candidate/Candidate";
import Styles from "./Candidates.module.scss";
import {
  getAllCandidates,
  getCandidatesByAction,
  updateCandidateAction,
} from "../../actions/outreach";
import _ from "lodash";
import { PageContainer } from "../../styled/SharedComponents";
import { async } from "q";
const Candidates = (props) => {
  const {
    getAllCandidates,
    allCandidates,
    getCandidatesByAction,
    loading,
    updateCandidateAction,
  } = props;
  const dispatch = useDispatch()
  const [filterData, setFilterData] = useState({
    search: "",
    sort: "score",
    order: "desc",
  });

  const [filterAbleCandidatesList, setFilterAbleCandidatesList] = useState([]);
  const [loadData, setLoadData] = useState(true);
  const [selectedOption, setSelectedOption] = useState("");
  useEffect(() => {
    if (selectedOption !== "") {
      getCandidatesByAction(selectedOption);
      setLoadData(true);
    }
  }, [selectedOption]);

  useEffect(() => {
    if (loadData) {
      const newData = allCandidates.map((el) => ({ ...el }));
      const sortedData = _.orderBy(newData, ["score"], ["desc"]);
      setFilterAbleCandidatesList(sortedData);
      setLoadData(false);
    }
  }, [allCandidates]);
const fetchData = async ()=>{
  await getAllCandidates();
  console.log(allCandidates, "get all");
  return ()=>{}
}
  useEffect(() => {
    dispatch( fetchData() );
    return ()=>{
      console.log("component will unmaount")
    }
  }, [dispatch]);
  const onChange = (e) => {
    setFilterData({ ...filterData, [e.target.name]: e.target.value });

    if (e.target.name === "search" && e.target.value !== "") {
      const newData = allCandidates.filter((el) => {
        if (el.name.startsWith(e.target.value)) {
          return { ...el };
        }
      });

      console.log(newData);

      setFilterAbleCandidatesList(newData);
    } else if (e.target.value === "") {
      const newData = allCandidates.map((el) => {
        return { ...el };
      });
      const sortedData = _.orderBy(newData, ["score"], [filterData.order]);
      setFilterAbleCandidatesList(sortedData);
    }

    if (e.target.name === "sort") {
      const order = e.target.value === "score" ? filterData.order : "asc";
      const newData = _.orderBy(allCandidates, [e.target.value], [order]);
      setFilterAbleCandidatesList(newData);
    }
  };
  const changeOrder = (e, order) => {
    console.log("here");
    if (order !== filterData.order) {
      console.log("here");

      setFilterData({ ...filterData, order: order });
      const newOrderData = _.orderBy(
        filterAbleCandidatesList,
        ["score"],
        [order]
      );
      setFilterAbleCandidatesList(newOrderData);
    }
  };

  return (
    <PageContainer>
      <div className="container">
        <div className={Styles.header}>
          <div className={Styles.inputContainer}>
            <input
              placeholder="Search"
              onChange={onChange}
              name="search"
              value={filterData.search}
            />
            <SearchOutlined />
          </div>
          <div className={Styles.inputContainer}>
            <FormControl variant="standard" className={Styles.formControl}>
              <Select
                name={"sort"}
                displayEmpty
                className={Styles.select}
                value={filterData.sort}
                onChange={onChange}
              >
                <MenuItem className={Styles.menuitem} value={"name"}>
                  Name
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"email"}>
                  Email
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"phone"}>
                  Phone
                </MenuItem>

                <MenuItem className={Styles.menuitem} value={"score"}>
                  Score
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={Styles.buttonContainer}>
            <Button
              className={`${filterData.order === "asc" ? Styles.active : ""}`}
              onClick={(e) => changeOrder(e, "asc")}
            >
              <ArrowUpward />
            </Button>
            <Button
              className={`${filterData.order === "desc" ? Styles.active : ""}`}
              onClick={(e) => changeOrder(e, "desc")}
            >
              <ArrowDownward />
            </Button>
          </div>
        </div>

        <div className={Styles.candidatesContainer}>
          <div className={Styles.bucketOptions}>
            <p
              className={`${
                selectedOption === "Schedule" ? Styles.active : ""
              }`}
              onClick={() => setSelectedOption("Schedule")}
            >
              Schedule
            </p>
            <p
              className={`${
                selectedOption === "Disqualify" ? Styles.active : ""
              }`}
              onClick={() => setSelectedOption("Disqualify")}
            >
              Disqualify
            </p>
            <p
              className={`${
                selectedOption === "Assess Further" ? Styles.active : ""
              }`}
              onClick={() => setSelectedOption("Assess Further")}
            >
              {" "}
              Assess Further
            </p>
            <p
              className={`${
                selectedOption === "Future Reference" ? Styles.active : ""
              }`}
              onClick={() => setSelectedOption("Future Reference")}
            >
              Future Reference
            </p>
            <p
              className={`${
                selectedOption === "Shortlist" ? Styles.active : ""
              }`}
              onClick={() => setSelectedOption("Shortlist")}
            >
              Shortlist
            </p>
            <p
              className={`${
                selectedOption === "Schedule Later" ? Styles.active : ""
              }`}
              onClick={() => setSelectedOption("Schedule Later")}
            >
              Schedule Later
            </p>
          </div>
          <div className={Styles.candidateViewer}>
            <h2>Candidates</h2>
            <div
              className={`${Styles.candidatesList} ${
                loading ? Styles.center : ""
              } ${
                filterAbleCandidatesList.length === 0 || selectedOption === ""
                  ? Styles.centerError
                  : ""
              }`}
            >
              {filterAbleCandidatesList.length === 0 ||
              selectedOption === "" ? (
                selectedOption === "" ? (
                  <h2>Please Select an Option to view candidates</h2>
                ) : (
                  <h2>There are no candidates in this option</h2>
                )
              ) : loading ? (
                <div className={Styles.loading}></div>
              ) : (
                filterAbleCandidatesList.length !== 0 &&
                selectedOption !== "" &&
                filterAbleCandidatesList.map((candidate) => {
                  return (
                    <Candidate
                      updateCandidate={updateCandidateAction}
                      candidate={candidate}
                      filterData={filterData}
                      selectedOption={selectedOption}
                      setLoadData={setLoadData}
                    />
                  );
                })
              )}
            </div>
          </div>
        </div>
      </div>
    </PageContainer>
  );
};

const mapStateToProps = (state) => ({
  allCandidates: state.Outreach.allCandidates,
  loading: state.Outreach.loading,
});

export default connect(mapStateToProps, {
  getAllCandidates,
  getCandidatesByAction,
  updateCandidateAction,
})(Candidates);
