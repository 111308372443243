//tabs
import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { AuthAxios } from "../../utils/api";
import { Button, Skeleton, Stack, useTheme } from "@mui/material";
import {
  useChangeCandidateStageByIdMutation,
  useGetCandidatesQuery,
} from "../../slices/candidates";
import { useAllAllowedActions, useAtsActions } from "../../slices/appConfig";
import CandidatesTable from "./CandidatesTable";
import FilterListIcon from "@mui/icons-material/FilterList";
import { FilterCandidates } from "./FilterComponents/filter";
import { useDispatch, useSelector } from "react-redux";
import {
  selectCandidateFilters,
  selectFilteredCandidates,
  setAction,
  setPosition,
  setDepartment,
} from "../../slices/candidateFilters";
import JobSelect from "../GlobalComponents/JobSelect/JobSelect";
import ReachOut from "../dialogs/Reachout";

export function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            height: "75vh",
            padding: "32px 0",
          }}
        >
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const BucketsTabs = (props) => {
  const { allowedActions, showAllApplicants } = props;
  const [selectedIds, setSelectedIds] = React.useState([]);
  const [filterOpen, setFilterOpen] = React.useState(false);
  const [nudgeModalState, setNudgeModalState] = React.useState(false);
  const [scheduleModal, setScheduleModal] = React.useState(false);
  const [candidateObj, setCandidatesObj] = React.useState([]);
  // Filter state switch
  const handleFilterToggle = () => {
    setFilterOpen((prev) => !prev);
  };
  const dispatch = useDispatch();
  const handleChange = (event, newValue) => {
    dispatch(setAction(newValue));
  };
  React.useEffect(() => {
    if (!showAllApplicants) {
      dispatch(setAction("future reference"));
    } else {
      dispatch(setAction(null));
    }
  }, [showAllApplicants, dispatch]);
  const { isFetching } = useGetCandidatesQuery();
  const filteredCandidates = useSelector(selectFilteredCandidates);
  const { action, department, position } = useSelector(selectCandidateFilters);

  const [changeCandidateStageById, { isLoading: isChangingStage }] =
    useChangeCandidateStageByIdMutation();

  const allAllowedActions = useAllAllowedActions();

  const loading = isFetching || isChangingStage;

  const changeCandidateState = React.useCallback(
    (stage) => {
      selectedIds.forEach((id) => {
        changeCandidateStageById({ id, stage });
      });
    },
    [selectedIds, changeCandidateStageById]
  );

  const theme = useTheme();
  console.log(filteredCandidates,'===========filteredCandidates===========')
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={action}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {showAllApplicants && (
            <Tab
              sx={{
                fontWeight: "600",
                fontSize: "16px",
                textTransform: "none",
              }}
              label="All applicants"
              {...a11yProps(0)}
              value={null}
            />
          )}
          {allowedActions?.map((action) => {
            return (
              <Tab
                key={action._id}
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  textTransform: "none",
                }}
                label={action.label}
                {...a11yProps(0)}
                value={action._id}
              />
            );
          })}
        </Tabs>
      </Box>

      <Box
        sx={{ width: "100%", position: "relative", marginTop: "24px" }}
        flexDirection={"row"}
        display={"flex"}
        justifyContent={"start"}
        gap={"16px"}
      >
        <Button
          variant="outlined"
          startIcon={<FilterListIcon />}
          onClick={handleFilterToggle}
          sx={{
            padding: "8px 16px",
          }}
        >
          <Typography color={"#000"} variant="h5" textTransform={"none"}>
            Filters
          </Typography>
        </Button>
        <FilterCandidates open={filterOpen} handleToggle={handleFilterToggle} />
        <JobSelect
          value={{
            position,
            department,
          }}
          onChange={({ position, department }) => {
            dispatch(setPosition(position));
            dispatch(setDepartment(department));
          }}
        />
      </Box>
      {/* {!loading ? ( */}
        <CandidatesTable
          candidates={filteredCandidates.filter((c) => {
            if (!action) {
              return allAllowedActions.some((a) => a._id === c.action) &&c.action!="future reference" &&c.action!="disqualified";
            }
            return c.action === action;
          })}
          setNumberOfSelected={setSelectedIds}
          setCandidatesObj={setCandidatesObj}
          loading={loading}
        />
      {/* ) : (
        <Skeleton variant="retangular" width={"100%"} height={400} />
      )} */}

      {selectedIds?.length > 0 && (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            bottom: "16px",
            display: "flex",
            padding: "12px",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "12px",
            border: "1px solid  #B692F6",
            boxShadow:
              "0px 0px 0px 4px #F4EBFF, 0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
          }}
        >
          <Typography variant="h5" color={theme.palette.common.black}>
            {selectedIds?.length} - SELECTED
          </Typography>
          <Stack direction="row" spacing={2}>
            {allAllowedActions.map((action) => {
              return (
                <Button
                  onClick={() => {
                    if (action._id == "screening") {
                      setNudgeModalState(true);
                    }
                    else if (action._id == "scheduling") {
                      setScheduleModal(true);
                    } else {
                      changeCandidateState(action._id)
                    }
                  }}
                  variant="outlined"
                  color="primary"
                  disabled={action === action._id}
                >
                  <Typography variant="h5" color={theme.palette.common.black}>
                    {action.label}
                  </Typography>
                </Button>
              );
            })}
          </Stack>
        </Box>
      )}
      <ReachOut 
      selectedCandidates={selectedIds} 
      nudgeModalState={nudgeModalState} 
      setNudgeModalState={setNudgeModalState} 
      openScheduleModal={scheduleModal}
      setScheduleModal={setScheduleModal}
      candidateObj={candidateObj}
      />
    </Box>
  );
};

export default BucketsTabs;
