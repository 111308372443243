/** @format */

import React, { useState, useEffect } from "react";
import Styles from "./Vacancies.module.scss";
import Job from "./Job/Job";
import _ from "lodash";
import {
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import { connect } from "react-redux";
const Vacancies = (props) => {
  const {
    departmentList,
    postingTitles,
    jobList,
    selectedVacancy,
    setSelectedVacancy,
    setLoading,
    jobListLoading,
    setSingleCandidate,
    removeChat,
    hidden,
    currentJob,
    setCurrentJob,
    setSelectedCandidates,
    unassignedLength,
  } = props;

  const [formData, setFormData] = useState({
    postingTitleName: "",
    department: "",
  });
  const [filterableJobList, setFilterAbleJobList] = useState([]);

  useEffect(() => {
    // setFilterAbleJobList(jobList);
    if (jobList !== null && jobList.length !== 0) {
      const newJobList = jobList.map((el) => ({ ...el, clicked: false }));
      setFilterAbleJobList(newJobList);
    }
  }, [jobList]);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
    const filterObj = {
      ...formData,
      [e.target.name]: e.target.value,
    };

    let mainFilter = {};
    for (var key in filterObj) {
      if (filterObj[key] !== "") {
        mainFilter[key] = filterObj[key];
      }
    }
    console.log(mainFilter,'=================')
    const filteredList = _.filter(jobList, mainFilter);
    console.log(filteredList,'========filteredList============')
    setFilterAbleJobList(filteredList);
  };

  const changeClicked = (e, id, job) => {
    setSingleCandidate({});
    removeChat();
    const newData = filterableJobList.map((job) => {
      if (id === job._id) {
        return { ...job, clicked: true };
      } else {
        return { ...job, clicked: false };
      }
    });
    setSelectedVacancy(job);
    setFilterAbleJobList(newData);
  };

  const changeJob = (e, job) => {
    setCurrentJob(job);
    setSelectedCandidates([]);
  };

  const setUnassignedActive = () => {
    // console.log("here");
    setSingleCandidate({});

    removeChat();
    const newData = filterableJobList.map((job) => {
      return { ...job, clicked: false };
    });
    setSelectedVacancy({ position: "Unassigned" });
    setFilterAbleJobList(newData);
  };

  return (
    <div
      className={`${Styles.vacancyContainer} ${hidden ? Styles.hidden : ""}`}
    >
      <h2>VACANCIES</h2>
      <div className={Styles.filterBox}>
        <FormControl variant="standard" className={Styles.formControl}>
          <Select
            name={"department"}
            displayEmpty
            className={Styles.select}
            value={formData.department}
            onChange={onChange}
          >
            <MenuItem className={Styles.menuitem} value="">
              Department
            </MenuItem>
            {departmentList !== null &&
              departmentList.map((dept, index) => {
                return (
                  <MenuItem
                    key={index}
                    className={Styles.menuitem}
                    value={dept._id}
                  >
                    {dept.departmentName}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        <FormControl variant="standard" className={Styles.formControl}>
          <Select
            name={"postingTitleName"}
            displayEmpty
            className={Styles.select}
            value={formData.postingTitleName}
            onChange={onChange}
          >
            <MenuItem className={Styles.menuitem} value="">
              Position
            </MenuItem>
            {postingTitles !== null &&
              postingTitles.map((title, index) => {
                return (
                  <MenuItem
                    key={index}
                    className={Styles.menuitem}
                    value={title.postingTitle}
                  >
                    {title.postingTitle}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
        
      </div>
      <div
        className={`${Styles.postingBox} ${
          jobListLoading ? Styles.jobsLoading : ""
        }`}
      >
        {filterableJobList !== null &&
          filterableJobList.length !== 0 &&
          filterableJobList.map((job) => (
            <Job
              changeClicked={changeClicked}
              job={job}
              setLoading={setLoading}
              changeJob={changeJob}
            />
          ))}
        {!jobListLoading ? (
          <Job
            job={{
              postingTitle: { postingTitle: "Unassigned" },
              unassigned: true,
              candidateCount: unassignedLength ? unassignedLength : 0,
            }}
            changeJob={changeJob}
            changeClicked={setUnassignedActive}
          />
        ) : (
          ""
        )}

        {!jobListLoading ? (
          filterableJobList !== null &&
          filterableJobList.length === 0 && (
            <h3 className={Styles.noVacancy}>No Vacancy Available</h3>
          )
        ) : (
          <div className={Styles.loading}></div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  jobListLoading: state.Positions.loading,
  unassignedLength: state.Outreach.unassignedCandidates,
});

export default connect(mapStateToProps, {})(Vacancies);
