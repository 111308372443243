/** @format */
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Outreach from "./components/Outreach";
import Positions from "./components/Positions";
import Candidates from "./components/Candidates";
import ScreeningQuestion from "./components/Positions/ScreeningQuestion/ScreeningQuestion";
import { Alert } from "@material-ui/lab";
import { connect } from "react-redux";
import { useEffect } from "react";
import SignIn from "./components/SignIn";
import { auth } from "./firebaseConfig";
import Protected from "./components/GlobalComponents/Protected/Protected";
import Workspaces from "./components/Workspaces/Workspaces";
import ManageWorkspaces from "./components/Workspaces/Manage/Manage";
import { AuthAxios } from "./utils/api";
import { CssBaseline } from "@mui/material";
import Ats from "./components/Ats/Ats";
import CandidateDetails from "./components/CandidateDetails/CandidateDetails";
import { useLazyGetAppConfigQuery } from "./slices/appConfig";
import Database from "./components/Database/Database";
import VerifyEmail from "./components/VerifyEmail/VerifyEmail";
import Analytics from "./components/Analytics/Analytics";

const { default: Navbar } = require("./components/Navbar");
/** @format */
function App(props) {
  const { currentWorkspaceId } = props;
  const [
    getAppConfig,
    {
      isLoading: isAppConfigLoading,
      isError: isAppConfigError,
      error: appConfigError,
    },
  ] = useLazyGetAppConfigQuery();

  useEffect(() => {
    try{
      AuthAxios.interceptors.request.use(async (config) => {
        const token = await auth.currentUser?.getIdToken();
        config.headers.authorization = `Bearer ${token}`;
        return config;
      });
      const unsub = auth.onAuthStateChanged((user) => {
        if (currentWorkspaceId && user) {
          getAppConfig();
        }
      });
      return unsub;
    }catch(error){

    }
   
  }, []);

  useEffect(() => {
    try{
      let newId;
      if (currentWorkspaceId) {
        newId = AuthAxios.interceptors.request.use((config) => {
          config.headers["x-workspace-id"] = currentWorkspaceId;
          return config;
        });
        getAppConfig();
      }
      return () => {
        AuthAxios.interceptors.request.eject(newId);
      };
    }catch(error){
console.log(error)
    }
   
  }, [currentWorkspaceId]);

  const { message, variant } = props;
  return (
    <>
      <Router>
        {message !== null ? (
          <Alert variant={"filled"} severity={variant}>
            {message}
          </Alert>
        ) : null}
        <Routes>
          <Route exact path="/" element={<SignIn />} />
          <Route
            exact
            path="/dashboard"
            element={
              <Protected>
                <Navbar />
                <Dashboard />
              </Protected>
            }
          />
          <Route
            exact
            path="/outreach"
            element={
              <Protected>
                <Navbar />
                <Outreach />
              </Protected>
            }
          />
          <Route
            exact
            path="/positions"
            element={
              <Protected>
                <Navbar />
                <Positions />
              </Protected>
            }
          />
          <Route
            exact
            path="/candidates"
            element={
              <Protected>
                <Navbar />
                <Candidates />
              </Protected>
            }
          />
          <Route
            exact
            path="/candidates/:id"
            element={
              <Protected>
                <Navbar />
                <CandidateDetails />
              </Protected>
            }
          />
          <Route
            exact
            path="/Screening"
            element={
              <Protected>
                <Navbar />
                <ScreeningQuestion />
              </Protected>
            }
          />
          <Route
            exact
            path="/ats"
            element={
              <Protected>
                <Navbar />
                <Ats />
              </Protected>
            }
          />
          <Route
            exact
            path="/database"
            element={
              <Protected>
                <Navbar />
                <Database />
              </Protected>
            }
          />
          <Route
            exact
            path="/workspaces"
            element={
              <Protected>
                <Workspaces />
              </Protected>
            }
          />
          <Route
            exact
            path="/workspaces/manage"
            element={
              <Protected>
                <Navbar />
                <ManageWorkspaces />
              </Protected>
            }
          />
          <Route
            exact
            path="/verify-email"
            element={
              <Protected allowUnverified={true}>
                <VerifyEmail />
              </Protected>
            }
          />
            <Route
            exact
            path="/analytics"
            element={
              <Protected allowUnverified={true}>
                <Navbar />
                <Analytics />
              </Protected>
            }
          />
        </Routes>
      </Router>
      <CssBaseline />
    </>
  );
}

const mapStateToProps = (state) => ({
  message: state.Alert.message,
  variant: state.Alert.variant,
  currentWorkspaceId: state.Auth.workspaceId,
});

export default connect(mapStateToProps)(App);
