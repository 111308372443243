import { store } from "../store";
import { AuthAxios } from "../utils/api";
import { FilePathBuilder, StorageService } from "./storage.service";

/**
 * @typedef("./types").BulkCandidateUploadEntry BulkCandidateUploadEntry
 */

export class CandidateService {
  /**
   * Uploads a list of candidates to the database using jobId and resumes
   * @param {Array<BulkCandidateUploadEntry>} entries
   */
  static async bulkUploadCandidates(entries) {
    const res = await AuthAxios.post(
      `${process.env.REACT_APP_API_URL}/candidates/bulk`,
      {
        candidates: entries,
      }
    );

    return res.data?.insertedCandidates;
  }

  /**
   * Uploads candidates for a jobId in bulk, using resume
   * @param {string} jobId
   * @param {Array<string>} resumes
   */
  static async bulkUploadCandidatesForJob(jobId, resumes) {
    const entries = resumes.map((resume) => ({
      jobId,
      resumeUrl: resume,
    }));

    return await this.bulkUploadCandidates(entries);
  }

  /**
   * Uploads candidate resume to GCS
   * @param {File} file
   * @returns {string} resumeUrl
   */
  static async uploadResume(file) {
    const workspaceId = store.getState().Auth.workspaceId;
    const path = new FilePathBuilder(workspaceId)
      .setFileType("RESUME")
      .setFileName(file.name)
      .build();
    return await StorageService.uploadFile(file, path, {
      contentType: file.type,
    });
  }

  /**
   * Uploads candidate resumes to GCS
   * @param {Array<File>} files
   * @returns {Promise<Array<string>>} resumeUrls
   */
  static async uploadResumes(files) {
    return await Promise.all(files.map((file) => this.uploadResume(file)));
  }
}
