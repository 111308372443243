/** @format */

import { REMOVE_ALERT, SHOW_ALERT } from "../actions/constants";

const INITIAL_STATE = {
	message: null,
	variant: null
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;
	switch (type) {
		case SHOW_ALERT:
			return {
				...state,
				...payload
			};
		case REMOVE_ALERT:
			return {
				...state,
				message: null,
				variant: null
			};

		default:
			return { ...state };
	}
}
