/** @format */

import React, { useEffect, useState } from "react";
import Styles from "./CandidateDetails.module.scss";
import {
	AppBar,
	Tabs,
	Tab,
	TextField,
	Button,
	IconButton,
	Fab,
	Dialog
} from "@material-ui/core";
import { Delete, InsertDriveFile } from "@material-ui/icons";
import SwipeableViews from "react-swipeable-views";
import Panel from "../../../GlobalComponents/Panel/Panel";
import { connect } from "react-redux";
import { Close } from "@material-ui/icons";
import {
	updateCandidateDetail,
	deleteCandidate
} from "../../../../actions/outreach";
const CandidateDetails = props => {
	const {
		candidate,
		chat,
		chatLoading,
		onModalClose,
		updateCandidateDetail,
		setLoadData,
		deleteCandidate
	} = props;
	const [value, setValue] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [formData, setFormData] = useState({
		name: "",
		phone: "",
		email: "",
		notes: ""
	});
	useEffect(() => {
		setFormData({
			...formData,
			name: candidate.name,
			phone: candidate.phone,
			email: candidate.email,
			notes: candidate.notes === undefined ? "" : candidate.notes
		});
	}, [candidate]);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const handelChangeIndex = index => {
		setValue(index);
	};

	const onChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const onUpdateCandidate = e => {
		setLoadData(true);
		updateCandidateDetail(candidate._id, formData);
	};

	const showConfirmModal = e => {
		setShowModal(true);
	};
	const onConfirmReject = e => {
		setShowModal(false);
	};
	const onConfirmAccept = e => {
		deleteCandidate(candidate._id);
		setShowModal(false);
		onModalClose();
		setLoadData(true);
	};
	const onNotesSave = e => {
		updateCandidateDetail(candidate._id, { notes: formData.notes });
	};
	return (
		<div className={Styles.modal}>
			<Close onClick={onModalClose} className={Styles.closeButton} />
			<div>
				<img src='/defaultimg.png' />
				<div>
					<p>
						{" "}
						<span>Name:</span> {candidate.name}
					</p>
					<p>
						{" "}
						<span>Phone:</span> {candidate.phone}
					</p>
					<p>
						{" "}
						<span>Email:</span> {candidate.email}
					</p>
					<p>
						<span>Skills:</span>{" "}
						{candidate.skills ? candidate.skills : "No skills available"}
					</p>
				</div>
				<div>
					{/* <Fab className={Styles.fab}>
						<Delete className={Styles.fabIcon} />
					</Fab> */}
					<Button onClick={showConfirmModal} className={Styles.fab}>
						Delete
					</Button>
				</div>
			</div>
			<div className={Styles.candidate}>
				<Tabs indicatorColor='primary' value={value} onChange={handleChange}>
					<Tab className={Styles.tab} label='Timelines' />
					<Tab className={Styles.tab} label='Chat' />
					<Tab className={Styles.tab} label='Profile' />
					<Tab className={Styles.tab} label='Notes' />
				</Tabs>
				<SwipeableViews index={value}>
					<Panel value={value} index={0}>
						<div className={Styles.panelChild}>Timeline</div>
					</Panel>
					<Panel value={value} index={1}>
						<div
							className={`${Styles.panelChild} ${Styles.chatbox} ${
								Styles.chatBox
							} ${chatLoading ? Styles.chatBoxLoading : ""}`}
						>
							{!chatLoading ? (
								chat === null ? (
									<h5>Please Select A candidate</h5>
								) : chat.length === 0 ? (
									<h5>Please send a message</h5>
								) : (
									<div className={Styles.chatSubBox}>
										{chat !== null &&
											chat !== undefined &&
											chat.length !== 0 &&
											chat.map(chat => {
												return (
													<div
														className={`${
															chat.sender === "user"
																? Styles.chatContainer
																: Styles.chatContainerBot
														}`}
													>
														<div
															className={`${
																chat.sender === "user"
																	? Styles.iconUser
																	: Styles.iconBot
															}`}
														>
															{chat.sender === "user" ? (
																<img src='/defaultimg.png'></img>
															) : chat.sender === "recruiter" ||
															  chat.sender === "Recruiter" ? (
																<img src='/recruiter.png' />
															) : (
																<img src='/botimg.png' />
															)}
														</div>
														<p>{chat.text}</p>
													</div>
												);
											})}
									</div>
								)
							) : (
								<div className={Styles.loading}></div>
							)}
						</div>
					</Panel>
					<Panel value={value} index={2}>
						<div>
							<div className={`${Styles.panelChild} `}>
								<div className={`${Styles.profile}`}>
									<div className={Styles.formFiledContainer}>
										<label htmlFor=''>Name</label>
										<div className={Styles.fullWidth}>
											<TextField
												name={"name"}
												onChange={onChange}
												className={Styles.textfiled}
												variant={"standard"}
												value={formData.name}
											></TextField>
										</div>
									</div>
									<div className={Styles.formFiledContainer}>
										<label htmlFor=''>Phone</label>
										<div className={Styles.fullWidth}>
											<TextField
												onChange={onChange}
												name={"phone"}
												className={Styles.textfiled}
												variant={"standard"}
												value={formData.phone}
											></TextField>
										</div>
									</div>
									<div className={Styles.formFiledContainer}>
										<label htmlFor=''>Email</label>
										<div className={Styles.fullWidth}>
											<TextField
												name={"email"}
												onChange={onChange}
												className={Styles.textfiled}
												variant={"standard"}
												value={formData.email}
											></TextField>
										</div>
									</div>
								</div>
								<Button
									variant='contained'
									color='primary'
									onClick={onUpdateCandidate}
								>
									Save
								</Button>
								<div className={Styles.resume}>
									<h3>Resume</h3>
									<div className={Styles.resumeContainer}>
										{candidate.resume ? (
											<a href={candidate.resume} className={Styles.resumeLink}>
												{" "}
												<InsertDriveFile className={Styles.resumeIcon} />
											</a>
										) : (
											<h5>No Resume Available at this moment</h5>
										)}
									</div>
								</div>
							</div>
						</div>
					</Panel>
					<Panel value={value} index={3}>
						<div className={Styles.panelChild}>
							<div className={Styles.editNote}>
								<TextField
									placeholder='Add Notes Here'
									multiline
									rows={6}
									name='notes'
									variant={"outlined"}
									value={formData.notes}
									onChange={e => onChange(e)}
								/>
								<Button
									variant='contained'
									color='primary'
									onClick={onNotesSave}
								>
									Save
								</Button>
							</div>
						</div>
					</Panel>
				</SwipeableViews>
				<Dialog open={showModal} onClose={onConfirmReject}>
					<div className={Styles.deleteModal}>
						<p>Are you sure you want to delete this candidate ?</p>
						<div>
							<Button variant='contained' onClick={onConfirmAccept}>
								Yes
							</Button>
							<Button variant='outlined' onClick={onConfirmReject}>
								Cancel
							</Button>
						</div>
					</div>
				</Dialog>
			</div>
		</div>
	);
};

const mapStateToProps = state => ({
	chat: state.Chat.chat,
	chatLoading: state.Chat.loading
});

export default connect(mapStateToProps, {
	updateCandidateDetail,
	deleteCandidate
})(CandidateDetails);
