import { Box, CircularProgress, Typography } from "@mui/material";

export const CircularScore = ({ score, dark }) => {
  return (
    <Box
      sx={{ position: "relative", display: "inline-flex", marginLeft: "24px" }}
    >
      {!isNaN(score) ? (
        <>
          <CircularProgress variant="determinate" value={score} size="48px" />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {score !== "undefined" ? (
              <Typography
                variant="caption"
                component="div"
                style={{ fontSize: "12px" }}
                color="black"
              >{`${Math.round(score)}%`}</Typography>
            ) : (
              <Typography
                variant="caption"
                component="div"
                color={dark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"}
              >
                No data
              </Typography>
            )}
          </Box>
        </>
      ) : (
        <Typography paddingLeft="8px" variant="h5">
          N/A
        </Typography>
      )}
    </Box>
  );
};
