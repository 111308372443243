const headerMapping = {
  chatType: "Chat Type",
  uid: "User ID",
  name: "Name",
  email: "Email",
  phone: "Phone",
  "jobId.postingTitle": "Job Title",
  "jobId.department": "Department",
  "jobId.assignedRecruiter": "Assigned Recruiter",
  skills: "Skills",
  score: "Score",
  "rawNewAnalysis.final_score": "Final Score",
  "educations[0].Institution.Name": "Institution 1",
  "educations[0].Institution.Location.City": "City 1",
  "educations[0].Degree.NormalizeDegree": "Degree 1",
  "educations[0].Degree.Specialization[0]": "Specialization 1",
  "educations[0].StartDate": "Start Date 1",
  "educations[0].EndDate": "End Date 1",
  "educations[1].Institution.Name": "Institution 2",
  "educations[1].Institution.Location.City": "City 2",
  "educations[1].Degree.NormalizeDegree": "Degree 2",
  "educations[1].Degree.Specialization[0]": "Specialization 2",
  "educations[1].StartDate": "Start Date 2",
  "educations[1].EndDate": "End Date 2",
  "experiences[0].Employer.EmployerName": "Employer 1",
  "experiences[0].JobProfile.Title": "Job Title 1",
  "experiences[0].StartDate": "Start Date 1",
  "experiences[0].EndDate": "End Date 1",
  "experiences[1].Employer.EmployerName": "Employer 2",
  "experiences[1].JobProfile.Title": "Job Title 2",
  "experiences[1].StartDate": "Start Date 2",
  "experiences[1].EndDate": "End Date 2",
  "rawParsedData.WebSite[0].Type": "Website Type 1",
  "rawParsedData.WebSite[0].Url": "Website URL 1",
  "rawParsedData.WebSite[1].Type": "Website Type 2",
  "rawParsedData.WebSite[1].Url": "Website URL 2",
  "rawParsedData.Certification": "Certification",
  "response[0].response": "Response 1",
  "response[1].response": "Response 2",
  "response[2].response": "Response 3",
  "response[3].response": "Response 4",
  "response[4].response": "Response 5",
  "response[5].response": "Response 6",
  "response[6].response": "Response 7",
  "response[7].response": "Response 8",
  "response[8].response": "Response 9",
  "response[9].response": "Response 10",
  "response[10].response": "Response 11",
  "response[11].response": "Response 12",
  "response[12].response": "Response 13",
};

export class ExportService {
  static convertToCSV(candidates) {
    const csvRows = candidates.map((candidate) => {
      return Object.keys(headerMapping)
        .map((field) => {
          const keys = field.split(".");
          let value = candidate;
          for (const key of keys) {
            if (
              value &&
              typeof value[key] !== "undefined" &&
              value[key] !== null
            ) {
              value = value[key];
            } else {
              value = ""; // Replace missing data with an empty string
              break;
            }
          }
          return `"${value || ""}"`;
        })
        .join(",");
    });

    const csv = [Object.values(headerMapping).join(","), ...csvRows].join("\n");
    return csv;
  }

  static downloadAsCsv(candidates) {
    const csv = this.convertToCSV(candidates);
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.setAttribute("hidden", "");
    a.setAttribute("href", url);
    a.setAttribute("download", "candidates.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}
