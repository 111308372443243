import { useEffect } from "react";
import { getJobList, getDepartments } from "../../../actions/positions";
import { MenuItem, Select } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

export default function JobSelect({ value, onChange, fullWidth, jobStyle, postingName = false }) {
  const departments = useSelector((state) => state.Positions.departmentList);
  const jobList = useSelector((state) => state.Positions.jobList);
  if (jobStyle) {
    var divStyle = { display: "flex", gap: "10px" };
  }
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getJobList());
    dispatch(getDepartments());
  }, []);

  const handleFilterChange = (e) => {
    console.log(e.target.value);
    onChange({ ...value, [e.target.name]: e.target.value });
  };
console.log(divStyle,"---divStyle")
  return (
    <>
      <div style={ divStyle }>
        <Select
          value={value.department}
          onChange={handleFilterChange}
          name={"department"}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          placeholder={"Select Department Here"}
          fullWidth={fullWidth}
        >
          <MenuItem value="">Select Department Here</MenuItem>
          {departments !== null &&
            departments.length !== 0 &&
            departments.map((department) => {
              return (
                <MenuItem value={department._id}>
                  {department.departmentName}
                </MenuItem>
              );
            })}
        </Select>
        <Select
          value={value.position}
          onChange={handleFilterChange}
          name={"position"}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          placeholder={"Select Position Here"}
          fullWidth={fullWidth}
        >
          <MenuItem value="" disabled>
            Select Position Here
          </MenuItem>
          {jobList !== null &&
            jobList.length !== 0 &&
            jobList.map((jobList) => {
              if (jobList.department === value.department) {
                return (
                  <MenuItem value={postingName === true ? jobList.postingTitle.postingTitle : jobList._id}>
                    {jobList.postingTitle.postingTitle}
                  </MenuItem>
                );
              }
            })}
        </Select>
      </div>
    </>
  );
}
