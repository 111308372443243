import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";

const Protected = ({ children, allowUnverified }) => {
  const [user, loading, error] = useAuthState(auth);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Something Went Wrong</div>;
  }
  if (!user) {
    return <Navigate to="/" replace />;
  }
  if (!allowUnverified && !user?.emailVerified) {
    return <Navigate to="/verify-email" replace />;
  }
  return <>{children}</>;
};

export default Protected;
