/** @format */

export const GET_COUNTRIES = "GET_COUNTRIES";
export const EMPTY_STATES = "EMPTY_STATES";
export const GET_STATES = "GET_STATES";
export const EMPTY_CITIES = "EMPTY_CITIES";
export const GET_CITIES = "GET_CITIES";

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_CURRENT_WORKSPACE = "SET_CURRENT_WORKSPACE";

export const GET_JOB_LIST = "GET_JOB_LIST";
export const SET_LOADING_POSITIONS = "SET_LOADING_POSITIONS";
export const CREATE_JOB = "CREATE_JOB";
export const GET_DEPARTMENTS = "GET_DEPARTMENTS";
export const CREATE_DEPARTMENT = "CREATE_DEPARTMENT";
export const UPDATE_JOB = "UPDATE_JOB";
export const GET_POSTING_TITLE = "GET_POSTING_TITLE";
export const CREATE_POSTING_TITLE = "CREATE_POSTING_TITLE";
export const GET_SCREENING_QUESTION = "GET_SCREENING_QUESTION";
export const DELETE_JOB = "DELETE_JOB";
export const GET_ANALYTICS = "GET_ANALYTICS";

export const ADD_CANDIDATE = "ADD_CANDIDATE";
export const ADD_CANDIDATE_BULK = "ADD_CANDIDATE_BULK";
export const REMOVE_CANDIDATE = "REMOVE_CANDIDATE";
export const SET_CANDIDATES = "SET_CANDIDATES";
export const RESET_CANDIDATES = "RESET_CANDIDATES";
export const REMOVE_ALL_CANDIDATES = "REMOVE_ALL_CANDIDATES";
export const UPDATE_CANDIDATE = "UPDATE_CANDIDATE";
export const CHANGE_POSITION_COUNT = "CHANGE_POSITION_COUNT";
export const SET_LOADING = "SET_LOADING";
export const SET_ALL_CANDIDATES = "SET_ALL_CANDIDATES";
export const REMOVE_UPDATED = "REMOVE_UPDATED";
export const UPDATE_CANDIDATE_ACTION = "UPDATE_CANDIDATE_ACTION";
export const SET_UNASSIGNED_LENGTH = "SET_UNASSIGNED_LENGTH";
export const GET_CHAT = "GET_CHAT";
export const SEND_CHAT = "SEND_CHAT";
export const LOADING_CHAT = "LOADING_CHAT";
export const REMOVE_CHAT = "REMOVE_CHAT";
export const UPDATE_CANDIDATE_DETAIL = "UPDATE_CANDIDATE_DETAIL";
export const DELETE_CANDIDATE = "DELETE_CANDIDATE";
export const ASSIGN_JOBS = "ASSIGN_JOBS";

export const GET_GENERAL_QUESTIONS = "GET_GENERAL_QUESTIONS";
export const GET_DEPARTMENT_BASED_QUESTIONS = "GET_DEPARTMENT_BASED_QUESTIONS";
export const ADD_GENERAL_QUESTION = "ADD_GENERAL_QUESTION";
export const ADD_DEPARTMENT_QUESTION = "ADD_DEPARTMENT_QUESTION";
export const DELETE_SCREENING_QUESTION = "DELETE_SCREENING_QUESTION";
export const EDIT_QUESTION = "EDIT_QUESTION";

export const SHOW_ALERT = "SHOW_ALERT";
export const REMOVE_ALERT = "REMOVE_ALERT";

export const SET_UPLOAD_LOADING = "SET_UPLOAD_LOADING";
export const GET_UPLOADED_CANDIDATES = "GET_UPLOADED_CANDIDATES";
export const MAKE_VISIBLE = "MAKE_VISIBLE";
export const USER_FILES = "USER_FILES";
export const TRAN_SCRIPT = "TRAN_SCRIPT";
export const IS_LOADING = "IS_LOADING";
export const SCHEDULED = "SCHEDULED"
export const SCHEDULE_MODAL="OPEN_SCHEDULE_MODAL"
export const SCHEDULED_LOADING="SET_MODAL_LOADING"
export const SCHEDULED_UNSET_LOADING="UNSET_LOADING"
export const FILES_LOADING="FILE_LOADER"
export const CREATE_JOB_LOADING="CREATE_JOB_LOADING"
export const CANDIDATES_DETAILS="CANDIDATES_DETAILS"
export const LOADING_ANALYTICS="LOADING_ANALYTICS"
export const HowItsWorkVideo="https://storage.googleapis.com/neural-react/Neuralday_Product_Demo_2.mp4"

