import {
  Box,
  Checkbox,
  Chip,
  Typography,
  FormControlLabel,
  FormGroup,
  Stack,
} from "@mui/material";

/**
 * MultipleSelectFilter component allows selecting multiple filter values from a predefined list.
 * It takes in the filter heading, list of filter values, callback to update selected values,
 * current selected values and an icon component.
 *
 * It displays the heading, icon and count of selected filters.
 * Renders a list of checkboxes allowing multiple selection.
 * Calls the callback on change to update the selected filter values.
 */
export const MultipleSelectFilter = ({
  heading,
  filterValues,
  setFilterValue,
  currentValues,
  IconComponent,
}) => {
  const handleMultipleSelect = (e) => {
    const selectedValue = e.target.value;
    // get the current selected values.
    let selectedValues = [...currentValues];
    // if already not present
    if (!selectedValues.includes(selectedValue)) {
      selectedValues.push(selectedValue);
    } else {
      selectedValues = selectedValues.filter(
        (value) => value !== selectedValue
      );
    }

    setFilterValue(selectedValues);
  };

  return (
    <Box>
      {/* Header */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Stack direction={"row"} gap={1}>
          <IconComponent />
          <Typography>{heading}</Typography>
        </Stack>
        <Chip variant="outlined" color="primary" label={currentValues.length} />
      </Box>

      {/* filters */}
      <Box
        sx={{
          width: "100%",
          padding: "4px 12px",
          borderRadius: "8px",
          background: "#f9fafb",
          marginTop: "12px",
        }}
      >
        <FormGroup name={heading} onChange={handleMultipleSelect}>
          {filterValues.map((filterValue, i) => (
            <FormControlLabel
              key={filterValue.value}
              control={
                <Checkbox checked={currentValues.includes(filterValue.value)} />
              }
              value={filterValue.value}
              label={filterValue.label}
            />
          ))}
        </FormGroup>
      </Box>
    </Box>
  );
};
