import { Badge, Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";

export default ({
  heading,
  badge,
  experiences,
  skills,
  languageKnown,
  qualification,
}) => {
  const [skillsDefined, setSkillsDefined] = useState();

  useEffect(() => {
    const mappedSkills = {};

    // Iterate over the array of skills
    skills?.forEach((skill) => {
      // Get the skill type
      const skillType = skill.Type;

      // If the skill type is not already in the mapped skills object, create a new key for it
      if (!mappedSkills[skillType]) {
        mappedSkills[skillType] = [];
      }

      // Add the skill to the mapped skills object
      mappedSkills[skillType].push(skill.Skill);
    });

    setSkillsDefined(mappedSkills);
  }, [skills]);

  console.log(skillsDefined);

  return (
    <>
      <Box
        width={"100%"}
        sx={{
          borderRadius: "16px",
          border: "1px solid #eaecf0",
          padding: "24px",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
        }}
      >
        <Typography
          variant="h5"
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-around"}
          fontWeight={600}
        >
          {heading}
          {badge && <Badge badgeContent={badge} />}
        </Typography>

        {/* If experience */}
        {experiences?.map((experience) => (
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Typography
              sx={{
                color: "#667085",
                fontSize: "14px",
                fontWeight: "500",
                width: "20%",
              }}
            >
              {experience?.JobPeriod}
            </Typography>
            <Box width={"75%"}>
              <Typography
                color={"#1d2939"}
                fontWeight={600}
                lineHeight={"20px"}
                fontSize={"14px"}
              >
                {experience?.Employer.EmployerName}
              </Typography>
              <Typography color={"#344054"}>
                {experience?.JobProfile.Title}
              </Typography>
              <Typography paddingTop={"8px"} color={"#667085"}>
                {experience?.JobDescription}
              </Typography>
            </Box>
          </Box>
        ))}

        {/* If qualifications */}

        {qualification?.map((qualification) => (
          <Box display={"flex"} justifyContent={"space-between"} width={"100%"}>
            <Typography
              sx={{
                color: "#667085",
                fontSize: "14px",
                fontWeight: "500",
                width: "20%",
              }}
            >
              {qualification?.FormattedDegreePeriod}
            </Typography>
            <Box width={"75%"}>
              <Typography
                color={"#1d2939"}
                fontWeight={600}
                lineHeight={"20px"}
                fontSize={"14px"}
              >
                {qualification?.Degree?.DegreeName || "Degree"}
              </Typography>
              <Typography color={"#344054"}>
                {qualification?.Institution?.Name || "Institution"}
              </Typography>
              <Typography paddingTop={"8px"} color={"#667085"}>
                {qualification?.Aggregate?.Value}
                {qualification?.Aggregate?.MeasureType}
              </Typography>
            </Box>
          </Box>
        ))}

        {/* If attributes */}
        {skillsDefined &&
          Object.entries(skillsDefined).map(([key, array]) => (
            <>
              <Typography
                key={key}
                variant="h5"
                fontSize={"14px"}
                lineHeight={"20px"}
                fontWeight={500}
                color={"#667085"}
              >
                {key}
              </Typography>
              <Box
                display={"flex"}
                flexWrap={"wrap"}
                columnGap={"8px"}
                rowGap={"8px"}
              >
                {array.map((item) => (
                  <Button
                    sx={{
                      borderRadius: "8px",
                      border: "0.5px solid #d0d5dd",
                      padding: "8px 16px",
                    }}
                    key={item}
                  >
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "500",
                        color: "#1d2939",
                        textTransform: "none",
                      }}
                      key={item}
                    >
                      {item}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </>
          ))}

        {/* Languages */}
        {languageKnown && (
          <Box
            display={"flex"}
            flexWrap={"wrap"}
            columnGap={"8px"}
            rowGap={"8px"}
          >
            {languageKnown.map((item) => (
              <Button
                sx={{
                  borderRadius: "8px",
                  border: "0.5px solid #d0d5dd",
                  padding: "8px 16px",
                }}
                key={item.LanguageCode}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#1d2939",
                    textTransform: "none",
                  }}
                  key={item.LanguageCode}
                >
                  {item.Language.length
                    ? item.Language
                    : "No language in resume"}
                </Typography>
              </Button>
            ))}
          </Box>
        )}
      </Box>
    </>
  );
};
