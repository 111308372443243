import { Box, Divider, Typography } from "@mui/material";
import { CircularScore } from "../CircularScore/CircularScore";

export const Analytics = ({ analytics, dark, minWidth }) => {
  return (
    <Box minWidth={minWidth}>
      <Box paddingBottom={"24px"}>
        <Typography
          fontSize={"18px"}
          fontWeight={600}
          lineHeight={"28px"}
          color={dark ? "#fff" : "#344054"}
        >
          {analytics?.skills_matched?.length}
        </Typography>
        <Typography
          fontSize={"14px"}
          color={dark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"}
        >
          Relevant Skills
        </Typography>
      </Box>

      <Divider />

      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexWrap: "wrap",
          paddingTop: "14px",
        }}
      >
        <Box width={"33%"}>
          <Typography
            color={dark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"}
            fontSize={"#14px"}
            fontWeight={600}
            paddingBottom={"10px"}
            paddingTop={"10px"}
          >
            Skill Relevance
          </Typography>
          <CircularScore score={analytics?.skill_match} dark={dark} />
        </Box>
        <Box width={"33%"}>
          <Typography
            color={dark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"}
            fontSize={"#14px"}
            fontWeight={600}
            paddingBottom={"10px"}
            paddingTop={"10px"}
          >
            Title Relevance
          </Typography>
          <CircularScore score={analytics?.title_match} dark={dark} />
        </Box>
        <Box width={"33%"}>
          <Typography
            color={dark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"}
            fontSize={"#14px"}
            fontWeight={600}
            paddingBottom={"10px"}
            paddingTop={"10px"}
          >
            Location Relevance
          </Typography>
          <CircularScore score={analytics?.location_match} dark={dark} />
        </Box>
        <Box width={"33%"}>
          <Typography
            color={dark ? "rgba(255, 255, 255, 0.6)" : "rgba(0, 0, 0, 0.6)"}
            fontSize={"#14px"}
            fontWeight={600}
            paddingBottom={"10px"}
            paddingTop={"10px"}
          >
            Experience Relevance
          </Typography>
          <CircularScore score={analytics?.exp_match} dark={dark} />
        </Box>
      </Box>
    </Box>
  );
};
