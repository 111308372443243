import { Menu, MenuItem, Button } from "@mui/material";
import { useState } from "react";
import { Link } from "react-router-dom";
import MoreVertIcon from "@mui/icons-material/MoreVert";

export const CandidateDetailMenu = ({ id }) => {
  const [moreDetailsOpen, setMoreDetailsOpen] = useState(false);
  return (
    <>
      <Button
        id={`basic-menu${id}`}
        aria-controls={moreDetailsOpen ? `basic-menu${id}` : undefined}
        aria-haspopup="true"
        aira-expanded={moreDetailsOpen ? "true" : undefined}
        onClick={() => {
          setMoreDetailsOpen(!moreDetailsOpen);
        }}
      >
        <MoreVertIcon />
      </Button>
      <Menu
        id={`basic-menu${id}`}
        anchorEl={
          moreDetailsOpen ? document.getElementById(`basic-menu${id}`) : null
        }
        keepMounted
        open={moreDetailsOpen}
        onClose={() => {
          setMoreDetailsOpen(false);
        }}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <Link
            style={{
              height: "100%",
              width: "100%",
            }}
            to={`/candidates/${id}`}
            underline="hover"
            variant="h5"
          >
            {" "}
            Candidate Details
          </Link>
        </MenuItem>
      </Menu>
    </>
  );
};
