//@ts-check
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { auth, storage } from "../firebaseConfig";

/**
 * @typedef {import('./types').FileType} FileType
 */

export class FilePathBuilder {
  /**
   * @param {string} workspaceId
   */
  constructor(workspaceId) {
    this.workspaceId = workspaceId;
  }

  /**
   * @param {FileType} fileType
   * @returns {FilePathBuilder}
   */
  setFileType(fileType) {
    this.fileType = fileType;
    return this;
  }

  /**
   * @param {string} fileName
   * @returns {FilePathBuilder}
   */
  setFileName(fileName) {
    this.fileName = fileName;
    return this;
  }

  /**
   * @returns {string}
   */
  build() {
    if (!this.fileType) {
      throw new Error("File type not set");
    }
    if (!this.fileName) {
      throw new Error("File name not set");
    }
    return `${this.workspaceId}/${this.fileType}/${this.fileName}`;
  }
}

export class StorageService {
  /**
   * @param {File} file
   * @param {string} path
   * @param {import("firebase/storage").UploadMetadata} metadata
   */
  static async uploadFile(file, path, metadata) {
    await auth.currentUser?.getIdToken(true);
    const fileRef = ref(storage, path);
    await uploadBytes(fileRef, file, metadata);
    const url = await getDownloadURL(fileRef);
    return url;
  }

  /**
   * @param {string} path
   * @returns {Promise<string>}
   * @throws {Error}
   * @throws {FirebaseStorageError}
   */
  static async getFileUrl(path) {
    const fileRef = ref(storage, path);
    return await getDownloadURL(fileRef);
  }
}
