/** @format */

import React, { useState } from "react";
import Styles from "./AddDepartment.module.scss";
import { Button, TextField } from "@material-ui/core";
import { connect } from "react-redux";
import {
	createDepartment,
	createPostingTitle
} from "../../../../actions/positions";

const AddDepartment = props => {
	const {
		handleModalClose,
		createDepartment,
		createPostingTitle,
		handleDataFromAPI,
		category
	} = props;

	const [formData, setFormData] = useState({
		departmentName: "",
		postingTitle: ""
	});

	const handleChange = e => {
		setFormData({ ...formData, [e.target.name]: e.target.value });
	};

	const handleSubmit = e => {
		e.preventDefault();
		if (category === "department") {
			createDepartment(
				{ departmentName: formData.departmentName },
				handleDataFromAPI
			);
			return handleModalClose();
		}
		if (category === "posting-title") {
			createPostingTitle(
				{ postingTitle: formData.postingTitle },
				handleDataFromAPI
			);
			return handleModalClose();
		}
	};
	return category === "department" ? (
		<div className={Styles.container}>
			<div className={Styles.formFiledContainer}>
				<label>Department Title</label>
				<TextField
					value={formData.departmentName}
					name={"departmentName"}
					onChange={handleChange}
					className={Styles.textfiled}
					variant={"standard"}
				></TextField>
			</div>
			<Button onClick={handleSubmit} variant='contained' color='primary'>
				Add Department
			</Button>
		</div>
	) : (
		<div className={Styles.container}>
			<div className={Styles.formFiledContainer}>
				<label>Posting Title</label>
				<TextField
					value={formData.postingTitle}
					name={"postingTitle"}
					onChange={handleChange}
					className={Styles.textfiled}
					variant={"standard"}
				></TextField>
			</div>
			<Button onClick={handleSubmit} variant='contained' color='primary'>
				Add Posting Title
			</Button>
		</div>
	);
};

export default connect(null, { createDepartment, createPostingTitle })(
	AddDepartment
);
