/** @format */

import {
	SET_UPLOAD_LOADING,
	GET_UPLOADED_CANDIDATES,
	MAKE_VISIBLE
} from "../actions/constants";

const INITIAL_STATE = {
	loading: false,
	uploadedCandidates: []
};

export default function (state = INITIAL_STATE, action) {
	const { type, payload } = action;

	switch (type) {
		case SET_UPLOAD_LOADING:
			return { ...state, loading: !state.loading };
		case GET_UPLOADED_CANDIDATES:
			return { ...state, uploadedCandidates: payload };
		case MAKE_VISIBLE:
			return {
				...state,
				uploadedCandidates: state.uploadedCandidates.filter(
					el => el._id !== payload
				)
			};
		default:
			return state;
	}
}
