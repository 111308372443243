import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { auth } from "../firebaseConfig";

export const appConfigApi = createApi({
  reducerPath: "appConfigApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: async (headers, { getState }) => {
      const workspaceId = getState().Auth.workspaceId;
      const token = await auth.currentUser?.getIdToken();
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      if (workspaceId) {
        headers.set("x-workspace-id", workspaceId);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getAppConfig: builder.query({
      query: () => "/app-config",
      transformErrorResponse: (baseQueryReturnValue, meta, arg) => {
        return baseQueryReturnValue.error.message;
      },
      providesTags: (result, error, id) => {
        return ["AppConfig"];
      },
    }),
    tagTypes: ["AppConfig"],
  }),
});

export const useGetActionById = () => {
  const { data: appConfig } = useGetAppConfigQuery();
  return (id) => {
    return appConfig?.allAllowedActions.find((action) => action._id === id);
  };
};

export const useAllAllowedActions = () => {
  const { data: appConfig } = useGetAppConfigQuery();
  return appConfig?.allAllowedActions || [];
};

export const useAtsActions = () => {
  const allAllowedActions = useAllAllowedActions();
  return allAllowedActions.filter((action) => !action.archive);
};

export const useDatabaseAction = () => {
  const allAllowedActions = useAllAllowedActions();
  return allAllowedActions.filter((action) => action.archive);
};

export const useCustomActions = () => {
  const { data: appConfig } = useGetAppConfigQuery();
  return appConfig?.customActions || [];
};

export const { useGetAppConfigQuery, useLazyGetAppConfigQuery } = appConfigApi;

// const initialState = {
//   loading: false,
//   candidates: [],
// };
//
// const candidatesSlice = createSlice({
//   name: "candidates",
//   initialState,
//   reducers: {},
// });
