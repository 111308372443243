import { Button, Box, Stack, Typography, useTheme, Modal } from "@mui/material";
import { PageContainer } from "../../styled/SharedComponents";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import AddIcon from "@mui/icons-material/Add";
import BucketsTabs from "./BucketsTabs";
import { useState } from "react";
import AddResume from "./AddResume";
import { useGetCandidatesQuery } from "../../slices/candidates";
import { useAtsActions } from "../../slices/appConfig";
import { useSelector } from "react-redux";
import { selectFilteredCandidates } from "../../slices/candidateFilters";
import { ExportService } from "../../services/export.service";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 520,
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow:
    "0px 8px 8px -4px rgba(16, 24, 40, 0.03), 0px 20px 24px -4px rgba(16, 24, 40, 0.08)",
  p: "24px",
};
/**
 * Ats component
 *
 * @returns JSX for the ATS page
 */
export default () => {
  const [modalActive, setModalActive] = useState(false);
  const atsActions = useAtsActions();

  const filteredCandidates = useSelector(selectFilteredCandidates);
  const handleExport = () => {
    ExportService.downloadAsCsv(filteredCandidates);
  };
  return (
    <PageContainer>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        height="100%"
        width="100%"
      >
        <Box>
          <Typography variant="h3" fontWeight={600}>
            Applicants
          </Typography>
          <Typography variant="h5" paddingTop={"8px"}>
            View the full list of resumes submitted by applicants.
          </Typography>
        </Box>
        <Box>
          <Stack direction={"row"} spacing={2}>
            <Button
              sx={{
                width: "8vw !important",
                padding: "0 16px",
                textDecoration: "none",
                boxShadow: "none",
              }}
              color="primary"
              variant="outlined"
              startIcon={<CloudDownloadIcon />}
              onClick={handleExport}
            >
              Export
            </Button>
            <Button
              sx={{
                width: "100% !important",
                padding: "5px 16px",
                textDecoration: "none",
                boxShadow: "none",
              }}
              onClick={() => {
                setModalActive(true);
              }}
              variant="contained"
              size="large"
              startIcon={<AddIcon />}
            >
              Add Resume
            </Button>
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "24px",
        }}
      >
        <BucketsTabs allowedActions={atsActions} showAllApplicants={true} />
      </Box>
      <Modal
        open={modalActive}
        onClose={() => {
          setModalActive(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <AddResume close={() => setModalActive(false)} />
        </Box>
      </Modal>
    </PageContainer>
  );
};
