/** @format */

import React, { useEffect, useState } from "react";
import Styles from "./ScreeningQuestion.module.scss";
import {
  Select,
  FormControl,
  MenuItem,
  TextField,
  Button,
  Paper,
  Modal,
  Dialog,
} from "@material-ui/core";
import AddQuestion from "./AddQuestion/AddQuestion";
import {
  getGeneralQuestions,
  createQuestion,
  getDepartmentWiseQuestion,
  deleteQuestion,
  updateQuestion,
} from "../../../actions/screeningQuestions";
import { Edit, Delete } from "@material-ui/icons";
import { getDepartments } from "../../../actions/positions";
import { connect } from "react-redux";
import { Navigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../firebaseConfig";
import { PageContainer } from "../../../styled/SharedComponents";

const ScreeningQuestion = (props) => {
  const {
    getGeneralQuestions,
    createQuestion,
    getDepartments,
    generalQuestions,
    departments,
    getDepartmentWiseQuestion,
    departmentQuestions,
    deleteQuestion,
    updateQuestion,
  } = props;
  const [formData, setFormData] = useState({
    department: "0",
    location: "0",
    job: "0",
    question: "0",
  });
  const [user, loading, error] = useAuthState(auth);
  useEffect(() => {
    getGeneralQuestions();
    getDepartments();
  }, []);

  const [modalOpen, setModalOpen] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [deleteQuestionData, setDeleteQuestionData] = useState({});
  const [editQuestion, setEditQuestion] = useState(false);
  const [editQuestionData, setEditQuestionData] = useState({});
  const [category, setCategory] = useState("");
  const [addQuestion, setAddQuestion] = useState(false)

  const handleChange = (event) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
    if (event.target.name === "department" && event.target.value !== "0") {
      getDepartmentWiseQuestion(event.target.value);
    }
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setEditQuestion(false);
    setEditQuestionData({});
  };

  const openModal = (e) => {
    setCategory(e.target.parentElement.ariaLabel);
    setModalOpen(true);
  };
  const onConfirmReject = () => {
    setConfirmModal(false);
    setDeleteQuestionData({});
  };

  const onConfirmAccept = () => {
    deleteQuestion(deleteQuestionData._id, deleteQuestionData.department);
    setConfirmModal(false);
    setDeleteQuestionData({});
  };

  const onConfirmRequest = (question) => {
    setConfirmModal(true);
    setDeleteQuestionData(question);
  };

  const onEditQuestionClick = (question) => {
    setEditQuestionData(question);
    setEditQuestion(true);
    setModalOpen(true);
  };
  const [departmentName, setDepartmentName]= useState('');

  const changeDepartmentName = (name)=>{
    setDepartmentName(name);
  }
  return user ? (
    <PageContainer>
      <div className={Styles.container}>
        <div className={Styles.filterFields}>
          <div className={Styles.formFiledContainer}>
            <label htmlFor="">Department</label>
            <FormControl variant="standard" className={Styles.formControl}>
              <Select
                value={formData.department}
                name={"department"}
                onChange={handleChange}
                displayEmpty
                className={Styles.select}
              >
                <MenuItem className={Styles.menuitem} value={"0"}>
                  All
                </MenuItem>
                {departments !== null &&
                  departments.length !== 0 &&
                  departments.map((department) => {
                    return (
                      <MenuItem
                        className={Styles.menuitem}
                        value={department._id}
                        onClick={()=>changeDepartmentName(department.departmentName)}
                      >
                        {department.departmentName}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </div>
          <div className={Styles.formFiledContainer}>
            <label htmlFor="">Job </label>
            <FormControl variant="standard" className={Styles.formControl}>
              <Select
                value={formData.job}
                name={"job"}
                onChange={handleChange}
                displayEmpty
                className={Styles.select}
              >
                <MenuItem className={Styles.menuitem} value={"0"}>
                  All
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test"}>
                  Test 1
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test2"}>
                  Test 2
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test3"}>
                  Test 3
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={Styles.formFiledContainer}>
            <label htmlFor="">Question</label>
            <FormControl variant="standard" className={Styles.formControl}>
              <Select
                value={formData.question}
                name={"question"}
                onChange={handleChange}
                displayEmpty
                className={Styles.select}
              >
                <MenuItem className={Styles.menuitem} value={"0"}>
                  All
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test"}>
                  Test 1
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test2"}>
                  Test 2
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test3"}>
                  Test 3
                </MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className={Styles.formFiledContainer}>
            <label htmlFor="">Location</label>
            <FormControl variant="standard" className={Styles.formControl}>
              <Select
                value={formData.location}
                name={"location"}
                onChange={handleChange}
                displayEmpty
                className={Styles.select}
              >
                <MenuItem className={Styles.menuitem} value={"0"}>
                  All
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test"}>
                  Test 1
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test2"}>
                  Test 2
                </MenuItem>
                <MenuItem className={Styles.menuitem} value={"test3"}>
                  Test 3
                </MenuItem>
              </Select>
            </FormControl>
          </div>
        </div>

        <div className={Styles.body}>
          <div
            className={`${Styles.formFiledContainer} ${Styles.greetingMessage}`}
          >
            <label className={`${Styles.alignTop}`} htmlFor="">
              Greeting Message
            </label>
            <TextField
              className={Styles.textfiled}
              multiline
              rows={9}
              variant={"outlined"}
            ></TextField>
            <div className={Styles.buttons}>
              <Button variant={"contained"} color={"primary"}>
                Save
              </Button>
              <Button variant={"outlined"} color={"primary"}>
                Edit
              </Button>
            </div>
          </div>

          <div className={Styles.questionContainer}>
            <Paper elevation={3}>
              <h3>General Questions</h3>
              <div className={Styles.question}>
                {generalQuestions !== null &&
                  generalQuestions.length !== 0 &&
                  generalQuestions.map((question) => {
                    return (
                      <p>
                        {question.question}{" "}
                        <span>
                          <Edit
                            className={Styles.editIcon}
                            size="large"
                            onClick={() => onEditQuestionClick(question)}
                          />
                          <Delete
                            className={Styles.deleteIcon}
                            size="large"
                            onClick={(e) => onConfirmRequest(question)}
                          />
                        </span>{" "}
                      </p>
                    );
                  })}
              </div>
              <Button
                onClick={openModal}
                size={"small"}
                color={"primary"}
                variant={"contained"}
                aria-label={"General"}
              >
                Add Question
              </Button>
            </Paper>
            {formData.department === "0" ? (
              <Paper>
                <h3>
                  Please Select A Department to display department based
                  questions
                </h3>
              </Paper>
            ) : (
              <Paper>
                <h3>
                  Department Based{" "}
                  {formData.department === "0"
                    ? ""
                    : `(${departmentName})`}{" "}
                </h3>
                <div className={Styles.question}>
                  {departmentQuestions !== null ? (
                    departmentQuestions.length !== 0 ? (
                      departmentQuestions.map((question) => (
                        <p>
                          {question.question}
                          <span>
                            <Edit
                              className={Styles.editIcon}
                              size="large"
                              onClick={() => onEditQuestionClick(question)}
                            />
                            <Delete
                              className={Styles.deleteIcon}
                              size="large"
                              onClick={(e) => onConfirmRequest(question)}
                            />
                          </span>
                        </p>
                      ))
                    ) : (
                      <h3>There are no questions in this department</h3>
                    )
                  ) : (
                    ""
                  )}
                </div>

                <Button
                  onClick={openModal}
                  size={"small"}
                  color={"primary"}
                  variant={"contained"}
                  aria-label={formData.department}
                >
                  Add Question
                </Button>
              </Paper>
            )}

            <Button color={"primary"} variant={"contained"}>
              Save and See A Sample Preview of Chat
            </Button>
          </div>
        </div>
        <Dialog open={modalOpen} onClose={handleModalClose}>
          <AddQuestion
            category={category}
            createQuestion={createQuestion}
            setModalOpen={setModalOpen}
            editQuestionData={editQuestionData}
            editQuestion={editQuestion}
            updateQuestion={updateQuestion}
            addQuestion={addQuestion}
            setAddQuestion={setAddQuestion}
          />
        </Dialog>
        <Dialog open={confirmModal} onClose={onConfirmReject}>
          <div className={Styles.deleteModal}>
            <p>Are you sure you want to delete this Screening Question ?</p>
            <div>
              <Button variant="contained" onClick={onConfirmAccept}>
                Yes
              </Button>
              <Button variant="outlined" onClick={onConfirmReject}>
                Cancel
              </Button>
            </div>
          </div>
        </Dialog>
      </div>
    </PageContainer>
  ) : (
    <Navigate to="/" />
  );
};

const mapStateToProps = (state) => ({
  generalQuestions: state.ScreeningQuestion.generalQuestions,
  departmentQuestions: state.ScreeningQuestion.departmentQuestions,
  departments: state.Positions.departmentList,
});

export default connect(mapStateToProps, {
  getGeneralQuestions,
  createQuestion,
  getDepartments,
  getDepartmentWiseQuestion,
  deleteQuestion,
  updateQuestion,
})(ScreeningQuestion);
